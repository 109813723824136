import ShippingMethodForm from "./shipping-method-form";
import {inject, observer} from "mobx-react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup, InputGroupAddon, InputGroupText,
    Label,
    Row
} from "reactstrap";
import React from "react";
import {Link} from "react-router-dom";
import ButtonAction from "../base/button/button-action";
import routes from "../../routes";

@inject('shippingStore')
@observer
export default class ShippingMethodCreate extends ShippingMethodForm {
    render() {
        const shippingStore = this.props.shippingStore;

        return <Card className="bg-secondary shadow">
            <CardHeader>
                <h6 className="text-uppercase ls-1">Create</h6>
                <h2>Shipping method</h2>
            </CardHeader>
            <CardBody>
                <Form innerRef={this.FormRef}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="form-control-label">Name</Label>
                                <Input innerRef={this.NameRef} type="text" required={true}
                                       className="form-control-alternative" placeholder="Name"/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Provider</InputGroupText>
                                    </InputGroupAddon>
                                    <select className="custom-select form-control-alternative"
                                            disabled={shippingStore.loading}
                                            onChange={(e) => shippingStore.selectProvider(e.target.value)}>
                                        <option value="">Choose...</option>
                                        {shippingStore.providers.map((provider) => this.renderProvider(provider))}
                                    </select>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                    <Input innerRef={this.AddressRequiredRef} id="addressRequired" type="checkbox"
                                           className="custom-control-input"/>
                                    <Label className="custom-control-label" htmlFor="addressRequired">
                                        <span>Address required</span>
                                    </Label>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                    <Input innerRef={this.ActiveRef} id="active" type="checkbox" defaultChecked={true}
                                           className="custom-control-input"/>
                                    <Label className="custom-control-label" htmlFor="active">
                                        <span>Active</span>
                                    </Label>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col md="6">
                        <Link className="btn btn-danger btn-icon btn-3 w-100" to={routes.shippingMethods}>
                            <span className="btn-inner--icon">
                                <i className="ni ni-bold-left"/>
                            </span>
                            <span className="btn-inner--text">Back</span>
                        </Link>
                    </Col>
                    <Col md="6">
                        <ButtonAction loading={shippingStore.saving}>
                            <Button className="btn-icon btn-3 w-100" role="link" color="success"
                                    onClick={async (e) => await this.initMethodCreate(e)}>
                                <span className="btn-inner--icon">
                                    <i className="ni ni-check-bold"/>
                                </span>
                                <span className="btn-inner--text">Save</span>
                            </Button>
                        </ButtonAction>
                    </Col>
                </Row>
            </CardFooter>
        </Card>;
    }

    private async initMethodCreate(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const valid = this.FormRef.current?.reportValidity();

        if (valid) {
            await this.createMethod();
        }
    }

    private async createMethod() {
        const method = {
            id: 0,
            name: this.NameRef.current?.value,
            addressRequired: this.AddressRequiredRef.current?.checked ?? false,
            active: this.ActiveRef.current?.checked ?? true
        };

        const props = this.props;
        const result = await props.shippingStore.createMethod(method);

        if (result) {
            props.history.push(routes.shippingMethods);
        }
    }
}