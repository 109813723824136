import BaseStore from "./base-store";
import {toast} from "react-toastify";

export default class NotificationStore extends BaseStore {
    notifyErrors(errors: any[]) {
        Object.values(errors).forEach((error) => {
            this.notifyError(error);
        });
    }

    notifyError(error: any) {
        toast.error(error.toString());
    }

    notifySuccess(msg: string) {
        toast.success(msg);
    }
}