import React from "react";
import Datetime from "react-datetime";
import ReactDatetimeClass from "react-datetime";
import 'moment/locale/en-gb';
import 'moment/locale/et';
import moment from "moment";

interface IDatePickerProps {
    innerRef: any,
    placeholder: string,
    defaultValue?: Date
}

export default class DatePicker extends React.Component<IDatePickerProps, ReactDatetimeClass.DatetimepickerState> {
    render() {
        const props = this.props;
        let defaultValue;

        if (props.defaultValue) {
            defaultValue = moment(props.defaultValue);
        }

        return <Datetime ref={props.innerRef} initialValue={defaultValue} inputProps={{placeholder: props.placeholder}}
                         locale={window.navigator.language}/>;
    }
}