import BaseStore from "./base-store";
import {IParameter, IParameterDto} from "../models/parameter";
import {action, observable} from "mobx";
import {v4} from "uuid";

export default class ParameterStore extends BaseStore {
    @observable
    parameters: IParameter[] = [];

    @observable
    parameter: IParameter | undefined;

    async getParameters() {
        try {
            this.startLoading();
            const result = await this.rootStore.parameterApi.getParameters();
            this.setParameters(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async getParameterById(id: number) {
        try {
            this.startLoading();
            const result = await this.rootStore.parameterApi.getParameterById(id);
            this.setParameter(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async createParameter(parameter: IParameter): Promise<boolean> {
        parameter.parameterValues = this.parameter?.parameterValues;
        const dto = ParameterStore.mapToDto(parameter);
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.parameterApi.createParameter(dto);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess(`${dto.name} created`);
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async updateParameter(parameter: IParameter): Promise<boolean> {
        parameter.parameterValues = this.parameter?.parameterValues;
        const dto = ParameterStore.mapToDto(parameter);
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.parameterApi.updateParameter(dto);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess(`${dto.name} updated`);
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async deleteParameter(id: number): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startDeleting();
            const result = await this.rootStore.parameterApi.deleteParameter(id);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                this.removeParameter(id);
                notificationStore.notifySuccess("Parameter deleted");
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishDeleting();
        }
    }

    @action
    initParameter() {
        this.parameter = {
            id: 0,
            name: undefined,
            active: true,
            parameterValues: []
        };
    }

    @action
    addParameterValue() {
        this.parameter?.parameterValues?.push({
            id: 0,
            idx: v4(),
            value: undefined
        });
    }

    @action
    editParameterValue(value: string, idx: string) {
        const existingParameterValue = this.parameter?.parameterValues?.find(x => x.idx === idx);

        if (existingParameterValue) {
            existingParameterValue.value = value;
        }
    }

    @action
    removeParameterValue(idx: string) {
        if (this.parameter) {
            const valueIdx = this.parameter.parameterValues?.findIndex(x => x.idx === idx) as number;

            if (valueIdx >= 0) {
                this.parameter.parameterValues?.splice(valueIdx, 1);
            }
        }
    }

    @action
    private setParameters(parameters: IParameterDto[]) {
        this.parameters = parameters.map((parameter) => {
            return ParameterStore.mapToParameter(parameter);
        });
    }

    @action
    private setParameter(parameter: IParameterDto) {
        this.parameter = ParameterStore.mapToParameter(parameter);
    }

    @action
    private removeParameter(id: number) {
        const parameter = this.parameters.find(x => x.id === id);

        if (parameter) {
            const parameterIdx = this.parameters.indexOf(parameter, 0);
            this.parameters.splice(parameterIdx, 1);
        }
    }

    private static mapToParameter(parameter: IParameterDto): IParameter {
        return {
            id: parameter.id,
            name: parameter.name,
            active: parameter.active,
            parameterValues: parameter.parameterValues?.map((parameterValue) => {
                return {
                    id: parameterValue.id,
                    idx: v4(),
                    value: parameterValue.value
                }
            })
        }
    }

    private static mapToDto(parameter: IParameter): IParameterDto {
        return {
            id: parameter.id,
            name: parameter.name,
            active: parameter.active,
            parameterValues: parameter.parameterValues?.map((parameterValue) => {
                return {
                    id: parameterValue.id,
                    value: parameterValue.value
                }
            })
        };
    }
}