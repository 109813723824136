import {IBaseProps} from "../base/base";
import CompanyStore from "../../stores/company-store";
import BaseForm from "../base/form/base-form";
import {inject, observer} from "mobx-react";
import LoaderDetails from "../base/loader/loader-details";
import React, {createRef, RefObject} from "react";
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Link} from "react-router-dom";
import routes from "../../routes";
import ButtonAction from "../base/button/button-action";

interface ICompanyEditProps extends IBaseProps {
    companyStore: CompanyStore
}

@inject('companyStore')
@observer
export default class CompanyEdit extends BaseForm<ICompanyEditProps> {
    private readonly legalNameRef: RefObject<HTMLInputElement>;
    private readonly displayNameRef: RefObject<HTMLInputElement>;
    private readonly regCodeRef: RefObject<HTMLInputElement>;
    private readonly vatNoRef: RefObject<HTMLInputElement>;
    private readonly addressRef: RefObject<HTMLInputElement>;
    private readonly emailRef: RefObject<HTMLInputElement>;
    private readonly phoneRef: RefObject<HTMLInputElement>;
    private readonly clientAppHostRef: RefObject<HTMLInputElement>;

    componentDidMount() {
        this.props.companyStore.getCompany();
    }

    constructor(props: ICompanyEditProps) {
        super(props);
        this.legalNameRef = createRef();
        this.displayNameRef = createRef();
        this.regCodeRef = createRef();
        this.vatNoRef = createRef();
        this.addressRef = createRef();
        this.emailRef = createRef();
        this.phoneRef = createRef();
        this.clientAppHostRef = createRef();
    }

    render() {
        return !this.props.companyStore.loading ? this.renderCompany() : <LoaderDetails/>;
    }

    private renderCompany(): JSX.Element {
        const companyStore = this.props.companyStore;
        const company = companyStore.company;
        const legalName = company?.legalName;

        return <Card className="bg-secondary shadow">
            <CardHeader>
                <h6 className="text-uppercase ls-1">Company</h6>
                <h2>{legalName}</h2>
            </CardHeader>
            <CardBody>
                <Form innerRef={this.FormRef}>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label className="form-control-label">Legal name</Label>
                                <Input innerRef={this.legalNameRef} type="text" required={true}
                                       className="form-control-alternative" placeholder="Legal name"
                                       defaultValue={legalName}/>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label className="form-control-label">Display name</Label>
                                <Input innerRef={this.displayNameRef} type="text" className="form-control-alternative"
                                       placeholder="Display name" defaultValue={company?.displayName}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label className="form-control-label">Registry code</Label>
                                <Input innerRef={this.regCodeRef} type="text" className="form-control-alternative"
                                       placeholder="Registry code" defaultValue={company?.regCode}/>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label className="form-control-label">VAT number</Label>
                                <Input innerRef={this.vatNoRef} type="text" className="form-control-alternative"
                                       placeholder="VAT number" defaultValue={company?.vatNo}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="form-control-label">Address</Label>
                                <Input innerRef={this.addressRef} type="text" className="form-control-alternative"
                                       placeholder="Address" defaultValue={company?.address}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label className="form-control-label">E-mail</Label>
                                <Input innerRef={this.emailRef} type="email" className="form-control-alternative"
                                       placeholder="E-mail" defaultValue={company?.email}/>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label className="form-control-label">Phone</Label>
                                <Input innerRef={this.phoneRef} type="tel" className="form-control-alternative"
                                       placeholder="Phone" defaultValue={company?.phone}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="form-control-label">Shop domain
                                    (without <i>http</i> and <i>www</i>)</Label>
                                <Input innerRef={this.clientAppHostRef} type="text" className="form-control-alternative"
                                       placeholder="Shop domain" defaultValue={company?.clientAppHost}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col md="6">
                        <Link className="btn btn-danger btn-icon btn-3 w-100" to={routes.backoffice}>
                            <span className="btn-inner--icon">
                                <i className="ni ni-bold-left"/>
                            </span>
                            <span className="btn-inner--text">Back</span>
                        </Link>
                    </Col>
                    <Col md="6">
                        <ButtonAction loading={companyStore.saving}>
                            <Button className="btn-icon btn-3 w-100" role="link" color="success"
                                    onClick={async (e) => await this.initCompanyUpdate(e)}>
                                <span className="btn-inner--icon">
                                    <i className="ni ni-check-bold"/>
                                </span>
                                <span className="btn-inner--text">Save</span>
                            </Button>
                        </ButtonAction>
                    </Col>
                </Row>
            </CardFooter>
        </Card>;
    }

    private async initCompanyUpdate(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const valid = this.FormRef.current?.reportValidity();

        if (valid) {
            await this.updateCompany();
        }
    }

    private async updateCompany() {
        const companyStore = this.props.companyStore;
        const currentCompany = companyStore.company;

        if (currentCompany) {
            const updatedCompany = {
                legalName: this.legalNameRef.current?.value || currentCompany.legalName,
                displayName: this.displayNameRef.current?.value,
                regCode: this.regCodeRef.current?.value,
                vatNo: this.vatNoRef.current?.value,
                address: this.addressRef.current?.value,
                email: this.emailRef.current?.value,
                phone: this.phoneRef.current?.value,
                clientAppHost: this.clientAppHostRef.current?.value
            };

            await companyStore.updateCompany(updatedCompany);
        }
    }
}