import React from "react";
import {Col, Label, Row} from "reactstrap";
import Dropzone from "react-dropzone";
import ImgUploadUtil from "../../../utils/img-upload-util";
import Base, {IBaseProps} from "../base";
import ImgStore from "../../../stores/img-store";
import {observer} from "mobx-react";
import ImgCrop from "./img-crop";

interface IImgUploadProps extends IBaseProps {
    loading: boolean,
    imgStore: ImgStore
}

@observer
export default class ImgUpload extends Base<IImgUploadProps> {
    render() {
        const defaultStyle = ImgUploadUtil.getDefaultStyle();
        const draggingStyle = ImgUploadUtil.getDraggingStyle();
        const previewStyle = ImgUploadUtil.getPreviewStyle();
        const props = this.props;
        const loading = props.loading;
        const imgStore = props.imgStore;
        const img = imgStore.img;

        return <Col>
            <Row>
                <Col md="4">
                    <Row>
                        <Col>
                            <Label>Step 1 - Add image</Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Dropzone onDrop={(imgs) => this.dropImgs(imgs)} disabled={loading}>
                                {({getRootProps, getInputProps, isDragActive}) => (
                                    <section style={isDragActive ? {...defaultStyle, ...draggingStyle} : defaultStyle}>
                                        <div {...getRootProps()} style={{height: "100%"}}>
                                            <input {...getInputProps()} />
                                            <p>Drag 'n' drop image here, or click to select image</p>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </Col>
                    </Row>
                </Col>
                <Col md="4">
                    <Row>
                        <Col>
                            <Label>Step 2 - Crop image</Label>
                        </Col>
                    </Row>
                    {img && <ImgCrop loading={loading} cropping={imgStore.cropping} imgPreviewSrc={img.previewSrc}
                                     onConfirm={(cropper) => this.cropImg(cropper)} savingCrop={imgStore.savingCrop}/>}
                </Col>
                <Col md="4">
                    <Row>
                        <Col>
                            <Label>Step 3 - Preview & Upload</Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="img-preview" style={previewStyle}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>;
    }

    private dropImgs(files: File[]) {
        const file = files[0];
        const previewSrc = URL.createObjectURL(file);

        const img = {
            rawFile: file,
            previewSrc: previewSrc
        };

        this.props.imgStore.dropImg(img);
    }

    private cropImg(cropper: Cropper) {
        const imgStore = this.props.imgStore;
        imgStore.startSavingCrop();

        const canvas = cropper.getCroppedCanvas({
            fillColor: "#ffffff"
        });

        if (canvas) {
            canvas.toBlob((blob) => imgStore.cropImg(blob!), "image/jpeg");
        }
    }
}