import ZoneForm from "./zone-form";
import {inject, observer} from "mobx-react";
import LoaderDetails from "../base/loader/loader-details";
import React from "react";
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Link} from "react-router-dom";
import routes from "../../routes";
import ButtonAction from "../base/button/button-action";

@inject('zoneStore')
@observer
export default class ZoneEdit extends ZoneForm {
    componentDidMount() {
        const props = this.props;
        props.zoneStore.getZoneById(props.match.params.id);
    }

    render() {
        return !this.props.zoneStore.loading ? this.renderZone() : <LoaderDetails/>;
    }

    private renderZone(): JSX.Element {
        const zoneStore = this.props.zoneStore;
        const zone = zoneStore.zone;
        const name = zone?.name;

        return <Card className="bg-secondary shadow">
            <CardHeader>
                <h6 className="text-uppercase ls-1">Zone</h6>
                <h2>{name}</h2>
            </CardHeader>
            <CardBody>
                <Form innerRef={this.FormRef}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="form-control-label">Name</Label>
                                <Input innerRef={this.NameRef} type="text" required={true}
                                       className="form-control-alternative" placeholder="Name" defaultValue={name}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                    <Input innerRef={this.ActiveRef} id="active" type="checkbox"
                                           defaultChecked={zone?.active} className="custom-control-input"/>
                                    <Label className="custom-control-label" htmlFor="active">
                                        <span>Active</span>
                                    </Label>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col md="6">
                        <Link className="btn btn-danger btn-icon btn-3 w-100" to={routes.zones}>
                            <span className="btn-inner--icon">
                                <i className="ni ni-bold-left"/>
                            </span>
                            <span className="btn-inner--text">Back</span>
                        </Link>
                    </Col>
                    <Col md="6">
                        <ButtonAction loading={zoneStore.saving}>
                            <Button className="btn-icon btn-3 w-100" role="link" color="success"
                                    onClick={async (e) => await this.initZoneUpdate(e)}>
                                <span className="btn-inner--icon">
                                    <i className="ni ni-check-bold"/>
                                </span>
                                <span className="btn-inner--text">Save</span>
                            </Button>
                        </ButtonAction>
                    </Col>
                </Row>
            </CardFooter>
        </Card>;
    }

    private async initZoneUpdate(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const valid = this.FormRef.current?.reportValidity();

        if (valid) {
            await this.updateZone();
        }
    }

    private async updateZone() {
        const props = this.props;
        const zoneStore = props.zoneStore;
        const existingZone = zoneStore.zone;

        if (existingZone) {
            const updatedZone = {
                id: existingZone.id,
                name: this.NameRef.current?.value,
                active: this.ActiveRef.current?.checked ?? existingZone.active
            };

            const result = await zoneStore.updateZone(updatedZone);

            if (result) {
                props.history.push(routes.zones);
            }
        }
    }
}