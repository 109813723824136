import Base, {IBaseProps} from "../base/base";
import ParameterStore from "../../stores/parameter-store";
import ProductStore from "../../stores/product-store";
import React, {createRef, RefObject} from "react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup, Input, InputGroup,
    InputGroupAddon,
    InputGroupText, Label,
    Row
} from "reactstrap";
import {Link} from "react-router-dom";
import ButtonAction from "../base/button/button-action";
import {inject, observer} from "mobx-react";
import {IProductParameterValue} from "../../models/product";
import {IParameter, IParameterValue} from "../../models/parameter";
import LoaderDetails from "../base/loader/loader-details";
import routes from "../../routes";

interface IProductParameterValueListProps extends IBaseProps {
    parameterStore: ParameterStore,
    productStore: ProductStore
}

@inject('parameterStore', 'productStore')
@observer
export default class ProductParameterValueList extends Base<IProductParameterValueListProps> {
    private readonly formRef: RefObject<HTMLFormElement>;

    componentDidMount() {
        const props = this.props;
        props.parameterStore.getParameters();
        props.productStore.getParameterValues(props.match.params.id);
    }

    constructor(props: IProductParameterValueListProps) {
        super(props);
        this.formRef = createRef();
    }

    render() {
        const props = this.props;
        const loading = props.parameterStore.loading || props.productStore.loading;
        return !loading ? this.renderParameterValues() : <LoaderDetails/>;
    }

    private renderParameterValues(): JSX.Element {
        const productStore = this.props.productStore;

        return <Card className="shadow">
            <CardHeader>
                <h2>Product parameter values</h2>
            </CardHeader>
            <CardBody>
                <Form
                    innerRef={this.formRef}>{productStore.parameterValues.map((parameterValue) => this.renderParameterValue(parameterValue))}</Form>
                <Row>
                    <Col>
                        <Button className="btn-icon btn-3" role="link" color="primary" size="sm"
                                onClick={() => productStore.addParameterValue()}>
                            <span className="btn-inner--icon">
                                <i className="ni ni-fat-add"/>
                            </span>
                            <span className="btn-inner--text">Add</span>
                        </Button>
                    </Col>
                </Row>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col md="6">
                        <Link className="btn btn-danger btn-icon btn-3 w-100" to={routes.products}>
                            <span className="btn-inner--icon">
                                <i className="ni ni-bold-left"/>
                            </span>
                            <span className="btn-inner--text">Back</span>
                        </Link>
                    </Col>
                    <Col md="6">
                        <ButtonAction loading={productStore.saving}>
                            <Button className="btn-icon btn-3 w-100" role="link" color="success"
                                    onClick={async (e) => await this.initParameterValuesUpdate(e)}>
                                <span className="btn-inner--icon">
                                    <i className="ni ni-check-bold"/>
                                </span>
                                <span className="btn-inner--text">Save</span>
                            </Button>
                        </ButtonAction>
                    </Col>
                </Row>
            </CardFooter>
        </Card>;
    }

    private renderParameterValue(parameterValue: IProductParameterValue): JSX.Element {
        const idx = parameterValue.idx;
        const checkboxId = `${idx}-active`;
        const props = this.props;
        const parameterStore = props.parameterStore;
        const productStore = props.productStore;

        return <Row key={idx}>
            <Col className="order-xl-0 order-lg-0" xl="5" lg="6">
                <FormGroup>
                    <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>Parameter value</InputGroupText>
                        </InputGroupAddon>
                        <select className="custom-select form-control-alternative" disabled={parameterStore.loading}
                                onChange={(e) => this.selectParameterValue(e, idx)} required={true}
                                defaultValue={parameterValue.parameterValueId}>
                            <option value="">Choose...</option>
                            {parameterStore.parameters.map((parameter) => this.renderValue(parameter))}
                        </select>
                    </InputGroup>
                </FormGroup>
            </Col>
            <Col className="order-xl-1 order-lg-3" xl="2" lg="6">
                <FormGroup>
                    <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="ni ni-box-2"/>
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input type="number" defaultValue={parameterValue.stock} className="form-control-alternative"
                               placeholder="Stock" onChange={(e) => this.updateStock(e, idx)}/>
                    </InputGroup>
                </FormGroup>
            </Col>
            <Col className="order-xl-2 order-lg-4" xl="2" lg="6">
                <FormGroup>
                    <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="ni ni-money-coins"/>
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input type="number" step=".01" className="form-control-alternative" placeholder="Price"
                               defaultValue={parameterValue.price} onChange={(e) => this.updatePrice(e, idx)}/>
                    </InputGroup>
                </FormGroup>
            </Col>
            <Col className="order-xl-3 order-lg-1" xl="1" lg="3">
                <FormGroup>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                        <Input id={checkboxId} type="checkbox" defaultChecked={parameterValue.active}
                               className="custom-control-input"
                               onChange={(e) => productStore.setParameterValueActive(e.target.checked, idx)}/>
                        <Label className="custom-control-label" htmlFor={checkboxId}>
                            <span>Active</span>
                        </Label>
                    </div>
                </FormGroup>
            </Col>
            <Col className="order-xl-4 order-lg-2" xl="2" lg="3">
                <Button className="btn-icon btn-3" role="link" color="warning"
                        onClick={() => productStore.removeParameterValue(idx)}>
                    <span className="btn-inner--icon">
                        <i className="ni ni-fat-remove"/>
                    </span>
                    <span className="btn-inner--text">Remove</span>
                </Button>
            </Col>
        </Row>;
    }

    private selectParameterValue(e: React.ChangeEvent<HTMLSelectElement>, idx: string) {
        const parameterValueId = Number(e.target.value);
        this.props.productStore.selectParameterValue(parameterValueId, idx);
    }

    private renderValue(parameter: IParameter): JSX.Element[] {
        return parameter.parameterValues?.map((value) => ProductParameterValueList.renderValueOption(value, parameter.name)) ?? [];
    }

    private static renderValueOption(parameterValue: IParameterValue, parameterName?: string): JSX.Element {
        const id = parameterValue.id;
        return <option key={id} value={id}>{`${parameterName}: ${parameterValue.value}`}</option>;
    }

    private async initParameterValuesUpdate(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const valid = this.formRef.current?.reportValidity();

        if (valid) {
            await this.updateParameterValues();
        }
    }

    private async updateParameterValues() {
        const props = this.props;
        const result = await props.productStore.updateParameterValues(props.match.params.id);

        if (result) {
            props.history.push(routes.products);
        }
    }

    private updateStock(e: React.ChangeEvent<HTMLInputElement>, idx: string) {
        const stockFieldValue = e.target.value;
        const productStore = this.props.productStore;

        if (stockFieldValue) {
            const stock = Number(stockFieldValue);
            productStore.setParameterValueStock(stock, idx);
        } else {
            productStore.unsetParameterValueStock(idx);
        }
    }

    private updatePrice(e: React.ChangeEvent<HTMLInputElement>, idx: string) {
        const price = Number(e.target.value);
        this.props.productStore.setParameterValuePrice(price, idx);
    }
}