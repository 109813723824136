import React from "react";
import {Badge} from "reactstrap";
import ColorUtil from "../../../utils/color-util";

interface IBadgeStatusProps {
    status: string | undefined
}

export default class BadgeStatus extends React.Component<IBadgeStatusProps> {
    render() {
        const status = this.props.status;

        if (status) {
            const color = ColorUtil.mapFromStatus(status);
            return <Badge color={color}>{status}</Badge>
        }
    }
}