import CompanyPaymentProviderForm from "./company-payment-provider-form";
import {inject, observer} from "mobx-react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input, InputGroup, InputGroupAddon, InputGroupText,
    Label,
    Row
} from "reactstrap";
import React from "react";
import {Link} from "react-router-dom";
import routes from "../../routes";
import ButtonAction from "../base/button/button-action";

@inject('companyStore')
@observer
export default class CompanyPaymentProviderCreate extends CompanyPaymentProviderForm {
    constructor(props: any) {
        super(props);
        this.props.companyStore.initPaymentProvider();
    }

    render() {
        const companyStore = this.props.companyStore;

        return <Card className="bg-secondary shadow">
            <CardHeader>
                <h6 className="text-uppercase ls-1">Create</h6>
                <h2>Payment provider</h2>
            </CardHeader>
            <CardBody>
                <Form innerRef={this.FormRef}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Name</InputGroupText>
                                    </InputGroupAddon>
                                    <select className="custom-select form-control-alternative" required={true}
                                            disabled={companyStore.loading}
                                            onChange={(e) => companyStore.selectPaymentProvider(e.target.value)}>
                                        <option value="">Choose...</option>
                                        {companyStore.paymentProviderNames.map((name) => this.renderName(name))}
                                    </select>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                    <Input innerRef={this.ActiveRef} id="active" type="checkbox" defaultChecked={true}
                                           className="custom-control-input"/>
                                    <Label className="custom-control-label" htmlFor="active">
                                        <span>Active</span>
                                    </Label>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <h3>Authentication</h3>
                        </Col>
                    </Row>
                    {companyStore.paymentProvider?.auths?.map((auth) => this.renderAuth(auth))}
                    <Row>
                        <Col>
                            <Button className="btn-icon btn-3" role="link" color="primary" size="sm"
                                    onClick={() => companyStore.addProviderAuth()}>
                                <span className="btn-inner--icon">
                                    <i className="ni ni-fat-add"/>
                                </span>
                                <span className="btn-inner--text">Add</span>
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col md="6">
                        <Link className="btn btn-danger btn-icon btn-3 w-100" to={routes.companyPaymentProviders}>
                            <span className="btn-inner--icon">
                                <i className="ni ni-bold-left"/>
                            </span>
                            <span className="btn-inner--text">Back</span>
                        </Link>
                    </Col>
                    <Col md="6">
                        <ButtonAction loading={companyStore.saving}>
                            <Button className="btn-icon btn-3 w-100" role="link" color="success"
                                    onClick={async (e) => await this.initProviderCreate(e)}>
                                <span className="btn-inner--icon">
                                    <i className="ni ni-check-bold"/>
                                </span>
                                <span className="btn-inner--text">Save</span>
                            </Button>
                        </ButtonAction>
                    </Col>
                </Row>
            </CardFooter>
        </Card>;
    }

    private async initProviderCreate(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const valid = this.FormRef.current?.reportValidity();

        if (valid) {
            await this.createProvider();
        }
    }

    private async createProvider() {
        const provider = {
            id: 0,
            active: this.ActiveRef.current?.checked ?? true
        }

        const props = this.props;
        const result = await props.companyStore.createPaymentProvider(provider);

        if (result) {
            props.history.push(routes.companyPaymentProviders);
        }
    }
}