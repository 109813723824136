import RootApi from "./root-api";
import {AxiosResponse} from "axios";

export default class ImgApi extends RootApi {
    private readonly endpoint = "/images";

    async uploadImg(file: Blob): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append("File", file);
        return this.makeFormPostCall(this.endpoint, formData);
    }
}