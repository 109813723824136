import {IBaseProps} from "../base/base";
import ParameterStore from "../../stores/parameter-store";
import BaseList from "../base/list/base-list";
import {inject, observer} from "mobx-react";
import React from "react";
import {Card, CardHeader, Table} from "reactstrap";
import LinkCreate from "../base/link/link-create";
import LoaderList from "../base/loader/loader-list";
import {IParameterDto, IParameterValueDto} from "../../models/parameter";
import ModalDelete from "../base/modal-delete";
import BadgeBoolean from "../base/badge/badge-boolean";
import routes from "../../routes";

interface IParameterListProps extends IBaseProps {
    parameterStore: ParameterStore
}

@inject('parameterStore')
@observer
export default class ParameterList extends BaseList<IParameterListProps> {
    componentDidMount() {
        this.props.parameterStore.getParameters();
    }

    render() {
        return <Card className="shadow">
            <CardHeader>
                <h2>Parameters</h2>
                <LinkCreate path={routes.parameters}/>
            </CardHeader>
            {!this.props.parameterStore.loading ? this.renderParameters() : <LoaderList/>}
        </Card>;
    }

    private renderParameters(): JSX.Element {
        return <Table className="align-items-center table-flush" responsive={true}>
            <thead className="thead-light">
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Values</th>
                <th scope="col">Active</th>
                <th scope="col"/>
            </tr>
            </thead>
            <tbody>{this.props.parameterStore.parameters.map((parameter) => this.renderParameter(parameter))}</tbody>
        </Table>;
    }

    private renderParameter(parameter: IParameterDto): JSX.Element {
        const id = parameter.id;
        return <tr key={id}>
            <td>{parameter.name}</td>
            <td>{parameter.parameterValues?.map((value) => ParameterList.renderParameterValue(value))}</td>
            <td>
                <BadgeBoolean isTrue={parameter.active}/>
            </td>
            <td>{this.renderDefaultBtnRow(id, routes.parameters)}</td>
            <ModalDelete id={id} modal={this.state.deletingModal} onToggle={() => this.toggleDeletingModal(id)}
                         onDelete={async () => await this.deleteParameter(id)}
                         deleting={this.props.parameterStore.deleting}/>
        </tr>;
    }

    private static renderParameterValue(parameterValue: IParameterValueDto): JSX.Element {
        return <p key={parameterValue.id} className="font-13 font-weight-normal">{parameterValue.value}</p>;
    }

    private async deleteParameter(id: number) {
        const result = await this.props.parameterStore.deleteParameter(id);

        if (result) {
            this.toggleDeletingModal(id);
        }
    }
}