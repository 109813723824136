import BaseStore from "./base-store";
import {ICountryDto, ICountryShippingMethodDto} from "../models/country";
import {action, observable} from "mobx";

export default class CountryStore extends BaseStore {
    @observable
    loadingCountries: boolean = false;

    @observable
    countries: ICountryDto[] = [];

    @observable
    country: ICountryDto | undefined;

    @observable
    selectedCountryId: number | undefined;

    @observable
    shippingMethods: ICountryShippingMethodDto[] = [];

    @observable
    shippingMethod: ICountryShippingMethodDto | undefined;

    async getCountries() {
        try {
            this.startLoadingCountries();
            const result = await this.rootStore.countryApi.getCountries();
            this.setCountries(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoadingCountries();
        }
    }

    async getCountriesByZone(zoneId: number) {
        try {
            this.startLoadingCountries();
            const result = await this.rootStore.countryApi.getCountriesByZone(zoneId);
            this.setCountries(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoadingCountries();
        }
    }

    async getShippingMethods() {
        try {
            this.startLoading();
            const result = await this.rootStore.countryApi.getShippingMethods();
            this.setShippingMethods(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async getCountryById(id: number) {
        try {
            this.startLoading();
            const result = await this.rootStore.countryApi.getCountryById(id);
            this.setCountry(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async getShippingMethodById(id: number) {
        try {
            this.startLoading();
            const result = await this.rootStore.countryApi.getShippingMethodById(id);
            this.setShippingMethod(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async createCountry(country: ICountryDto): Promise<boolean> {
        const rootStore = this.rootStore;
        country.zoneId = rootStore.zoneStore.selectedZoneId;
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await rootStore.countryApi.createCountry(country);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess(`${country.name} created`);
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async createShippingMethod(method: ICountryShippingMethodDto): Promise<boolean> {
        this.fillShippingMethodAdditionalFields(method);
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.countryApi.createShippingMethod(method);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess("Shipping setting created");
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async updateCountry(country: ICountryDto): Promise<boolean> {
        const rootStore = this.rootStore;
        country.zoneId = rootStore.zoneStore.selectedZoneId;
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await rootStore.countryApi.updateCountry(country);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess(`${country.name} updated`);
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async updateShippingMethod(method: ICountryShippingMethodDto): Promise<boolean> {
        this.fillShippingMethodAdditionalFields(method);
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.countryApi.updateShippingMethod(method);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess("Shipping setting updated");
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async deleteCountry(id: number): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startDeleting();
            const result = await this.rootStore.countryApi.deleteCountry(id);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                this.removeCountry(id);
                notificationStore.notifySuccess("Category deleted");
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishDeleting();
        }
    }

    async deleteShippingMethod(id: number): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startDeleting();
            const result = await this.rootStore.countryApi.deleteShippingMethod(id);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                this.removeShippingMethod(id);
                notificationStore.notifySuccess("Shipping setting deleted");
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishDeleting();
        }
    }

    @action
    selectCountry(id: number) {
        this.selectedCountryId = id;
    }

    @action
    unsetCountries() {
        this.countries = [];
        this.selectedCountryId = undefined;
    }

    private fillShippingMethodAdditionalFields(shipping: ICountryShippingMethodDto) {
        const rootStore = this.rootStore;
        shipping.zoneId = rootStore.zoneStore.selectedZoneId;
        shipping.countryId = this.selectedCountryId;
        shipping.methodId = rootStore.shippingStore.selectedMethodId;
        shipping.categoryId = rootStore.categoryStore.selectedCategoryId;
    }

    @action
    private startLoadingCountries() {
        this.loadingCountries = true;
    }

    @action
    private finishLoadingCountries() {
        this.loadingCountries = false;
    }

    @action
    private setCountries(countries: ICountryDto[]) {
        this.countries = countries;
    }

    @action
    private setShippingMethods(shippings: ICountryShippingMethodDto[]) {
        this.shippingMethods = shippings;
    }

    @action
    private setCountry(country: ICountryDto) {
        const zoneId = country.zoneId;

        if (zoneId) {
            this.rootStore.zoneStore.setSelectedZone(zoneId);
        }

        this.country = country;
    }

    @action
    private setShippingMethod(shipping: ICountryShippingMethodDto) {
        this.selectedCountryId = shipping.countryId;
        const rootStore = this.rootStore;
        const zoneId = shipping.zoneId;

        if (zoneId) {
            rootStore.zoneStore.selectZone(zoneId);
        }

        const methodId = shipping.methodId;

        if (methodId) {
            rootStore.shippingStore.selectMethodId(methodId);
        }

        const categoryId = shipping.categoryId;

        if (categoryId) {
            rootStore.categoryStore.selectCategory(categoryId);
        }

        this.shippingMethod = shipping;
    }

    @action
    private removeCountry(id: number) {
        const country = this.countries.find(x => x.id === id);

        if (country) {
            const countryIdx = this.countries.indexOf(country, 0);
            this.countries.splice(countryIdx, 1);
        }
    }

    @action
    private removeShippingMethod(id: number) {
        const shipping = this.shippingMethods.find(x => x.id === id);

        if (shipping) {
            const shippingIdx = this.shippingMethods.indexOf(shipping, 0);
            this.shippingMethods.splice(shippingIdx, 1);
        }
    }
}