import Base, {IBaseProps} from "../base/base";
import CustomerStore from "../../stores/customer-store";
import {inject, observer} from "mobx-react";
import LoaderDetails from "../base/loader/loader-details";
import React from "react";
import {Card, CardBody, CardHeader, Col, Label, Row} from "reactstrap";
import routes from "../../routes";
import {Link} from "react-router-dom";

interface ICustomerDetailsProps extends IBaseProps {
    customerStore: CustomerStore
}

@inject('customerStore')
@observer
export default class CustomerDetails extends Base<ICustomerDetailsProps> {
    componentDidMount() {
        const props = this.props;
        props.customerStore.getCustomerById(props.match.params.id);
    }

    render() {
        return !this.props.customerStore.loading ? this.renderCustomer() : <LoaderDetails/>;
    }

    private renderCustomer(): JSX.Element {
        const customer = this.props.customerStore.customer;
        const name = customer?.name;

        return <Card className="bg-secondary shadow">
            <CardHeader>
                <h6 className="text-uppercase ls-1">Customer</h6>
                <h2>{name}</h2>
                <Link className="btn btn-sm btn-danger btn-icon btn-3" to={routes.customers}>
                    <span className="btn-inner--icon">
                        <i className="ni ni-bold-left"/>
                    </span>
                    <span className="btn-inner--text">Back</span>
                </Link>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md="6">
                        <Label>Name</Label>
                        <p>{name}</p>
                    </Col>
                    {customer?.companyName && <Col md="6">
                        <Label>Company</Label>
                        <p>{customer.companyName}</p>
                    </Col>}
                </Row>
                <Row>
                    <Col md="6">
                        <Label>E-mail</Label>
                        <p>{customer?.email}</p>
                    </Col>
                    <Col md="6">
                        <Label>Phone</Label>
                        <p>{customer?.phone}</p>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col>
                        <Label>Wants newsletters?</Label>
                        <p>{customer?.wantsNewsletters ? "Yes" : "No"}</p>
                    </Col>
                </Row>
            </CardBody>
        </Card>;
    }
}