import {IBaseProps} from "../base/base";
import CustomerStore from "../../stores/customer-store";
import BaseList from "../base/list/base-list";
import {inject, observer} from "mobx-react";
import {Card, CardFooter, CardHeader, Col, Row, Table} from "reactstrap";
import React from "react";
import Paging from "../base/paging";
import LoaderList from "../base/loader/loader-list";
import {ICustomerDto} from "../../models/customer";
import BadgeBoolean from "../base/badge/badge-boolean";
import routes from "../../routes";
import {Link} from "react-router-dom";

interface ICustomerListProps extends IBaseProps {
    customerStore: CustomerStore
}

@inject('customerStore')
@observer
export default class CustomerList extends BaseList<ICustomerListProps> {
    componentDidMount() {
        const customerStore = this.props.customerStore;
        customerStore.searchCustomers({page: customerStore.currentPage});
    }

    render() {
        const customerStore = this.props.customerStore;
        const customerList = customerStore.customerList;
        const currentPage = customerStore.currentPage;

        return <Card className="shadow">
            <CardHeader>
                <Row>
                    <Col md="6">
                        <h2>Customers</h2>
                    </Col>
                    <Col md="6">
                        <Paging list={customerList} currentPage={currentPage}
                                onClick={async (page) => await customerStore.searchCustomers({page: page})}/>
                    </Col>
                </Row>
            </CardHeader>
            {!customerStore.loading ? this.renderCustomers() : <LoaderList/>}
            <CardFooter>
                <Row>
                    <Col md="6">
                        <h4>Total: {customerList.count}</h4>
                    </Col>
                    <Col md="6">
                        <Paging list={customerList} currentPage={currentPage}
                                onClick={async (page) => await customerStore.searchCustomers({page: page})}/>
                    </Col>
                </Row>
            </CardFooter>
        </Card>;
    }

    private renderCustomers(): JSX.Element {
        return <Table className="align-items-center table-flush" responsive={true}>
            <thead className="thead-light">
            <tr>
                <th scope="col">Name</th>
                <th scope="col">E-mail</th>
                <th scope="col">Newsletters</th>
                <th scope="col"/>
            </tr>
            </thead>
            <tbody>{this.props.customerStore.customerList.entities.map((customer) => CustomerList.renderCustomer(customer))}</tbody>
        </Table>;
    }

    private static renderCustomer(customer: ICustomerDto): JSX.Element {
        const id = customer.id;

        return <tr key={id}>
            <td>{customer.name}</td>
            <td>{customer.email}</td>
            <td>
                <BadgeBoolean isTrue={customer.wantsNewsletters}/>
            </td>
            <td>
                <Link className="btn btn-default btn-sm btn-icon btn-3" to={`${routes.customers}/${id}`}>
                    <span className="btn-inner--icon">
                        <i className="ni ni-zoom-split-in"/>
                    </span>
                    <span className="btn-inner--text">Details</span>
                </Link>
            </td>
        </tr>;
    }
}