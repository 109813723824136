import {IListDto} from "../../models/base";
import React from "react";
import {Pagination, PaginationItem, PaginationLink} from "reactstrap";

interface IPagingProps {
    list: IListDto<any>,
    currentPage: number,
    onClick: (page: number) => void
}

export default class Paging extends React.Component<IPagingProps> {
    render() {
        const pages = this.getVisiblePages();
        const currentPage = this.props.currentPage;

        return <nav aria-label="Page navigation">
            <Pagination className="pagination justify-content-end" listClassName="justify-content-end">
                <PaginationItem className={pages[0] === currentPage ? "disabled" : ""}>
                    <PaginationLink onClick={() => this.selectPage(currentPage - 1)}>
                        <i className="ni ni-bold-left"/>
                        <span className="sr-only">Previous</span>
                    </PaginationLink>
                </PaginationItem>
                {pages.map((page) => this.renderPage(page))}
                <PaginationItem className={pages[pages.length - 1] === currentPage ? "disabled" : ""}>
                    <PaginationLink onClick={() => this.selectPage(currentPage + 1)}>
                        <i className="ni ni-bold-right"/>
                        <span className="sr-only">Next</span>
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        </nav>;
    }

    private getVisiblePages(): number[] {
        const pages = this.getPages();
        const currentPage = this.props.currentPage;
        const visiblePages = [currentPage];
        const nextPage = pages[currentPage];

        if (currentPage === 1) {
            if (nextPage) {
                visiblePages.push(nextPage);
            }

            if (pages[currentPage + 1]) {
                visiblePages.push(nextPage + 1);
            }
        } else {
            const lastPage = pages[pages.length - 1];
            const previousPage = pages[currentPage - 2];

            if (currentPage === lastPage) {
                if (previousPage) {
                    visiblePages.unshift(previousPage);
                }

                if (pages[currentPage - 3]) {
                    visiblePages.unshift(previousPage - 1);
                }
            } else {
                visiblePages.unshift(previousPage);
                visiblePages.push(nextPage);
            }
        }

        return visiblePages;
    }

    private getPages(): number[] {
        const list = this.props.list;
        const limit = list.limit;
        const pages = [1];
        let currentPage = 1;

        for (let i = limit; i < list.count; i = i + limit) {
            currentPage = currentPage + 1;
            pages.push(currentPage);
        }

        return pages;
    }

    private renderPage(page: number): JSX.Element {
        return <PaginationItem key={page} className={page === this.props.currentPage ? "active" : ""}>
            <PaginationLink onClick={() => this.selectPage(page)}>{page}</PaginationLink>
        </PaginationItem>;
    }

    private selectPage(page: number) {
        this.props.onClick(page);
    }
}