import {action, observable, reaction} from "mobx";
import RootStore from "./root-store";
import {LocalStorageEnum} from "../models/local-storage";
import NotificationStore from "./notification-store";

export default class BaseStore {
    sessionToken: string | undefined;

    @observable
    loading: boolean = false;

    @observable
    saving: boolean = false;

    @observable
    deleting: boolean = false;

    @observable
    currentPage: number = 1;
    protected rootStore: RootStore;
    protected notificationStore: NotificationStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.notificationStore = this.rootStore.notificationStore;

        reaction(() => this.sessionToken, token => {
            const key = LocalStorageEnum.SessionToken;

            if (token) {
                localStorage.setItem(key, token);
            } else {
                localStorage.removeItem(key);
            }
        });
    }

    @action
    selectPage(page: number) {
        this.currentPage = page;
    }

    @action
    protected startLoading() {
        this.loading = true;
    }

    @action
    protected finishLoading() {
        this.loading = false;
    }

    @action
    protected startSaving() {
        this.saving = true;
    }

    @action
    protected finishSaving() {
        this.saving = false;
    }

    @action
    protected startDeleting() {
        this.deleting = true;
    }

    @action
    protected finishDeleting() {
        this.deleting = false;
    }
}