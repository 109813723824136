import RootApi from "./root-api";
import Axios, {AxiosResponse} from "axios";
import {ICompanyDto, ICompanyEmailAccountDto, ICompanyProviderDto} from "../models/company";

export default class CompanyApi extends RootApi {
    private readonly companiesEndpoint = "/companies/current";
    private readonly emailAccountsEndpoint = "/company/email-accounts";
    private readonly providersEndpoint = "/company/providers";

    async getCompany(): Promise<AxiosResponse> {
        return Axios.get(this.companiesEndpoint);
    }

    async getEmailAccounts(): Promise<AxiosResponse> {
        return Axios.get(this.emailAccountsEndpoint);
    }

    async getPaymentProviders(): Promise<AxiosResponse> {
        return Axios.get(`${this.providersEndpoint}/payment`);
    }

    async getEmailAccountById(id: number): Promise<AxiosResponse> {
        return Axios.get(`${this.emailAccountsEndpoint}/${id}`);
    }

    async getPaymentProviderById(id: number): Promise<AxiosResponse> {
        return Axios.get(`${this.providersEndpoint}/payment/${id}`);
    }

    async createEmailAccount(account: ICompanyEmailAccountDto): Promise<AxiosResponse> {
        return this.makePostCall(this.emailAccountsEndpoint, account);
    }

    async createPaymentProvider(provider: ICompanyProviderDto): Promise<AxiosResponse> {
        return this.makePostCall(`${this.providersEndpoint}/payment`, provider);
    }

    async updateCompany(company: ICompanyDto): Promise<AxiosResponse> {
        return Axios.put(this.companiesEndpoint, company);
    }

    async updateEmailAccount(account: ICompanyEmailAccountDto): Promise<AxiosResponse> {
        return Axios.put(`${this.emailAccountsEndpoint}/${account.id}`, account);
    }

    async updatePaymentProvider(provider: ICompanyProviderDto): Promise<AxiosResponse> {
        return Axios.put(`${this.providersEndpoint}/payment/${provider.id}`, provider);
    }

    async deleteEmailAccount(id: number): Promise<AxiosResponse> {
        return Axios.delete(`${this.emailAccountsEndpoint}/${id}`);
    }

    async deletePaymentProvider(id: number): Promise<AxiosResponse> {
        return Axios.delete(`${this.providersEndpoint}/payment/${id}`);
    }
}