import {IBaseDto} from "./base";
import {ICustomerDetailsDto} from "./customer";

export interface IOrderBaseDto extends IBaseDto {
    code: string,
    total: number,
    status: string,
    created: Date
}

export interface IOrderDto extends IOrderBaseDto {
    customerName: string
}

export interface IOrderDetailsDto extends IOrderBaseDto {
    processed: boolean,
    trackingCode?: string,
    comment: string,
    paymentMethod: string,
    couponCode?: string,
    discount?: number,
    customer: ICustomerDetailsDto,
    shipping: IOrderShippingDto,
    items: IOrderItemDto[]
}

export interface IOrderShippingDto {
    methodName: string,
    pickupPoint?: string
    address?: string,
    city?: string,
    zip?: string,
    county?: string,
    countryName: string,
    fee?: number
}

export interface IOrderItemDto extends IBaseDto {
    productCode?: string,
    productName: string,
    price: number,
    qty: number,
    parameters?: IOrderItemParameterDto[]
}

export interface IOrderItemParameterDto {
    name: string,
    value: string
}

export interface IOrderUpdateDto {
    orderId: number,
    trackingCode?: string,
    processed: boolean
}

export enum OrderStatusEnum {
    Pending = "Pending",
    Finalized = "Finalized",
    Paid = "Paid"
}