import {IBaseProps} from "../base/base";
import CountryStore from "../../stores/country-store";
import SimpleForm from "../base/form/simple-form";
import React, {createRef, RefObject} from "react";
import ShippingStore from "../../stores/shipping-store";
import CategoryStore from "../../stores/category-store";
import {ICountryDto} from "../../models/country";
import {IShippingMethodDto} from "../../models/shipping";
import {ICategoryDto} from "../../models/category";
import ZoneStore from "../../stores/zone-store";
import {IZoneDto} from "../../models/zone";

interface ICountryShippingMethodFormProps extends IBaseProps {
    categoryStore: CategoryStore,
    countryStore: CountryStore,
    shippingStore: ShippingStore,
    zoneStore: ZoneStore
}

export default class CountryShippingMethodForm extends SimpleForm<ICountryShippingMethodFormProps> {
    protected readonly FeeRef: RefObject<HTMLInputElement>;
    protected readonly EstimationInDaysRef: RefObject<HTMLInputElement>;

    componentDidMount() {
        const props = this.props;
        props.zoneStore.getZones();
        props.shippingStore.getMethods();
        props.categoryStore.getCategories();
    }

    constructor(props: ICountryShippingMethodFormProps) {
        super(props);
        this.FeeRef = createRef();
        this.EstimationInDaysRef = createRef();
    }

    protected renderZone(zone: IZoneDto): JSX.Element {
        const id = zone.id;
        return <option key={id} value={id}>{zone.name}</option>
    }

    protected async selectZone(e: React.ChangeEvent<HTMLSelectElement>) {
        const id = Number(e.target.value);
        await this.props.zoneStore.selectZone(id);
    }

    protected renderCountry(country: ICountryDto): JSX.Element {
        const id = country.id;
        return <option key={id} value={id}>{country.name}</option>;
    }

    protected selectCountry(e: React.ChangeEvent<HTMLSelectElement>) {
        const id = Number(e.target.value);
        this.props.countryStore.selectCountry(id);
    }

    protected renderMethod(method: IShippingMethodDto): JSX.Element {
        const id = method.id;
        return <option key={id} value={id}>{method.name}</option>;
    }

    protected selectMethod(e: React.ChangeEvent<HTMLSelectElement>) {
        const id = Number(e.target.value);
        this.props.shippingStore.selectMethodId(id);
    }

    protected renderCategory(category: ICategoryDto): JSX.Element {
        const id = category.id;
        return <option key={id} value={id}>{category.name}</option>;
    }

    protected selectCategory(e: React.ChangeEvent<HTMLSelectElement>) {
        const id = Number(e.target.value);
        this.props.categoryStore.selectCategory(id);
    }
}