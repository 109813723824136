import React from "react";
import {DropResult} from "react-beautiful-dnd";

interface IBaseListEditProps<T> {
    entities: T[],
    onChange: (entity: T[]) => void
}

export default class BaseListEdit<T> extends React.Component<IBaseListEditProps<T>> {
    protected onDragEnd(result: DropResult) {
        if (!result.destination) {
            return;
        }

        const reorderedEntities = this.reorder(result.source.index, result.destination.index);
        this.props.onChange(reorderedEntities);
    }

    protected getListStyle(isDraggingOver: boolean): any {
        return {
            background: isDraggingOver ? "lightblue" : "lightgrey"
        };
    }

    protected getItemStyle(isDragging: boolean, draggableStyle: any): any {
        return {
            background: isDragging ? "#2dce89" : "#ffffff",
            ...draggableStyle
        };
    }

    private reorder(startIdx: number, endIdx: number): T[] {
        const reorderedEntities = Array.from(this.props.entities);
        const [removed] = reorderedEntities.splice(startIdx, 1);
        reorderedEntities.splice(endIdx, 0, removed);

        for (let i = 0; i < reorderedEntities.length; i++) {
            // @ts-ignore
            reorderedEntities[i].position = i + 1;
        }

        return reorderedEntities;
    }
}