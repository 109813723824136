import BlogPostForm from "./blog-post-form";
import {inject, observer} from "mobx-react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup, InputGroupAddon, InputGroupText,
    Label,
    Row
} from "reactstrap";
import TextEditor from "../base/text-editor";
import {Link} from "react-router-dom";
import ButtonAction from "../base/button/button-action";
import React from "react";
import LoaderDetails from "../base/loader/loader-details";
import DatePicker from "../base/date-picker";
import {IBlogPostDetailsDto} from "../../models/blog";
import routes from "../../routes";

@inject('blogStore')
@observer
export default class BlogPostEdit extends BlogPostForm {
    componentDidMount() {
        const props = this.props;
        props.blogStore.getPostById(props.match.params.id);
    }

    render() {
        return !this.props.blogStore.loading ? this.renderPost() : <LoaderDetails/>;
    }

    private renderPost(): JSX.Element {
        const blogStore = this.props.blogStore;
        const post = blogStore.post;
        const title = post?.title;

        return <Card className="bg-secondary shadow">
            <CardHeader>
                <h6 className="text-uppercase ls-1">Blog post</h6>
                <h2>{title}</h2>
            </CardHeader>
            <CardBody>
                <Form innerRef={this.FormRef}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="form-control-label">Title</Label>
                                <Input innerRef={this.TitleRef} type="text" defaultValue={title} required={true}
                                       className="form-control-alternative" placeholder="Title"/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="form-control-label">Content</Label>
                                <TextEditor value={blogStore.content} defaultValue={post?.content}
                                            onChange={(content) => blogStore.updateContent(content)}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-calendar-grid-58"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <DatePicker innerRef={this.PublishedRef} placeholder="Published"
                                                defaultValue={post?.published}/>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col md="6">
                        <Link className="btn btn-danger btn-icon btn-3 w-100" to="/back-office/blog/posts">
                            <span className="btn-inner--icon">
                                <i className="ni ni-bold-left"/>
                            </span>
                            <span className="btn-inner--text">Back</span>
                        </Link>
                    </Col>
                    <Col md="6">
                        <ButtonAction loading={blogStore.saving}>
                            <Button className="btn-icon btn-3 w-100" role="link" color="success"
                                    onClick={async (e) => await this.initPostUpdate(e)}>
                                <span className="btn-inner--icon">
                                    <i className="ni ni-check-bold"/>
                                </span>
                                <span className="btn-inner--text">Save</span>
                            </Button>
                        </ButtonAction>
                    </Col>
                </Row>
            </CardFooter>
        </Card>;
    }

    private async initPostUpdate(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const valid = this.FormRef.current?.reportValidity();

        if (valid) {
            await this.updatePost();
        }
    }

    private async updatePost() {
        const props = this.props;
        const blogStore = props.blogStore;
        const existingPost = blogStore.post;

        if (existingPost) {
            const updatedPost: IBlogPostDetailsDto = {
                id: existingPost.id,
                title: this.TitleRef.current?.value
            }

            const publishedDateInput = this.PublishedRef.current?.state.selectedDate;

            if (publishedDateInput) {
                updatedPost.published = publishedDateInput.toDate();
            }

            const result = await blogStore.updatePost(updatedPost);

            if (result) {
                props.history.push(routes.blogPosts);
            }
        }
    }
}