import {IBaseProps} from "../base/base";
import ProductStore from "../../stores/product-store";
import {inject, observer} from "mobx-react";
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col, DropdownItem, DropdownMenu, DropdownToggle,
    Form,
    FormGroup,
    Input, InputGroup, InputGroupAddon, InputGroupText,
    Label,
    Row,
    Table,
    UncontrolledDropdown
} from "reactstrap";
import React, {createRef, RefObject} from "react";
import LinkCreate from "../base/link/link-create";
import LoaderList from "../base/loader/loader-list";
import {IProductDto} from "../../models/product";
import LinkEdit from "../base/link/link-edit";
import ButtonDelete from "../base/button/button-delete";
import BaseList from "../base/list/base-list";
import ModalDelete from "../base/modal-delete";
import BadgeBoolean from "../base/badge/badge-boolean";
import Paging from "../base/paging";
import ButtonAction from "../base/button/button-action";
import {NavLink} from "react-router-dom";
import {IPropertyDto, PropertyTypeEnum} from "../../models/base";
import routes from "../../routes";
import ProductListEdit from "./product-list-edit";
import ButtonReorder from "../base/button/button-reorder";
import ButtonRow from "../base/button/button-row";
import ButtonDropdown from "../base/button/button-dropdown";

interface IProductListProps extends IBaseProps {
    productStore: ProductStore
}

@inject('productStore')
@observer
export default class ProductList extends BaseList<IProductListProps> {
    private readonly formRef: RefObject<HTMLFormElement>;

    componentDidMount() {
        const productStore = this.props.productStore;
        productStore.searchProducts({page: productStore.currentPage});
        productStore.getProductProps();
        productStore.getProductSources();
    }

    constructor(props: IProductListProps) {
        super(props);
        this.formRef = createRef();
    }

    render() {
        const productStore = this.props.productStore;
        const productList = productStore.productList;
        const currentPage = productStore.currentPage;
        const loadingProducts = productStore.loading;
        const reordering = this.state.reordering;
        const search = productStore.search;

        return <Card className={reordering ? "card-highlighted" : "shadow"}>
            <CardHeader>
                <Row>
                    <Col md="6">
                        <h2>Products</h2>
                        {!reordering && <LinkCreate path={routes.products}/>}
                        <ButtonReorder reordering={reordering} saving={productStore.saving}
                                       onToggle={async () => await this.toggleOrdering()}
                                       onCancel={() => this.setState({reordering: false})}/>
                    </Col>
                    {!reordering && <Col md="6">
                        <Paging list={productList} currentPage={currentPage}
                                onClick={async (page) => await productStore.searchProducts({page: page})}/>
                    </Col>}
                </Row>
                <hr/>
                {!reordering && <Form innerRef={this.formRef}>
                    <Row>{productStore.productProps.map((prop) => this.renderProp(prop))}</Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                    <Input disabled={loadingProducts} type="checkbox" id="discounted-checkbox"
                                           className="custom-control-input" defaultChecked={search.isDiscounted}
                                           onChange={(e) => productStore.setDiscounted(e.target.checked)}/>
                                    <Label className="custom-control-label" htmlFor="discounted-checkbox">
                                        <span>Discounted</span>
                                    </Label>
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                    <Input disabled={loadingProducts} type="checkbox" id="unactive-checkbox"
                                           className="custom-control-input" defaultChecked={search.unActive}
                                           onChange={(e) => productStore.setUnActive(e.target.checked)}/>
                                    <Label className="custom-control-label" htmlFor="unactive-checkbox">
                                        <span>Unactive</span>
                                    </Label>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4" md="6">
                            <FormGroup>
                                <Input disabled={loadingProducts} type="text" defaultValue={search.term}
                                       className="form-control-alternative"
                                       onChange={(e) => productStore.setSearchTerm(e.target.value)}
                                       placeholder="Search by name or code..."/>
                            </FormGroup>
                        </Col>
                        <Col lg="4" md="6">
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Source</InputGroupText>
                                    </InputGroupAddon>
                                    <select className="custom-select form-control-alternative"
                                            defaultValue={search.source}
                                            disabled={productStore.loadingSources || loadingProducts}
                                            onChange={(e) => productStore.selectSource(e.target.value)}>
                                        <option value="">Choose...</option>
                                        {productStore.productSources.map((source) => ProductList.renderSource(source))}
                                    </select>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col lg="4">
                            <ButtonAction loading={loadingProducts}>
                                <Button className="btn-icon btn-3 w-100" role="link" color="success"
                                        onClick={async (e) => await this.initProductSearch(e)}>
                                    <span className="btn-inner--icon">
                                        <i className="ni ni-zoom-split-in"/>
                                    </span>
                                    <span className="btn-inner--text">Search</span>
                                </Button>
                            </ButtonAction>
                        </Col>
                    </Row>
                </Form>}
            </CardHeader>
            {!loadingProducts ? this.renderProducts() : <LoaderList/>}
            <CardFooter>
                <Row>
                    <Col md="6">
                        <h4>Total: {productList.count}</h4>
                    </Col>
                    {!reordering && <Col md="6">
                        <Paging list={productList} currentPage={currentPage}
                                onClick={async (page) => await productStore.searchProducts({page: page})}/>
                    </Col>}
                </Row>
            </CardFooter>
        </Card>;
    }

    private renderProducts(): JSX.Element {
        if (this.state.reordering) {
            return this.renderEditableTable();
        }

        return this.renderFixedTable();
    }

    private renderEditableTable(): JSX.Element {
        const productStore = this.props.productStore;

        return <Table className="align-self-center table-flush" responsive={true}>
            <thead className="thead-light">
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Current price</th>
                <th scope="col">Active</th>
                <th scope="col"/>
            </tr>
            </thead>
            <ProductListEdit entities={productStore.productList.entities}
                             onChange={(products) => productStore.setProductListEntities(products)}/>
        </Table>;
    }

    private renderFixedTable(): JSX.Element {
        return <Table className="align-self-center table-flush" responsive={true}>
            <thead className="thead-light">
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Current price</th>
                <th scope="col">Active</th>
                <th scope="col"/>
            </tr>
            </thead>
            <tbody>{this.props.productStore.productList.entities.map((product) => this.renderProduct(product))}</tbody>
        </Table>;
    }

    private renderProduct(product: IProductDto): JSX.Element {
        const id = product.id;
        const code = product.code;

        return <tr key={code || id}>
            <td>{product.name}</td>
            <td>{product.currentPrice.toFixed(2)}</td>
            <td>
                <BadgeBoolean isTrue={product.active}/>
            </td>
            <td>{this.renderBtnRow(id, code)}</td>
            <ModalDelete id={id} modal={this.state.deletingModal} onToggle={() => this.toggleDeletingModal(id)}
                         onDelete={async () => await this.deleteProduct(id)}
                         deleting={this.props.productStore.deleting}/>
        </tr>;
    }

    private renderBtnRow(productId: number, productCode?: string): JSX.Element {
        return <>
            <ButtonRow expandedClass="d-xl-table-cell">
                <LinkEdit
                    path={`${routes.products}/${productId}?refType=${"id"}`}/>
                <ButtonDelete disabled={productId === 0} onToggle={() => this.toggleDeletingModal(productId)}/>
                <UncontrolledDropdown>
                    <DropdownToggle className="btn-icon-only text-light" href="#" role="button" size="sm" color=""
                                    onClick={(e) => e.preventDefault()}>
                        <i className="fas fa-ellipsis-v"/>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right={true}>
                        {productId > 0 && <DropdownItem to={`${routes.products}/${productId}/images`} tag={NavLink}>
                            <i className="ni ni-image"/>
                            Images
                        </DropdownItem>}
                        <DropdownItem to={`${routes.products}/${productId}/parameter-values`} tag={NavLink}>
                            <i className="ni ni-ui-04"/>
                            Parameters
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </ButtonRow>
            <ButtonDropdown hidden="xl">
                {productId > 0 && <DropdownItem to={`${routes.products}/${productId}/images`} tag={NavLink}>
                    <i className="ni ni-image"/>
                    Images
                </DropdownItem>}
                <DropdownItem to={`${routes.products}/${productId}/parameter-values`} tag={NavLink}>
                    <i className="ni ni-ui-04"/>
                    Parameters
                </DropdownItem>
                <DropdownItem
                    to={`${routes.products}/${productId}?refType=${"id"}`}
                    tag={NavLink}>
                    <i className="ni ni-settings-gear-65"/>
                    Edit
                </DropdownItem>
                <DropdownItem onClick={() => this.toggleDeletingModal(productId)}>
                    <i className="ni ni-fat-remove"/>
                    Delete
                </DropdownItem>
            </ButtonDropdown>
        </>;
    }

    private async deleteProduct(id: number) {
        const result = await this.props.productStore.deleteProduct(id);

        if (result) {
            this.toggleDeletingModal(id);
        }
    }

    private async initProductSearch(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const valid = this.formRef.current?.reportValidity();

        if (valid) {
            await this.props.productStore.searchProducts({page: 1});
        }
    }

    private renderProp(prop: IPropertyDto): JSX.Element {
        const key = prop.key;

        return <Col xl="3" lg="4" md="6" key={key}>
            <FormGroup>{prop.type === PropertyTypeEnum.Bool ? this.renderBoolProp(prop) : this.renderStringProp(prop)}</FormGroup>
        </Col>;
    }

    private renderBoolProp(prop: IPropertyDto): JSX.Element {
        const productStore = this.props.productStore;
        const key = prop.key;
        const isTrue = productStore.search.additionalProps.find(x => x.key === key)?.value === "true";
        const id = `active-${key}`;

        return <div className="custom-control custom-control-alternative custom-checkbox">
            <Input disabled={productStore.loading} type="checkbox" id={id} defaultChecked={isTrue}
                   className="custom-control-input" onChange={(e) => {
                productStore.setSearchProp(String(e.target.checked), key, prop.type)
            }}/>
            <Label className="custom-control-label" htmlFor={id}>
                <span>{key}</span>
            </Label>
        </div>;
    }

    private renderStringProp(prop: IPropertyDto): JSX.Element {
        const productStore = this.props.productStore;
        const key = prop.key;
        const currentValue = productStore.search.additionalProps.find(x => x.key === key)?.value;

        return <Input disabled={productStore.loading} type="text" className="form-control-alternative"
                      defaultValue={currentValue} placeholder={`Search by ${key?.toLowerCase()}...`}
                      onChange={(e) => productStore.setSearchProp(e.target.value, key, prop.type)}/>;
    }

    private async toggleOrdering() {
        if (!this.state.reordering) {
            this.setState({reordering: !this.state.reordering});
        } else {
            const result = await this.props.productStore.updateProductsOrder();

            if (result) {
                this.setState({reordering: !this.state.reordering});
            }
        }
    }

    private static renderSource(source: string): JSX.Element {
        return <option key={source} value={source}>{source}</option>;
    }
}