import React from "react";
import {Col, Spinner} from "reactstrap";

interface IButtonActionProps {
    loading?: boolean,
    loadingAlign?: string,
    children: any
}

export default class ButtonAction extends React.Component<IButtonActionProps> {
    render() {
        const props = this.props;

        if (props.loading) {
            return <Col className={`mx-auto align-items-center text-${props.loadingAlign || "center"}`}>
                <Spinner size="sm"/>
            </Col>
        }

        return props.children;
    }
}