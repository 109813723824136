import {inject, observer} from "mobx-react";
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import React from "react";
import {Link} from "react-router-dom";
import ButtonAction from "../base/button/button-action";
import CategoryForm from "./category-form";
import routes from "../../routes";

@inject('categoryStore')
@observer
export default class CategoryCreate extends CategoryForm {
    render() {
        return <Card className="bg-secondary shadow">
            <CardHeader>
                <h6 className="text-uppercase ls-1">Create</h6>
                <h2>Category</h2>
            </CardHeader>
            <CardBody>
                <Form innerRef={this.FormRef}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="form-control-label">Name</Label>
                                <Input innerRef={this.NameRef} type="text" required={true}
                                       className="form-control-alternative" placeholder="Name"/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                    <Input innerRef={this.ActiveRef} id="active" type="checkbox" defaultChecked={true}
                                           className="custom-control-input"/>
                                    <Label className="custom-control-label" htmlFor="active">
                                        <span>Active</span>
                                    </Label>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col md="6">
                        <Link className="btn btn-danger btn-icon btn-3 w-100" to={routes.categories}>
                            <span className="btn-inner--icon">
                                <i className="ni ni-bold-left"/>
                            </span>
                            <span className="btn-inner--text">Back</span>
                        </Link>
                    </Col>
                    <Col md="6">
                        <ButtonAction loading={this.props.categoryStore.saving}>
                            <Button className="btn-icon btn-3 w-100" role="link" color="success"
                                    onClick={async (e) => await this.initCategoryCreate(e)}>
                                <span className="btn-inner--icon">
                                    <i className="ni ni-check-bold"/>
                                </span>
                                <span className="btn-inner--text">Save</span>
                            </Button>
                        </ButtonAction>
                    </Col>
                </Row>
            </CardFooter>
        </Card>;
    }

    private async initCategoryCreate(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const valid = this.FormRef.current?.reportValidity();

        if (valid) {
            await this.createCategory();
        }
    }

    private async createCategory() {
        const category = {
            id: 0,
            name: this.NameRef.current?.value,
            active: this.ActiveRef.current?.checked ?? true
        }

        const props = this.props;
        const result = await props.categoryStore.createCategory(category);

        if (result) {
            props.history.push(routes.categories);
        }
    }
}