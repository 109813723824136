import {IProductDto} from "../../models/product";
import React from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import BadgeBoolean from "../base/badge/badge-boolean";
import BaseListEdit from "../base/list/base-list-edit";

export default class ProductListEdit extends BaseListEdit<IProductDto> {
    render() {
        return <DragDropContext onDragEnd={(result) => this.onDragEnd(result)}>
            <Droppable droppableId="droppable">{(provided, snapshot) => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}
                       style={this.getListStyle(snapshot.isDraggingOver)}>{this.props.entities.map((product: IProductDto, idx) => this.renderProduct(product, idx))}{provided.placeholder}</tbody>
            )}
            </Droppable>
        </DragDropContext>;
    }

    private renderProduct(product: IProductDto, idx: number): JSX.Element {
        const ref = product.ref;

        return <Draggable key={ref} draggableId={ref || product.id.toString()} index={idx}>{(provided, snapshot) => (
            <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                <td>{product.name}</td>
                <td>{product.currentPrice.toFixed(2)}</td>
                <td>
                    <BadgeBoolean isTrue={product.active}/>
                </td>
                <td/>
            </tr>
        )}
        </Draggable>;
    }
}