import ParameterForm from "./parameter-form";
import {inject, observer} from "mobx-react";
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import React from "react";
import {Link} from "react-router-dom";
import ButtonAction from "../base/button/button-action";
import routes from "../../routes";

@inject('parameterStore')
@observer
export default class ParameterCreate extends ParameterForm {
    constructor(props: any) {
        super(props);
        this.props.parameterStore.initParameter();
    }

    render() {
        const parameterStore = this.props.parameterStore;

        return <Card className="bg-secondary shadow">
            <CardHeader>
                <h6 className="text-uppercase ls-1">Create</h6>
                <h2>Parameter</h2>
            </CardHeader>
            <CardBody>
                <Form innerRef={this.FormRef}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="form-control-label">Name</Label>
                                <Input innerRef={this.NameRef} type="text" required={true}
                                       className="form-control-alternative" placeholder="Name"/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                    <Input innerRef={this.ActiveRef} id="active" type="checkbox" defaultChecked={true}
                                           className="custom-control-input"/>
                                    <Label className="custom-control-label" htmlFor="active">
                                        <span>Active</span>
                                    </Label>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr className="my-3"/>
                    <Label className="form-control-label">Values</Label>
                    {parameterStore.parameter?.parameterValues?.map((value) => this.renderValue(value))}
                    <Row>
                        <Col>
                            <Button className="btn-icon btn-3" role="link" color="primary" size="sm"
                                    onClick={() => parameterStore.addParameterValue()}>
                                <span className="btn-inner--icon">
                                    <i className="ni ni-fat-add"/>
                                </span>
                                <span className="btn-inner--text">Add</span>
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col md="6">
                        <Link className="btn btn-danger btn-icon btn-3 w-100" to={routes.parameters}>
                            <span className="btn-inner--icon">
                                <i className="ni ni-bold-left"/>
                            </span>
                            <span className="btn-inner--text">Back</span>
                        </Link>
                    </Col>
                    <Col md="6">
                        <ButtonAction loading={parameterStore.saving}>
                            <Button className="btn-icon btn-3 w-100" role="link" color="success"
                                    onClick={async (e) => await this.initParameterCreate(e)}>
                                <span className="btn-inner--icon">
                                    <i className="ni ni-check-bold"/>
                                </span>
                                <span className="btn-inner--text">Save</span>
                            </Button>
                        </ButtonAction>
                    </Col>
                </Row>
            </CardFooter>
        </Card>;
    }

    private async initParameterCreate(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const valid = this.FormRef.current?.reportValidity();

        if (valid) {
            await this.createParameter();
        }
    }

    private async createParameter() {
        const parameter = {
            id: 0,
            name: this.NameRef.current?.value,
            active: this.ActiveRef.current?.checked ?? true
        };

        const props = this.props;
        const result = await props.parameterStore.createParameter(parameter);

        if (result) {
            props.history.push(routes.parameters);
        }
    }
}