import BaseStore from "./base-store";
import {IBlogPostDetailsDto, IBlogPostDto} from "../models/blog";
import {action, observable} from "mobx";

export default class BlogStore extends BaseStore {
    @observable
    posts: IBlogPostDto[] = [];

    @observable
    post: IBlogPostDetailsDto | undefined;

    @observable
    content: string = "";

    async getPosts() {
        try {
            this.startLoading();
            const result = await this.rootStore.blogApi.getPosts();
            this.setPosts(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async getPostById(id: number) {
        try {
            this.startLoading();
            const result = await this.rootStore.blogApi.getPostById(id);
            this.setPost(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async createPost(post: IBlogPostDetailsDto): Promise<boolean> {
        post.content = this.content;
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.blogApi.createPost(post);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess(`"${post.title}" created`);
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async updatePost(post: IBlogPostDetailsDto): Promise<boolean> {
        post.content = this.content;
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.blogApi.updatePost(post);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess(`"${post.title}" updated`);
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async updateImg(blogId: number): Promise<boolean> {
        const rootStore = this.rootStore;
        const imgStore = rootStore.imgStore;
        const img = imgStore.img;
        const notificationStore = this.notificationStore;

        if (!img) {
            notificationStore.notifyError("Please add image");
            return false;
        }

        const file = img.croppedFile;

        if (!file) {
            notificationStore.notifyError("Please finish cropping first");
            return false;
        }

        try {
            this.startSaving();
            const result = await rootStore.blogApi.updateImg(file, blogId);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess("Image uploaded");
                imgStore.resetImg();
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async deletePost(id: number): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startDeleting();
            const result = await this.rootStore.blogApi.deletePost(id);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                this.removePost(id);
                notificationStore.notifySuccess("Post deleted");
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishDeleting();
        }
    }

    @action
    unsetContent() {
        this.content = "";
    }

    @action
    updateContent(content: string) {
        this.content = content;
    }

    @action
    private setPosts(posts: IBlogPostDto[]) {
        this.posts = posts;
    }

    @action
    private setPost(post: IBlogPostDetailsDto) {
        const content = post.content || "";
        this.updateContent(content);
        this.post = post;
    }

    @action
    private removePost(id: number) {
        const post = this.posts.find(x => x.id === id);

        if (post) {
            const postIdx = this.posts.indexOf(post, 0);
            this.posts.splice(postIdx, 1);
        }
    }
}