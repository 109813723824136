import {IBaseProps} from "../base/base";
import BlogStore from "../../stores/blog-store";
import BaseList from "../base/list/base-list";
import {inject, observer} from "mobx-react";
import {Card, CardHeader, DropdownItem, Table} from "reactstrap";
import React from "react";
import LinkCreate from "../base/link/link-create";
import LoaderList from "../base/loader/loader-list";
import {IBlogPostDto} from "../../models/blog";
import LinkEdit from "../base/link/link-edit";
import ButtonDelete from "../base/button/button-delete";
import ModalDelete from "../base/modal-delete";
import DateUtil from "../../utils/date-util";
import {Link, NavLink} from "react-router-dom";
import routes from "../../routes";
import ButtonRow from "../base/button/button-row";
import ButtonDropdown from "../base/button/button-dropdown";

interface IBlogPostListProps extends IBaseProps {
    blogStore: BlogStore
}

@inject('blogStore')
@observer
export default class BlogPostList extends BaseList<IBlogPostListProps> {
    componentDidMount() {
        this.props.blogStore.getPosts();
    }

    render() {
        return <Card className="shadow">
            <CardHeader>
                <h2>Blog posts</h2>
                <LinkCreate path={routes.blogPosts}/>
            </CardHeader>
            {!this.props.blogStore.loading ? this.renderPosts() : <LoaderList/>}
        </Card>;
    }

    private renderPosts(): JSX.Element {
        return <Table className="align-items-center table-flush" responsive={true}>
            <thead className="thead-light">
            <tr>
                <th scope="col"/>
                <th scope="col">Title</th>
                <th scope="col">Published</th>
                <th scope="col"/>
            </tr>
            </thead>
            <tbody>{this.props.blogStore.posts.map((post) => this.renderPost(post))}</tbody>
        </Table>;
    }

    private renderPost(post: IBlogPostDto): JSX.Element {
        const id = post.id;
        const published = post.published && DateUtil.format(post.published);
        const title = post.title;

        return <tr key={id}>
            <td className="w-25">
                <img className="img-fluid" src={post.imgPath} alt={title}/>
            </td>
            <td>{title}</td>
            <td>{published}</td>
            <td>{this.renderBtnRow(id)}</td>
            <ModalDelete id={id} modal={this.state.deletingModal} onToggle={() => this.toggleDeletingModal(id)}
                         onDelete={async () => await this.deletePost(id)} deleting={this.props.blogStore.deleting}/>
        </tr>;
    }

    private renderBtnRow(postId: number): JSX.Element {
        return <>
            <ButtonRow expandedClass="d-xl-table-cell">
                <Link className="btn btn-info btn-sm btn-icon btn-3" to={`${routes.blogPosts}/${postId}/image`}>
                    <span className="btn-inner--icon">
                        <i className="ni ni-image"/>
                    </span>
                    <span className="btn-inner--text">Image</span>
                </Link>
                <LinkEdit path={`${routes.blogPosts}/${postId}`}/>
                <ButtonDelete onToggle={() => this.toggleDeletingModal(postId)}/>
            </ButtonRow>
            <ButtonDropdown hidden="xl">
                <DropdownItem to={`${routes.blogPosts}/${postId}/image`} tag={NavLink}>
                    <i className="ni ni-image"/>
                    Image
                </DropdownItem>
                <DropdownItem to={`${routes.blogPosts}/${postId}`} tag={NavLink}>
                    <i className="ni ni-settings-gear-65"/>
                    Edit
                </DropdownItem>
                <DropdownItem onClick={() => this.toggleDeletingModal(postId)}>
                    <i className="ni ni-fat-remove"/>
                    Delete
                </DropdownItem>
            </ButtonDropdown>
        </>;
    }

    private async deletePost(id: number) {
        const result = await this.props.blogStore.deletePost(id);

        if (result) {
            this.toggleDeletingModal(id);
        }
    }
}