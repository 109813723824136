import {IBaseProps} from "../base/base";
import ShippingStore from "../../stores/shipping-store";
import BaseList from "../base/list/base-list";
import {inject, observer} from "mobx-react";
import {Card, CardHeader, Table} from "reactstrap";
import React from "react";
import LinkCreate from "../base/link/link-create";
import LoaderList from "../base/loader/loader-list";
import {IShippingMethodDto} from "../../models/shipping";
import ModalDelete from "../base/modal-delete";
import BadgeBoolean from "../base/badge/badge-boolean";
import routes from "../../routes";

interface IShippingMethodListProps extends IBaseProps {
    shippingStore: ShippingStore
}

@inject('shippingStore')
@observer
export default class ShippingMethodList extends BaseList<IShippingMethodListProps> {
    componentDidMount() {
        this.props.shippingStore.getMethods();
    }

    render() {
        return <Card className="shadow">
            <CardHeader>
                <h2>Shipping methods</h2>
                <LinkCreate path={routes.shippingMethods}/>
            </CardHeader>
            {!this.props.shippingStore.loading ? this.renderMethods() : <LoaderList/>}
        </Card>;
    }

    private renderMethods(): JSX.Element {
        return <Table className="align-items-center table-flush" responsive={true}>
            <thead className="thead-light">
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Provider</th>
                <th scope="col">Address required</th>
                <th scope="col">Active</th>
                <th scope="col"/>
            </tr>
            </thead>
            <tbody>{this.props.shippingStore.methods.map((method) => this.renderMethod(method))}</tbody>
        </Table>;
    }

    private renderMethod(method: IShippingMethodDto): JSX.Element {
        const id = method.id;

        return <tr key={id}>
            <td>{method.name}</td>
            <td>{method.provider}</td>
            <td>
                <BadgeBoolean isTrue={method.addressRequired}/>
            </td>
            <td>
                <BadgeBoolean isTrue={method.active}/>
            </td>
            <td>{this.renderDefaultBtnRow(id, routes.shippingMethods)}</td>
            <ModalDelete id={id} modal={this.state.deletingModal} onToggle={() => this.toggleDeletingModal(id)}
                         onDelete={async () => await this.deleteMethod(id)}
                         deleting={this.props.shippingStore.deleting}/>
        </tr>;
    }

    private async deleteMethod(id: number) {
        const result = await this.props.shippingStore.deleteMethod(id);

        if (result) {
            this.toggleDeletingModal(id);
        }
    }
}