import {IBaseProps} from "../base/base";
import ProductStore from "../../stores/product-store";
import SimpleForm from "../base/form/simple-form";
import {createRef, RefObject} from "react";
import ImgStore from "../../stores/img-store";

interface IProductImgFormProps extends IBaseProps {
    imgStore: ImgStore,
    productStore: ProductStore
}

export default class ProductImgForm extends SimpleForm<IProductImgFormProps> {
    protected readonly PrimaryRef: RefObject<HTMLInputElement>;

    constructor(props: IProductImgFormProps) {
        super(props);
        this.PrimaryRef = createRef();
    }
}