import BaseList from "../base/list/base-list";
import {IBaseProps} from "../base/base";
import CouponStore from "../../stores/coupon-store";
import {inject, observer} from "mobx-react";
import {Card, CardHeader, Table} from "reactstrap";
import React from "react";
import LinkCreate from "../base/link/link-create";
import LoaderList from "../base/loader/loader-list";
import {ICouponDto} from "../../models/coupon";
import BadgeBoolean from "../base/badge/badge-boolean";
import ModalDelete from "../base/modal-delete";
import routes from "../../routes";

interface ICouponListProps extends IBaseProps {
    couponStore: CouponStore
}

@inject('couponStore')
@observer
export default class CouponList extends BaseList<ICouponListProps> {
    componentDidMount() {
        this.props.couponStore.getCoupons();
    }

    render() {
        return <Card className="shadow">
            <CardHeader>
                <h2>Coupons</h2>
                <LinkCreate path={routes.coupons}/>
            </CardHeader>
            {!this.props.couponStore.loading ? this.renderCoupons() : <LoaderList/>}
        </Card>;
    }

    private renderCoupons(): JSX.Element {
        return <Table className="align-items-center table-flush" responsive={true}>
            <thead className="thead-light">
            <tr>
                <th scope="col">Code</th>
                <th scope="col">Percentage</th>
                <th scope="col">Active</th>
                <th scope="col"/>
            </tr>
            </thead>
            <tbody>{this.props.couponStore.coupons.map((coupon) => this.renderCoupon(coupon))}</tbody>
        </Table>;
    }

    private renderCoupon(coupon: ICouponDto): JSX.Element {
        const id = coupon.id;

        return <tr key={id}>
            <td>{coupon.code}</td>
            <td>{coupon.percentage.toFixed(2)}</td>
            <td>
                <BadgeBoolean isTrue={coupon.active}/>
            </td>
            <td>{this.renderDefaultBtnRow(id, routes.coupons)}</td>
            <ModalDelete id={id} modal={this.state.deletingModal} onToggle={() => this.toggleDeletingModal(id)}
                         onDelete={async () => await this.deleteCoupon(id)} deleting={this.props.couponStore.deleting}/>
        </tr>;
    }

    private async deleteCoupon(id: number) {
        const result = await this.props.couponStore.deleteCoupon(id);

        if (result) {
            this.toggleDeletingModal(id);
        }
    }
}