import Base, {IBaseProps} from "../base/base";
import OrderStore from "../../stores/order-store";
import LoaderDetails from "../base/loader/loader-details";
import React from "react";
import {Card, CardBody, CardHeader, Col, Label, Row, Table} from "reactstrap";
import {Link} from "react-router-dom";
import DateUtil from "../../utils/date-util";
import {inject, observer} from "mobx-react";
import {IOrderItemDto, IOrderItemParameterDto} from "../../models/order";
import routes from "../../routes";

interface IOrderDetailsProps extends IBaseProps {
    orderStore: OrderStore
}

@inject('orderStore')
@observer
export default class OrderDetails extends Base<IOrderDetailsProps> {
    componentDidMount() {
        const props = this.props;
        props.orderStore.getOrderById(props.match.params.id);
    }

    render() {
        return !this.props.orderStore.loading ? this.renderOrder() : <LoaderDetails/>;
    }

    private renderOrder(): JSX.Element {
        const order = this.props.orderStore.order;
        const created = order?.created && DateUtil.format(order.created);
        const customer = order?.customer;
        const shipping = order?.shipping;
        const shippingFee = shipping?.fee;
        const discount = order?.discount;

        return <Card className="bg-secondary shadow">
            <CardHeader>
                <h6 className="text-uppercase ls-1">Order</h6>
                <h2>{order?.code}</h2>
                <Link className="btn btn-sm btn-danger btn-icon btn-3" to={routes.orders}>
                    <span className="btn-inner--icon">
                        <i className="ni ni-bold-left"/>
                    </span>
                    <span className="btn-inner--text">Back</span>
                </Link>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <Label>Created</Label>
                                <p>{created}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h3>Customer</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table className="align-items-center table-flush" responsive={true}>
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Company</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">E-mail</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{customer?.firstName} {customer?.lastName}</td>
                                        <td>{customer?.companyName}</td>
                                        <td>{customer?.phone}</td>
                                        <td>{customer?.email}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col>{this.renderItems()}</Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col>
                                <h3>Shipping</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <Label>Country</Label>
                                <p>{shipping?.countryName}</p>
                            </Col>
                            <Col md="6">
                                <Label>Method</Label>
                                <p>{shipping?.methodName}</p>
                            </Col>
                        </Row>
                        {shipping?.pickupPoint && <Row>
                            <Col>
                                <Label>Pickup point</Label>
                                <p>{shipping.pickupPoint}</p>
                            </Col>
                        </Row>}
                        {shipping?.address && <Row>
                            <Col>
                                <Label>Address</Label>
                                <p>{shipping.address}</p>
                            </Col>
                        </Row>}
                        <Row>
                            {shipping?.city && <Col md="6">
                                <Label>City</Label>
                                <p>{shipping.city}</p>
                            </Col>}
                            {shipping?.zip && <Col md="6">
                                <Label>Zip</Label>
                                <p>{shipping.zip}</p>
                            </Col>}
                        </Row>
                        {shipping?.county && <Row>
                            <Col>
                                <Label>County</Label>
                                <p>{shipping.county}</p>
                            </Col>
                        </Row>}
                        {order?.comment && <div>
                            <hr/>
                            <Row>
                                <Col>
                                    <h3>Comment</h3>
                                    <p>{order.comment}</p>
                                </Col>
                            </Row>
                        </div>}
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col>
                        <h3>Payment</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table className="align-items-center table-flush" responsive={true}>
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">Shipping fee</th>
                                <th scope="col">Coupon code</th>
                                <th scope="col">Discount</th>
                                <th scope="col">Payment method</th>
                                <th scope="col">Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{shippingFee && `${shippingFee.toFixed(2)}€`}</td>
                                <td>{order?.couponCode}</td>
                                <td>{discount && `${discount.toFixed(2)}€`}</td>
                                <td>{order?.paymentMethod}</td>
                                <td>{order?.total.toFixed(2)}€</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </CardBody>
        </Card>;
    }

    private renderItems(): JSX.Element {
        return <Row>
            <Col>
                <h3>Items</h3>
                <Table className="align-items-center table-flush" responsive={true}>
                    <thead className="thead-light">
                    <tr>
                        <th scope="col">Code</th>
                        <th scope="col">Name</th>
                        <th scope="col">Parameters</th>
                        <th scope="col">Price</th>
                        <th scope="col">Qty</th>
                    </tr>
                    </thead>
                    <tbody>{this.props.orderStore.order?.items.map((item) => OrderDetails.renderItem(item))}</tbody>
                </Table>
            </Col>
        </Row>;
    }

    private static renderItem(item: IOrderItemDto): JSX.Element {
        const id = item.id;

        return <tr key={id}>
            <td>{item.productCode}</td>
            <td>{item.productName}</td>
            <td>{item.parameters?.map((parameter) => this.renderItemParameter(parameter))}</td>
            <td>{item.price.toFixed(2)} €</td>
            <td>{item.qty}</td>
        </tr>;
    }

    private static renderItemParameter(parameter: IOrderItemParameterDto): JSX.Element {
        const name = parameter.name;
        return <p key={name} className="font-weight-normal font-13">{name}: {parameter.value}</p>;
    }
}