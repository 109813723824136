import {IBaseProps} from "../base/base";
import ParameterStore from "../../stores/parameter-store";
import SimpleForm from "../base/form/simple-form";
import React, {createRef, RefObject} from "react";
import {IParameterValue} from "../../models/parameter";
import {Button, Col, FormGroup, Input, Row} from "reactstrap";

interface IParameterFormProps extends IBaseProps {
    parameterStore: ParameterStore
}

export default class ParameterForm extends SimpleForm<IParameterFormProps> {
    protected readonly NameRef: RefObject<HTMLInputElement>;

    constructor(props: IParameterFormProps) {
        super(props);
        this.NameRef = createRef();
    }

    protected renderValue(parameterValue: IParameterValue): JSX.Element {
        const idx = parameterValue.idx;
        const parameterStore = this.props.parameterStore;

        return <Row key={idx}>
            <Col md="6">
                <FormGroup>
                    <Input type="text" required={true} className="form-control-alternative" placeholder="Value"
                           onChange={(e) => parameterStore.editParameterValue(e.target.value, idx)}
                           defaultValue={parameterValue.value}/>
                </FormGroup>
            </Col>
            <Col md="6">
                <Button className="btn-icon btn-3" role="link" color="warning"
                        onClick={() => parameterStore.removeParameterValue(idx)}>
                    <span className="btn-inner--icon">
                        <i className="ni ni-fat-remove"/>
                    </span>
                    <span className="btn-inner--text">Remove</span>
                </Button>
            </Col>
        </Row>;
    }
}