import React from "react";

interface IButtonRowProps {
    expandedClass?: string
}

export default class ButtonRow extends React.Component<IButtonRowProps> {
    render() {
        const expandedClass = this.renderExpandedClass();
        return <div className={`d-none ${expandedClass}`}>{this.props.children}</div>;
    }

    private renderExpandedClass(): string {
        return this.props.expandedClass || "d-lg-table-cell";
    }
}