import {RouteProps, Route, Redirect} from "react-router-dom";
import React from "react";
import {IUserDto} from "../models/user";
import routes from "../routes";

interface IPrivateRouteProps extends RouteProps {
    component?: any,
    user?: IUserDto
}

export default class PrivateRoute extends React.Component<IPrivateRouteProps> {
    render() {
        const {component: Component, ...rest} = this.props;

        return <Route {...rest}
                      render={(props) => this.props.user ? <Component {...props}/> : <Redirect to={routes.login}/>}/>;
    }
}