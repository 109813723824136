import Axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {LocalStorageEnum} from "../models/local-storage";
import {IQueryParams} from "../models/base";
import {LanguageEnum} from "../models/locale";

function initRequest(cfg: AxiosRequestConfig): AxiosRequestConfig {
    const headers = cfg.headers;

    if (headers) {
        const language = localStorage.getItem(LocalStorageEnum.Language);
        headers["Accept-Language"] = language || LanguageEnum.En;
        const token = localStorage.getItem(LocalStorageEnum.SessionToken);

        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }
    }

    return cfg;
}

Axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT || "";
Axios.interceptors.request.use((cfg) => initRequest(cfg));

export default class RootApi {
    protected makeFormPostCall(endpoint: string, request: FormData): Promise<AxiosResponse> {
        const cfg = {
            headers: {
                "Content-type": "multipart/form-data"
            }
        };

        return this.makePostCall(endpoint, request, undefined, cfg);
    }

    protected makePostCall(endpoint: string, request: any = {}, queryParams?: IQueryParams, cfg?: AxiosRequestConfig): Promise<AxiosResponse> {
        if (queryParams) {
            const page = queryParams.page;

            if (page) {
                endpoint = `${endpoint}?page=${page}`;
            }
        }

        return Axios.post(endpoint, request, cfg);
    }
}