import BaseStore from "./base-store";
import {action, observable} from "mobx";
import {IImg} from "../models/img";

export default class ImgStore extends BaseStore {
    @observable
    cropping: boolean = false;

    @observable
    savingCrop: boolean = false;

    @observable
    img: IImg | undefined;

    async uploadImg(file: Blob): Promise<any> {
        return await this.rootStore.imgApi.uploadImg(file);
    }

    @action
    dropImg(img: IImg) {
        this.img = img;
        this.startCropping();
    }

    @action
    cropImg(file: Blob) {
        const notificationStore = this.notificationStore;

        if (this.img) {
            this.img.croppedFile = file;
            notificationStore.notifySuccess("Image cropped");
            this.finishCropping();
        } else {
            notificationStore.notifyError("Please add image first");
        }
    }

    @action
    resetImg() {
        if (this.img?.previewSrc) {
            URL.revokeObjectURL(this.img.previewSrc);
        }

        this.img = undefined;
    }

    @action
    startSavingCrop() {
        this.savingCrop = true;
    }

    @action
    private startCropping() {
        this.cropping = true;
    }

    @action
    private finishCropping() {
        this.savingCrop = false;
        this.cropping = false;
    }
}