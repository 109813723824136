import React from "react";
import {
    Col,
    Collapse,
    Container, DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Media,
    Nav,
    Navbar,
    NavbarBrand, NavItem, NavLink, Row,
    UncontrolledDropdown
} from "reactstrap";
import {Link, NavLink as NavLinkRRD} from "react-router-dom";
import Banner from "../../assets/imgs/Banner.png";
import Profile from "../../assets/imgs/Profile.png";
import UserStore from "../../stores/user-store";
import Base, {IBaseProps} from "../base/base";
import {inject, observer} from "mobx-react";
import routes from "../../routes";

interface INavMenuSideProps extends IBaseProps {
    userStore?: UserStore
}

interface INavMenuSideState {
    collapseOpen: boolean
}

@inject('userStore')
@observer
export default class NavMenuSide extends Base<INavMenuSideProps, INavMenuSideState> {
    constructor(props: INavMenuSideProps) {
        super(props);

        this.state = {
            collapseOpen: false
        };
    }

    render() {
        return <Navbar className="navbar-vertical fixed-left navbar-light bg-white" expand="md" id="sidenav-main">
            <Container fluid={true}>
                <button className="navbar-toggler" type="button" onClick={() => this.toggleCollapse()}>
                    <span className="navbar-toggler-icon"/>
                </button>
                <NavbarBrand to={routes.backoffice} tag={Link}>
                    <img alt="Vanilli" className="navbar-brand-img" src={Banner}/>
                </NavbarBrand>
                <Nav className="align-items-center d-md-none">
                    <UncontrolledDropdown nav={true}>
                        <DropdownToggle nav={true}>
                            <Media className="align-items-center">
                                <span className="avatar avatar-sm rounded-circle">
                                    <img alt="Profile" src={Profile}/>
                                </span>
                            </Media>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right={true}>
                            <DropdownItem className="noti-title" header={true} tag="div">
                                <h6 className="text-overflow">Welcome!</h6>
                            </DropdownItem>
                            <DropdownItem onClick={() => this.props.userStore?.signOut()}>
                                <i className="ni ni-user-run"/>
                                <span>Log out</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
                <Collapse navbar={true} isOpen={this.state.collapseOpen}>
                    <div className="navbar-collapse-header d-md-none">
                        <Row>
                            <Col className="collapse-brand" xs="6">
                                <Link to={routes.backoffice}>
                                    <img alt="Vanilli" src={Banner}/>
                                </Link>
                            </Col>
                            <Col className="collapse-close" xs="6">
                                <button className="navbar-toggler" type="button" onClick={() => this.toggleCollapse()}>
                                    <span/>
                                    <span/>
                                </button>
                            </Col>
                        </Row>
                    </div>
                    <Nav navbar={true}>
                        <NavItem>
                            <NavLink to={routes.orders} tag={NavLinkRRD} onClick={() => this.closeCollapse()}>
                                <i className="ni ni-box-2"/>
                                Orders
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to={routes.customers} tag={NavLinkRRD} onClick={() => this.closeCollapse()}>
                                <i className="ni ni-single-02"/>
                                Customers
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <hr className="my-3"/>
                    <Nav navbar={true}>
                        <NavItem>
                            <NavLink to={routes.categories} tag={NavLinkRRD} onClick={() => this.closeCollapse()}>
                                <i className="ni ni-ungroup"/>
                                Categories
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to={routes.products} tag={NavLinkRRD} onClick={() => this.closeCollapse()}>
                                <i className="ni ni-tag"/>
                                Products
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to={routes.parameters} tag={NavLinkRRD} onClick={() => this.closeCollapse()}>
                                <i className="ni ni-ui-04"/>
                                Product parameters
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <hr className="my-3"/>
                    <Nav navbar={true}>
                        <NavItem>
                            <NavLink to={routes.zones} tag={NavLinkRRD} onClick={() => this.closeCollapse()}>
                                <i className="ni ni-world-2"/>
                                Zones
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to={routes.countries} tag={NavLinkRRD} onClick={() => this.closeCollapse()}>
                                <i className="ni ni-world"/>
                                Countries
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to={routes.shippingMethods} tag={NavLinkRRD} onClick={() => this.closeCollapse()}>
                                <i className="ni ni-delivery-fast"/>
                                Shipping methods
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to={routes.countryShippings} tag={NavLinkRRD} onClick={() => this.closeCollapse()}>
                                <i className="ni ni-settings-gear-65"/>
                                Shipping settings
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <hr className="my-3"/>
                    <Nav navbar={true}>
                        <NavItem>
                            <NavLink to={routes.coupons} tag={NavLinkRRD} onClick={() => this.closeCollapse()}>
                                <i className="ni ni-map-big"/>
                                Coupons
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to={routes.blogPosts} tag={NavLinkRRD} onClick={() => this.closeCollapse()}>
                                <i className="ni ni-collection"/>
                                Blog
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <hr className="my-3"/>
                    <Nav navbar={true}>
                        <NavItem>
                            <NavLink to={routes.company} tag={NavLinkRRD} onClick={() => this.closeCollapse()}>
                                <i className="ni ni-building"/>
                                Company info
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to={routes.companyEmails} tag={NavLinkRRD} onClick={() => this.closeCollapse()}>
                                <i className="ni ni-email-83"/>
                                E-mail accounts
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to={routes.companyPaymentProviders} tag={NavLinkRRD}
                                     onClick={() => this.closeCollapse()}>
                                <i className="ni ni-money-coins"/>
                                Payment providers
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>;
    }

    private toggleCollapse() {
        this.setState({
            collapseOpen: !this.state.collapseOpen
        });
    }

    private closeCollapse() {
        this.setState({
            collapseOpen: false
        });
    }
}