import {IBaseProps} from "../base/base";
import BlogStore from "../../stores/blog-store";
import BaseForm from "../base/form/base-form";
import {createRef, RefObject} from "react";
import DatePicker from "../base/date-picker";

interface IBlogPostFormProps extends IBaseProps {
    blogStore: BlogStore
}

export default class BlogPostForm extends BaseForm<IBlogPostFormProps> {
    protected readonly TitleRef: RefObject<HTMLInputElement>;
    protected readonly PublishedRef: RefObject<DatePicker>;

    constructor(props: IBlogPostFormProps) {
        super(props);
        this.TitleRef = createRef();
        this.PublishedRef = createRef();
    }
}