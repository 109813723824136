import React from "react";
import {Link} from "react-router-dom";

interface ILinkCreateProps {
    path: string
}

export default class LinkCreate extends React.Component<ILinkCreateProps> {
    render() {
        return <Link className="btn btn-success btn-sm btn-icon btn-3" to={`${this.props.path}/create`}>
            <span className="btn-inner--icon">
                <i className="ni ni-fat-add"/>
            </span>
            <span className="btn-inner--text">Create</span>
        </Link>;
    }
}