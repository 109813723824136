import React from "react";

export default class Overlay extends React.Component {
    render() {
        return <div>
            <div className="wrapper"/>
            <div className="overlay"/>
            <div className="spanner">
                <div className="loader"/>
                <h1>Loading your session</h1>
            </div>
        </div>;
    }
}