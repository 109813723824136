import RootStore from "../../stores/root-store";
import React from "react";

export interface IBaseProps {
    history?: any,
    match?: any,
    location?: any,
    rootStore?: RootStore
}

export default class Base<T extends IBaseProps, S = {}> extends React.Component<T, S> {
    protected rootStore: RootStore | undefined;

    constructor(props: T) {
        super(props);
        this.rootStore = props.rootStore;
    }
}