import BaseStore from "./base-store";
import {ICategoryDto, ICategoryParameterValue, ICategoryParameterValueDto} from "../models/category";
import {action, observable} from "mobx";
import {v4} from "uuid";

export default class CategoryStore extends BaseStore {
    @observable
    categories: ICategoryDto[] = [];

    @observable
    category: ICategoryDto | undefined;

    @observable
    parameterValues: ICategoryParameterValue[] = [];

    @observable
    selectedCategoryId: number | undefined;

    async getCategories() {
        try {
            this.startLoading();
            const result = await this.rootStore.categoryApi.getCategories();
            this.setCategories(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async getCategoryById(id: number) {
        try {
            this.startLoading();
            const result = await this.rootStore.categoryApi.getCategoryById(id);
            this.setCategory(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async getParameterValues(categoryId: number) {
        try {
            this.startLoading();
            const result = await this.rootStore.categoryApi.getParameterValues(categoryId);
            this.setParameterValues(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async createCategory(category: ICategoryDto): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.categoryApi.createCategory(category);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess(`${category.name} created`);
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async updateCategory(category: ICategoryDto): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.categoryApi.updateCategory(category);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess(`${category.name} updated`);
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async updateParameterValues(categoryId: number): Promise<boolean> {
        const dtos = this.parameterValues.map((parameterValue) => CategoryStore.mapToParameterValueDto(parameterValue));
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.categoryApi.updateParameterValues(categoryId, dtos);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess("Category parameter values updated");
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async deleteCategory(id: number): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startDeleting();
            const result = await this.rootStore.categoryApi.deleteCategory(id);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                this.removeCategory(id);
                notificationStore.notifySuccess("Category deleted");
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishDeleting();
        }
    }

    @action
    selectCategory(id: number) {
        if (id) {
            this.selectedCategoryId = id;
        } else {
            this.selectedCategoryId = undefined;
        }
    }

    @action
    addParameterValue() {
        this.parameterValues.push({
            id: 0,
            idx: v4(),
            parameterName: undefined,
            parameterValueId: undefined,
            active: true
        });
    }

    @action
    selectParameterValue(valueId: number, idx: string) {
        const existingValue = this.parameterValues.find(x => x.idx === idx);

        if (existingValue) {
            existingValue.parameterValueId = valueId;
        }
    }

    @action
    setParameterValueActive(active: boolean, idx: string) {
        const existingValue = this.parameterValues.find(x => x.idx === idx);

        if (existingValue) {
            existingValue.active = active;
        }
    }

    @action
    removeParameterValue(idx: string) {
        const parameterValueIdx = this.parameterValues.findIndex(x => x.idx === idx);

        if (parameterValueIdx >= 0) {
            this.parameterValues.splice(parameterValueIdx, 1);
        }
    }

    @action
    private setCategories(categories: ICategoryDto[]) {
        this.categories = categories;
    }

    @action
    private setCategory(category: ICategoryDto) {
        this.category = category;
    }

    @action
    private removeCategory(id: number) {
        const category = this.categories.find(x => x.id === id);

        if (category) {
            const categoryIdx = this.categories.indexOf(category, 0);
            this.categories.splice(categoryIdx, 1);
        }
    }

    @action
    private setParameterValues(parameterValues: ICategoryParameterValueDto[]) {
        this.parameterValues = parameterValues.map((parameterValue) => {
            return CategoryStore.mapToParameterValue(parameterValue);
        });
    }

    private static mapToParameterValue(parameterValue: ICategoryParameterValueDto): ICategoryParameterValue {
        return {
            id: parameterValue.id,
            idx: v4(),
            parameterName: parameterValue.parameterName,
            parameterValueId: parameterValue.parameterValueId,
            active: parameterValue.active
        };
    }

    private static mapToParameterValueDto(parameterValue: ICategoryParameterValue): ICategoryParameterValueDto {
        return {
            id: parameterValue.id,
            parameterName: parameterValue.parameterName,
            parameterValueId: parameterValue.parameterValueId,
            active: parameterValue.active
        }
    }
}