import React, {createRef, RefObject} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText,
    Navbar,
    NavbarBrand,
    Row,
    UncontrolledCollapse
} from "reactstrap";
import {Link} from "react-router-dom";
import Banner from "../assets/imgs/Banner-light.png";
import Base, {IBaseProps} from "./base/base";
import UserStore from "../stores/user-store";
import {inject, observer} from "mobx-react";
import ButtonAction from "./base/button/button-action";
import FooterContent from "./footer/footer-content";
import routes from "../routes";

interface ILoginProps extends IBaseProps {
    userStore: UserStore
}

@inject('userStore')
@observer
export default class Login extends Base<ILoginProps> {
    private readonly formRef: RefObject<HTMLFormElement>;
    private readonly usernameRef: RefObject<HTMLInputElement>;
    private readonly passwordRef: RefObject<HTMLInputElement>;

    componentDidMount() {
        document.body.classList.add("bg-default");
    }

    componentWillUnmount() {
        document.body.classList.remove("bg-default");
    }

    constructor(props: ILoginProps) {
        super(props);
        this.formRef = createRef();
        this.usernameRef = createRef();
        this.passwordRef = createRef();
    }

    render() {
        return <div>
            <div className="main-content">
                <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="md">
                    <Container className="px-4">
                        <NavbarBrand to="/" tag={Link}>{Login.renderImg()}</NavbarBrand>
                        <button className="navbar-toggler" id="navbar-collapse-main">
                            <span className="navbar-toggler-icon"/>
                        </button>
                        <UncontrolledCollapse navbar={true} toggler="#navbar-collapse-main">
                            <div className="navbar-collapse-header d-md-none">
                                <Row>
                                    <Col className="collapse-brand" xs="6">
                                        <Link to="/">{Login.renderImg()}</Link>
                                    </Col>
                                    <Col className="collapse-close" xs="6">
                                        <button className="navbar-toggler" id="navbar-collapse-main">
                                            <span/>
                                            <span/>
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </UncontrolledCollapse>
                    </Container>
                </Navbar>
                <div className="header bg-vanilli py-7 py-lg-8">
                    <Container>
                        <div className="header-body text-center mb-7">
                            <Row className="justify-content-center">
                                <Col lg="5" md="6">
                                    <h1 className="text-white">Welcome to online shop!</h1>
                                    <p className="text-lead text-light">Use this form to sign in</p>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <div className="separator separator-bottom separator-skew zindex-100">
                        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1"
                             viewBox="0 0 2560 100" x="0" y="0">
                            <polygon className="fill-default" points="2560 0 2560 100 0 100"/>
                        </svg>
                    </div>
                </div>
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        <Col lg="5" md="7">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-transparent">
                                    <div className="text-muted text-center">
                                        <small>Sign in</small>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Form innerRef={this.formRef} role="form">
                                        <FormGroup className="mb-3">
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-single-02"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input innerRef={this.usernameRef} placeholder="Username" type="text"
                                                       required={true}/>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-lock-circle-open"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input innerRef={this.passwordRef} placeholder="Password"
                                                       type="password" required={true}/>
                                            </InputGroup>
                                        </FormGroup>
                                        <div className="text-center">
                                            <ButtonAction loading={this.props.userStore.loading}>
                                                <Button color="primary" type="submit"
                                                        onClick={async (e) => await this.initLogin(e)}>
                                                    <span className="btn-inner--icon">
                                                        <i className="ni ni-lock-circle-open"/>
                                                    </span>
                                                    <span className="btn-inner--text">Sign in</span>
                                                </Button>
                                            </ButtonAction>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <footer className="py-5">
                <Container>
                    <FooterContent/>
                </Container>
            </footer>
        </div>
    }

    private static renderImg(): JSX.Element {
        return <img alt="Vanilli" src={Banner}/>;
    }

    private async initLogin(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const valid = this.formRef.current?.reportValidity();

        if (valid) {
            await this.signIn();
        }
    }

    private async signIn() {
        const login = {
            username: this.usernameRef.current?.value,
            password: this.passwordRef.current?.value
        }

        const props = this.props;
        const result = await props.userStore.signIn(login);

        if (result) {
            props.history.push(routes.backoffice);
        }
    }
}