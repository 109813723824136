import RootApi from "./root-api";
import Axios, {AxiosResponse} from "axios";
import {ICountryDto, ICountryShippingMethodDto} from "../models/country";

export default class CountryApi extends RootApi {
    private readonly countriesEndpoint = "/countries";
    private readonly shippingEndpoint = "/country/shipping/methods";

    async getCountries(): Promise<AxiosResponse> {
        return Axios.get(this.countriesEndpoint);
    }

    async getCountriesByZone(zoneId: number): Promise<AxiosResponse> {
        return Axios.get(`${this.countriesEndpoint}/zone/${zoneId}`);
    }

    async getShippingMethods(): Promise<AxiosResponse> {
        return Axios.get(this.shippingEndpoint);
    }

    async getCountryById(id: number): Promise<AxiosResponse> {
        return Axios.get(`${this.countriesEndpoint}/${id}`);
    }

    async getShippingMethodById(id: number): Promise<AxiosResponse> {
        return Axios.get(`${this.shippingEndpoint}/${id}`);
    }

    async createCountry(country: ICountryDto): Promise<AxiosResponse> {
        return this.makePostCall(this.countriesEndpoint, country);
    }

    async createShippingMethod(method: ICountryShippingMethodDto): Promise<AxiosResponse> {
        return this.makePostCall(this.shippingEndpoint, method);
    }

    async updateCountry(country: ICountryDto): Promise<AxiosResponse> {
        return Axios.put(`${this.countriesEndpoint}/${country.id}`, country);
    }

    async updateShippingMethod(method: ICountryShippingMethodDto): Promise<AxiosResponse> {
        return Axios.put(`${this.shippingEndpoint}/${method.id}`, method);
    }

    async deleteCountry(id: number): Promise<AxiosResponse> {
        return Axios.delete(`${this.countriesEndpoint}/${id}`);
    }

    async deleteShippingMethod(id: number): Promise<AxiosResponse> {
        return Axios.delete(`${this.shippingEndpoint}/${id}`);
    }
}