import React from "react";
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Form, Row} from "reactstrap";
import {Link} from "react-router-dom";
import ButtonAction from "../base/button/button-action";
import BaseForm from "../base/form/base-form";
import {IBaseProps} from "../base/base";
import BlogStore from "../../stores/blog-store";
import {inject, observer} from "mobx-react";
import ImgUpload from "../base/img/img-upload";
import ImgStore from "../../stores/img-store";
import routes from "../../routes";

interface IBlogPostImgProps extends IBaseProps {
    blogStore: BlogStore,
    imgStore: ImgStore
}

@inject('blogStore', 'imgStore')
@observer
export default class BlogPostImg extends BaseForm<IBlogPostImgProps> {
    render() {
        const props = this.props;
        const saving = props.blogStore.saving;
        const imgStore = props.imgStore;

        return <Card className="bg-secondary shadow">
            <CardHeader>
                <h6 className="text-uppercase ls-1">Create</h6>
                <h2>Image</h2>
            </CardHeader>
            <CardBody>
                <Form innerRef={this.FormRef}>
                    <Row>
                        <ImgUpload loading={saving} imgStore={imgStore}/>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col md="6">
                        <Link className="btn btn-danger btn-icon btn-3 w-100" to="/back-office/blog/posts">
                            <span className="btn-inner--icon">
                                <i className="ni ni-bold-left"/>
                            </span>
                            <span className="btn-inner--text">Back</span>
                        </Link>
                    </Col>
                    <Col md="6">
                        <ButtonAction loading={saving}>
                            <Button className="btn-icon btn-3 w-100" role="link" disabled={!imgStore.img?.croppedFile}
                                    color="success" onClick={async (e) => await this.initPostImgUpdate(e)}>
                                <span className="btn-inner--icon">
                                    <i className="ni ni-check-bold"/>
                                </span>
                                <span className="btn-inner--text">Save</span>
                            </Button>
                        </ButtonAction>
                    </Col>
                </Row>
            </CardFooter>
        </Card>;
    }

    private async initPostImgUpdate(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const valid = this.FormRef.current?.reportValidity();

        if (valid) {
            await this.updateImg();
        }
    }

    private async updateImg() {
        const props = this.props;
        const result = await props.blogStore.updateImg(props.match.params.id);

        if (result) {
            props.history.push(routes.blogPosts);
        }
    }
}