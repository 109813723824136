import RootApi from "./root-api";
import Axios, {AxiosResponse} from "axios";
import {IZoneDto} from "../models/zone";

export default class ZoneApi extends RootApi {
    private readonly endpoint = "/zones";

    async getZones(): Promise<AxiosResponse> {
        return Axios.get(this.endpoint);
    }

    async getZoneById(id: number): Promise<AxiosResponse> {
        return Axios.get(`${this.endpoint}/${id}`);
    }

    async createZone(zone: IZoneDto): Promise<AxiosResponse> {
        return this.makePostCall(this.endpoint, zone);
    }

    async updateZone(zone: IZoneDto): Promise<AxiosResponse> {
        return Axios.put(`${this.endpoint}/${zone.id}`, zone);
    }

    async deleteZone(id: number): Promise<AxiosResponse> {
        return Axios.delete(`${this.endpoint}/${id}`);
    }
}