import React from "react";

export default class ImgUploadUtil {
    static getDefaultStyle(): React.CSSProperties {
        return {
            border: "dashed 3px #eee",
            borderColor: "#eee",
            borderRadius: "5px",
            paddingTop: "30px",
            textAlign: "center" as "center",
            height: 200
        };
    }

    static getDraggingStyle(): React.CSSProperties {
        return {
            borderColor: "green"
        };
    }

    static getPreviewStyle(): React.CSSProperties {
        return {
            minHeight: 200,
            overflow: "hidden"
        };
    }
}