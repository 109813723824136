import Base from "../base";
import {createRef, RefObject} from "react";

export default class BaseForm<T> extends Base<T> {
    protected readonly FormRef: RefObject<HTMLFormElement>;

    constructor(props: any) {
        super(props);
        this.FormRef = createRef();
    }
}