import BaseStore from "./base-store";
import {IZoneDto} from "../models/zone";
import {action, observable} from "mobx";

export default class ZoneStore extends BaseStore {
    @observable
    zones: IZoneDto[] = [];

    @observable
    zone: IZoneDto | undefined;

    @observable
    selectedZoneId: number | undefined;

    async getZones() {
        try {
            this.startLoading();
            const result = await this.rootStore.zoneApi.getZones();
            this.setZones(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async getZoneById(id: number) {
        try {
            this.startLoading();
            const result = await this.rootStore.zoneApi.getZoneById(id);
            this.setZone(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async createZone(zone: IZoneDto): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.zoneApi.createZone(zone);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess(`${zone.name} created`);
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async updateZone(zone: IZoneDto): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.zoneApi.updateZone(zone);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess(`${zone.name} updated`);
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async deleteZone(id: number): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startDeleting();
            const result = await this.rootStore.zoneApi.deleteZone(id);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                this.removeZone(id);
                notificationStore.notifySuccess("Zone deleted");
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishDeleting();
        }
    }

    async selectZone(id: number) {
        if (id) {
            this.setSelectedZone(id);
            await this.rootStore.countryStore.getCountriesByZone(id);
        } else {
            this.unsetSelectedZone();
        }
    }

    @action
    setSelectedZone(id: number) {
        this.selectedZoneId = id;
    }

    @action
    private setZones(zones: IZoneDto[]) {
        this.zones = zones;
    }

    @action
    private setZone(zone: IZoneDto) {
        this.zone = zone;
    }

    @action
    private unsetSelectedZone() {
        this.selectedZoneId = undefined;
        this.rootStore.countryStore.unsetCountries();
    }

    @action
    private removeZone(id: number) {
        const zone = this.zones.find(x => x.id === id);

        if (zone) {
            const zoneIdx = this.zones.indexOf(zone, 0);
            this.zones.splice(zoneIdx, 1);
        }
    }
}