import BaseStore from "./base-store";
import {IShippingMethodDto, ShippingProviderEnum} from "../models/shipping";
import {action, observable} from "mobx";

export default class ShippingStore extends BaseStore {
    @observable
    methods: IShippingMethodDto[] = [];

    @observable
    method: IShippingMethodDto | undefined;

    @observable
    selectedMethodId: number | undefined;

    @observable
    selectedProvider: string | undefined;
    providers: ShippingProviderEnum[] = Object.values(ShippingProviderEnum);

    async getMethods() {
        try {
            this.startLoading();
            const result = await this.rootStore.shippingApi.getMethods();
            this.setMethods(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async getMethodById(id: number) {
        try {
            this.startLoading();
            const result = await this.rootStore.shippingApi.getMethodById(id);
            this.setMethod(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async createMethod(method: IShippingMethodDto): Promise<boolean> {
        method.provider = this.selectedProvider;
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.shippingApi.createMethod(method);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess(`${method.name} created`);
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async updateMethod(method: IShippingMethodDto): Promise<boolean> {
        method.provider = this.selectedProvider;
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.shippingApi.updateMethod(method);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess(`${method.name} updated`);
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async deleteMethod(id: number): Promise<boolean> {
        const notificationStore = this.notificationStore;
        try {
            this.startDeleting();
            const result = await this.rootStore.shippingApi.deleteMethod(id);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                this.removeMethod(id);
                notificationStore.notifySuccess("Method deleted");
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishDeleting();
        }
    }

    @action
    selectMethodId(id: number) {
        this.selectedMethodId = id;
    }

    @action
    selectProvider(provider: string) {
        this.selectedProvider = provider;
    }

    @action
    private setMethods(methods: IShippingMethodDto[]) {
        this.methods = methods;
    }

    @action
    private setMethod(method: IShippingMethodDto) {
        this.selectedProvider = method.provider;
        this.method = method;
    }

    @action
    private removeMethod(id: number) {
        const method = this.methods.find(x => x.id === id);

        if (method) {
            const methodIdx = this.methods.indexOf(method, 0);
            this.methods.splice(methodIdx, 1);
        }
    }
}