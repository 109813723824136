import CountryShippingMethodForm from "./country-shipping-method-form";
import {inject, observer} from "mobx-react";
import {
    Button,
    Card,
    CardBody, CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup, Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Label,
    Row
} from "reactstrap";
import React from "react";
import {Link} from "react-router-dom";
import ButtonAction from "../base/button/button-action";
import {ICountryShippingMethodDto} from "../../models/country";
import routes from "../../routes";

@inject('categoryStore', 'countryStore', 'shippingStore', 'zoneStore')
@observer
export default class CountryShippingMethodCreate extends CountryShippingMethodForm {
    componentDidMount() {
        super.componentDidMount();
    }

    render() {
        const props = this.props;
        const categoryStore = props.categoryStore;
        const countryStore = props.countryStore;
        const shippingStore = props.shippingStore;
        const zoneStore = props.zoneStore;

        return <Card className="bg-secondary shadow">
            <CardHeader>
                <h6 className="text-uppercase ls-1">Create</h6>
                <h2>Shipping setting</h2>
            </CardHeader>
            <CardBody>
                <Form innerRef={this.FormRef}>
                    <Row>
                        <Col lg="6">
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Zone</InputGroupText>
                                    </InputGroupAddon>
                                    <select className="custom-select form-control-alternative" required={true}
                                            disabled={zoneStore.loading}
                                            onChange={async (e) => await this.selectZone(e)}>
                                        <option value="">Choose...</option>
                                        {zoneStore.zones.map((zone) => this.renderZone(zone))}
                                    </select>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Country</InputGroupText>
                                    </InputGroupAddon>
                                    <select className="custom-select form-control-alternative"
                                            disabled={countryStore.loadingCountries}
                                            onChange={(e) => this.selectCountry(e)}>
                                        <option value="">Choose...</option>
                                        {countryStore.countries.map((country) => this.renderCountry(country))}
                                    </select>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Method</InputGroupText>
                                    </InputGroupAddon>
                                    <select className="custom-select form-control-alternative"
                                            disabled={shippingStore.loading} onChange={(e) => this.selectMethod(e)}
                                            required={true}>
                                        <option value="">Choose...</option>
                                        {shippingStore.methods.map((method) => this.renderMethod(method))}
                                    </select>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Category</InputGroupText>
                                    </InputGroupAddon>
                                    <select className="custom-select form-control-alternative"
                                            disabled={categoryStore.loading} onChange={(e) => this.selectCategory(e)}>
                                        <option value="">Choose...</option>
                                        {categoryStore.categories.map((category) => this.renderCategory(category))}
                                    </select>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="3" md="6">
                            <FormGroup>
                                <Label className="form-control-label">Fee</Label>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-money-coins"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input innerRef={this.FeeRef} type="number" step=".01"
                                           className="form-control-alternative" placeholder="Fee"/>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col lg="3" md="6">
                            <FormGroup>
                                <Label className="form-control-label">Estimation in days</Label>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-calendar-grid-58"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input innerRef={this.EstimationInDaysRef} type="number" required={true}
                                           className="form-control-alternative" placeholder="Estimation in days"/>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                    <Input innerRef={this.ActiveRef} id="active" type="checkbox" defaultChecked={true}
                                           className="custom-control-input"/>
                                    <Label className="custom-control-label" htmlFor="active">
                                        <span>Active</span>
                                    </Label>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col md="6">
                        <Link className="btn btn-danger btn-icon btn-3 w-100" to={routes.countryShippings}>
                            <span className="btn-inner--icon">
                                <i className="ni ni-bold-left"/>
                            </span>
                            <span className="btn-inner--text">Back</span>
                        </Link>
                    </Col>
                    <Col md="6">
                        <ButtonAction loading={countryStore.saving}>
                            <Button className="btn-icon btn-3 w-100" role="link" color="success"
                                    onClick={async (e) => await this.initShippingCreate(e)}>
                                <span className="btn-inner--icon">
                                    <i className="ni ni-check-bold"/>
                                </span>
                                <span className="btn-inner--text">Save</span>
                            </Button>
                        </ButtonAction>
                    </Col>
                </Row>
            </CardFooter>
        </Card>;
    }

    private async initShippingCreate(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const valid = this.FormRef.current?.reportValidity();

        if (valid) {
            await this.createShipping();
        }
    }

    private async createShipping() {
        const shipping: ICountryShippingMethodDto = {
            id: 0,
            fee: Number(this.FeeRef.current?.value),
            estimationInDays: Number(this.EstimationInDaysRef.current?.value),
            active: this.ActiveRef.current?.checked ?? true
        }

        const props = this.props;
        const result = await props.countryStore.createShippingMethod(shipping);

        if (result) {
            props.history.push(routes.countryShippings);
        }
    }
}