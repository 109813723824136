import Base from "../base";
import {IDeletingModal} from "../../../models/base";
import ButtonRow from "../button/button-row";
import LinkEdit from "../link/link-edit";
import ButtonDelete from "../button/button-delete";
import ButtonDropdown from "../button/button-dropdown";
import {DropdownItem} from "reactstrap";
import {NavLink} from "react-router-dom";
import React from "react";

interface IBaseListState {
    reordering: boolean,
    deletingModal: IDeletingModal
}

export default class BaseList<T> extends Base<T, IBaseListState> {
    constructor(props: T) {
        super(props);

        this.state = {
            reordering: false,
            deletingModal: {
                id: undefined,
                open: false
            }
        };
    }

    protected renderDefaultBtnRow(entityId: number, rootPath: string): JSX.Element {
        return <>
            <ButtonRow>
                <LinkEdit path={`${rootPath}/${entityId}`}/>
                <ButtonDelete onToggle={() => this.toggleDeletingModal(entityId)}/>
            </ButtonRow>
            <ButtonDropdown>
                <DropdownItem to={`${rootPath}/${entityId}`} tag={NavLink}>
                    <i className="ni ni-settings-gear-65"/>
                    Edit
                </DropdownItem>
                <DropdownItem onClick={() => this.toggleDeletingModal(entityId)}>
                    <i className="ni ni-fat-remove"/>
                    Delete
                </DropdownItem>
            </ButtonDropdown>
        </>;
    }

    protected toggleDeletingModal(id: number) {
        this.setState({
            deletingModal: {
                id: id,
                open: !this.state.deletingModal.open
            }
        });
    }
}