import React from 'react';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import Layout from "./components/layout/layout";
import {ToastContainer} from "react-toastify";
import ScrollToTop from "./components/scroll-to-top";

class App extends React.Component {
    render() {
        return <BrowserRouter>
            <ScrollToTop/>
            <ToastContainer/>
            <Switch>
                <Route component={Layout}/>
            </Switch>
        </BrowserRouter>;
    }
}

export default App;