import React from "react";
import {DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";

interface IButtonDropdownProps {
    hidden?: string
}

export default class ButtonDropdown extends React.Component<IButtonDropdownProps> {
    render() {
        const hiddenClass = this.renderHiddenClass();

        return <UncontrolledDropdown className={`d-table-cell ${hiddenClass}`}>
            <DropdownToggle className="btn-icon-only text-light" href="#" role="button" size="sm" color=""
                            onClick={(e) => e.preventDefault()}>
                <i className="fas fa-pencil-alt"/>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right={true}>{this.props.children}</DropdownMenu>
        </UncontrolledDropdown>;
    }

    private renderHiddenClass(): string {
        const hide = this.props.hidden || "lg";
        return `d-${hide}-none`;
    }
}