import RootApi from "./root-api";
import Axios, {AxiosResponse} from "axios";
import {IOrderUpdateDto} from "../models/order";
import {IQueryParams} from "../models/base";

export default class OrderApi extends RootApi {
    private readonly endpoint = "/orders";

    async searchOrders(queryParams?: IQueryParams): Promise<AxiosResponse> {
        return this.makePostCall(`${this.endpoint}/search`, {}, queryParams);
    }

    async getOrderById(id: number): Promise<AxiosResponse> {
        return Axios.get(`${this.endpoint}/${id}`);
    }

    async updateOrder(request: IOrderUpdateDto): Promise<AxiosResponse> {
        return Axios.put(`${this.endpoint}/${request.orderId}`, request);
    }

    async deleteOrder(id: number): Promise<AxiosResponse> {
        return Axios.delete(`${this.endpoint}/${id}`);
    }
}