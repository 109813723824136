import CountryForm from "./country-form";
import {inject, observer} from "mobx-react";
import LoaderDetails from "../base/loader/loader-details";
import React from "react";
import {
    Button, Card,
    CardBody, CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row, UncontrolledTooltip
} from "reactstrap";
import {Link} from "react-router-dom";
import ButtonAction from "../base/button/button-action";
import {ICountryDto} from "../../models/country";
import routes from "../../routes";

@inject('countryStore', 'zoneStore')
@observer
export default class CountryEdit extends CountryForm {
    componentDidMount() {
        super.componentDidMount();
        const props = this.props;
        props.countryStore.getCountryById(props.match.params.id);
    }

    render() {
        return !this.props.countryStore.loading ? this.renderCountry() : <LoaderDetails/>;
    }

    private renderCountry(): JSX.Element {
        const props = this.props;
        const countryStore = props.countryStore;
        const zoneStore = props.zoneStore;
        const country = countryStore.country;
        const name = country?.name;

        return <Card className="bg-secondary shadow">
            <CardHeader>
                <h6 className="text-uppercase ls-1">Country</h6>
                <h2>{name}</h2>
            </CardHeader>
            <CardBody>
                <Form innerRef={this.FormRef}>
                    <Row>
                        <Col xl="7" md="12">
                            <FormGroup>
                                <Label className="form-control-label">Name</Label>
                                <Input innerRef={this.NameRef} type="text" required={true}
                                       className="form-control-alternative" placeholder="Name" defaultValue={name}/>
                            </FormGroup>
                        </Col>
                        <Col xl="2" md="6">
                            <FormGroup>
                                <Label className="form-control-label">Code</Label>
                                <span className="pl-2">
                                    <i className="fa fa-question-circle" data-placement="top" id="tooltip-code"/>
                                    <UncontrolledTooltip delay={0} placement="top" target="tooltip-code">
                                        <p className="mb-0">It is used for getting country's specific parcel machines, where possible</p>
                                        <hr className="my-1 border-white"/>
                                        <ul>
                                            <li>Estonia = "EE"</li>
                                            <li>Finland = "FI"</li>
                                        </ul>
                                    </UncontrolledTooltip>
                                </span>
                                <Input innerRef={this.CodeRef} type="text" className="form-control-alternative"
                                       placeholder="Code" defaultValue={country?.code}/>
                            </FormGroup>
                        </Col>
                        <Col xl="3" md="6">
                            <FormGroup>
                                <Label className="form-control-label">Free shipping threshold</Label>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-money-coins"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input innerRef={this.FreeShippingThresholdRef} type="number" step=".01"
                                           className="form-control-alternative" placeholder="Free shipping threshold"
                                           defaultValue={country?.freeShippingThreshold}/>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Zone</InputGroupText>
                                    </InputGroupAddon>
                                    <select className="custom-select form-control-alternative"
                                            disabled={zoneStore.loading} defaultValue={country?.zoneId}
                                            onChange={(e) => this.selectZone(e)} required={true}>
                                        <option value="">Choose...</option>
                                        {zoneStore.zones.map((zone) => this.renderZone(zone))}
                                    </select>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                    <Input innerRef={this.ActiveRef} id="active" type="checkbox"
                                           defaultChecked={country?.active} className="custom-control-input"/>
                                    <Label className="custom-control-label" htmlFor="active">
                                        <span>Active</span>
                                    </Label>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col md="6">
                        <Link className="btn btn-danger btn-icon btn-3 w-100" to={routes.countries}>
                            <span className="btn-inner--icon">
                                <i className="ni ni-bold-left"/>
                            </span>
                            <span className="btn-inner--text">Back</span>
                        </Link>
                    </Col>
                    <Col md="6">
                        <ButtonAction loading={countryStore.saving}>
                            <Button className="btn-icon btn-3 w-100" role="link" color="success"
                                    onClick={async (e) => await this.initCountryUpdate(e)}>
                                <span className="btn-inner--icon">
                                    <i className="ni ni-check-bold"/>
                                </span>
                                <span className="btn-inner--text">Save</span>
                            </Button>
                        </ButtonAction>
                    </Col>
                </Row>
            </CardFooter>
        </Card>;
    }

    private async initCountryUpdate(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const valid = this.FormRef.current?.reportValidity();

        if (valid) {
            await this.updateCountry();
        }
    }

    private async updateCountry() {
        const props = this.props;
        const countryStore = props.countryStore;
        const existingCountry = countryStore.country;

        if (existingCountry) {
            const updatedCountry: ICountryDto = {
                id: existingCountry.id,
                name: this.NameRef.current?.value,
                code: this.CodeRef.current?.value,
                active: this.ActiveRef.current?.checked ?? existingCountry.active
            }

            const freeShippingThreshold = this.FreeShippingThresholdRef.current?.value;

            if (freeShippingThreshold) {
                updatedCountry.freeShippingThreshold = Number(freeShippingThreshold);
            }

            const result = await countryStore.updateCountry(updatedCountry);

            if (result) {
                props.history.push(routes.countries);
            }
        }
    }
}