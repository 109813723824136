import BaseStore from "./base-store";
import {ICustomerDetailsDto, ICustomerDto} from "../models/customer";
import {action, observable} from "mobx";
import {IListDto, IQueryParams} from "../models/base";

export default class CustomerStore extends BaseStore {
    @observable
    customerList: IListDto<ICustomerDto> = {
        entities: [],
        count: 0,
        limit: 0
    }

    @observable
    customer: ICustomerDetailsDto | undefined;

    async searchCustomers(queryParams?: IQueryParams) {
        try {
            this.startLoading();
            const result = await this.rootStore.customerApi.searchCustomers(queryParams);
            this.setCustomers(result.data, queryParams);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async getCustomerById(id: number) {
        try {
            this.startLoading();
            const result = await this.rootStore.customerApi.getCustomerById(id);
            this.setCustomer(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    @action
    private setCustomers(customerList: IListDto<ICustomerDto>, queryParams?: IQueryParams) {
        this.customerList = customerList;
        const page = queryParams?.page;

        if (page) {
            this.selectPage(page);
        }
    }

    @action
    private setCustomer(customer: ICustomerDetailsDto) {
        this.customer = customer;
    }
}