import RootApi from "./root-api";
import Axios, {AxiosResponse} from "axios";
import {IBlogPostDetailsDto} from "../models/blog";

export default class BlogApi extends RootApi {
    private readonly endpoint = "/blog/posts";

    async getPosts(): Promise<AxiosResponse> {
        return Axios.get(this.endpoint);
    }

    async getPostById(id: number): Promise<AxiosResponse> {
        return Axios.get(`${this.endpoint}/${id}`);
    }

    async createPost(post: IBlogPostDetailsDto): Promise<AxiosResponse> {
        return this.makePostCall(this.endpoint, post);
    }

    async updatePost(post: IBlogPostDetailsDto): Promise<AxiosResponse> {
        return Axios.put(`${this.endpoint}/${post.id}`, post);
    }

    async updateImg(file: Blob, blogId: number): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append("File", file);

        const cfg = {
            headers: {
                "Content-type": "multipart/form-data"
            }
        };

        return Axios.put(`${this.endpoint}/${blogId}/image`, formData, cfg)
    }

    async deletePost(id: number): Promise<AxiosResponse> {
        return Axios.delete(`${this.endpoint}/${id}`);
    }
}