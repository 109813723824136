export interface IBaseDto {
    id: number
}

export interface ISimpleDto extends IBaseDto {
    active: boolean
}

export interface IDeletingModal {
    id: number | undefined,
    open: boolean
}

export interface IListDto<T> {
    entities: T[],
    count: number,
    limit: number
}

export interface IPropertyDto extends IBaseDto {
    key: string | undefined,
    value: string | undefined,
    type: string | undefined
}

export interface IProperty {
    id: number,
    idx: string,
    key: string | undefined,
    value: string | undefined,
    type: string | undefined
}

export interface IQueryParams {
    page?: number
}

export enum PropertyTypeEnum {
    Bool = "bool",
    String = "string"
}