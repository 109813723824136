import {IBaseProps} from "../base/base";
import CompanyStore from "../../stores/company-store";
import {inject, observer} from "mobx-react";
import BaseList from "../base/list/base-list";
import {Card, CardHeader, Table} from "reactstrap";
import React from "react";
import LoaderList from "../base/loader/loader-list";
import {ICompanyProviderDto} from "../../models/company";
import BadgeBoolean from "../base/badge/badge-boolean";
import ModalDelete from "../base/modal-delete";
import LinkCreate from "../base/link/link-create";
import routes from "../../routes";

interface ICompanyPaymentProviderListProps extends IBaseProps {
    companyStore: CompanyStore
}

@inject('companyStore')
@observer
export default class CompanyPaymentProviderList extends BaseList<ICompanyPaymentProviderListProps> {
    componentDidMount() {
        this.props.companyStore.getPaymentProviders();
    }

    render() {
        return <Card className="shadow">
            <CardHeader>
                <h2>Payment providers</h2>
                <LinkCreate path={routes.companyPaymentProviders}/>
            </CardHeader>
            {!this.props.companyStore.loading ? this.renderProviders() : <LoaderList/>}
        </Card>;
    }

    private renderProviders(): JSX.Element {
        return <Table className="align-items-center table-flush" responsive={true}>
            <thead className="thead-light">
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Active</th>
                <th scope="col"/>
            </tr>
            </thead>
            <tbody>{this.props.companyStore.paymentProviders.map((provider) => this.renderProvider(provider))}</tbody>
        </Table>;
    }

    private renderProvider(provider: ICompanyProviderDto): JSX.Element {
        const id = provider.id;

        return <tr key={id}>
            <td>{provider.name}</td>
            <td>
                <BadgeBoolean isTrue={provider.active}/>
            </td>
            <td>{this.renderDefaultBtnRow(id, routes.companyPaymentProviders)}</td>
            <ModalDelete id={id} modal={this.state.deletingModal} onToggle={() => this.toggleDeletingModal(id)}
                         onDelete={async () => await this.deleteProvider(id)}
                         deleting={this.props.companyStore.deleting}/>
        </tr>;
    }

    private async deleteProvider(id: number) {
        const result = await this.props.companyStore.deletePaymentProvider(id);

        if (result) {
            this.toggleDeletingModal(id);
        }
    }
}