import * as mobx from "mobx";
import NotificationStore from "./notification-store";
import UserApi from "../api/user-api";
import UserStore from "./user-store";
import CategoryStore from "./category-store";
import CategoryApi from "../api/category-api";
import ProductStore from "./product-store";
import ProductApi from "../api/product-api";
import CountryStore from "./country-store";
import CountryApi from "../api/country-api";
import ShippingApi from "../api/shipping-api";
import ShippingStore from "./shipping-store";
import ParameterApi from "../api/parameter-api";
import ParameterStore from "./parameter-store";
import OrderApi from "../api/order-api";
import OrderStore from "./order-store";
import ImgStore from "./img-store";
import CouponApi from "../api/coupon-api";
import CouponStore from "./coupon-store";
import BlogApi from "../api/blog-api";
import BlogStore from "./blog-store";
import ImgApi from "../api/img-api";
import CustomerApi from "../api/customer-api";
import CustomerStore from "./customer-store";
import CompanyStore from "./company-store";
import CompanyApi from "../api/company-api";
import ZoneApi from "../api/zone-api";
import ZoneStore from "./zone-store";

interface IRootStore {
    blogStore: BlogStore,
    categoryStore: CategoryStore,
    companyStore: CompanyStore,
    countryStore: CountryStore,
    couponStore: CouponStore,
    customerStore: CustomerStore,
    imgStore: ImgStore,
    notificationStore: NotificationStore,
    orderStore: OrderStore,
    parameterStore: ParameterStore,
    productStore: ProductStore,
    shippingStore: ShippingStore,
    userStore: UserStore,
    zoneStore: ZoneStore
}

export default class RootStore implements IRootStore {
    blogStore: BlogStore;
    categoryStore: CategoryStore;
    companyStore: CompanyStore;
    countryStore: CountryStore;
    couponStore: CouponStore;
    customerStore: CustomerStore;
    imgStore: ImgStore;
    notificationStore: NotificationStore;
    orderStore: OrderStore;
    parameterStore: ParameterStore;
    productStore: ProductStore;
    shippingStore: ShippingStore;
    userStore: UserStore;
    zoneStore: ZoneStore;
    blogApi: BlogApi;
    categoryApi: CategoryApi;
    companyApi: CompanyApi;
    countryApi: CountryApi;
    couponApi: CouponApi;
    customerApi: CustomerApi;
    imgApi: ImgApi;
    orderApi: OrderApi;
    parameterApi: ParameterApi;
    productApi: ProductApi;
    shippingApi: ShippingApi;
    userApi: UserApi;
    zoneApi: ZoneApi;

    constructor() {
        mobx.configure({
            enforceActions: "observed"
        });

        this.notificationStore = new NotificationStore(this);
        this.blogStore = new BlogStore(this);
        this.categoryStore = new CategoryStore(this);
        this.companyStore = new CompanyStore(this);
        this.countryStore = new CountryStore(this);
        this.couponStore = new CouponStore(this);
        this.customerStore = new CustomerStore(this);
        this.imgStore = new ImgStore(this);
        this.orderStore = new OrderStore(this);
        this.parameterStore = new ParameterStore(this);
        this.productStore = new ProductStore(this);
        this.shippingStore = new ShippingStore(this);
        this.userStore = new UserStore(this);
        this.zoneStore = new ZoneStore(this);
        this.blogApi = new BlogApi();
        this.categoryApi = new CategoryApi();
        this.companyApi = new CompanyApi();
        this.countryApi = new CountryApi();
        this.couponApi = new CouponApi();
        this.customerApi = new CustomerApi();
        this.imgApi = new ImgApi();
        this.orderApi = new OrderApi();
        this.parameterApi = new ParameterApi();
        this.productApi = new ProductApi();
        this.shippingApi = new ShippingApi();
        this.userApi = new UserApi();
        this.zoneApi = new ZoneApi();
    }
}