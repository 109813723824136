import React from "react";
import {Button, Col, Row} from "reactstrap";

interface IButtonReorderProps {
    reordering: boolean,
    saving: boolean,
    onToggle: () => void,
    onCancel: () => void
}

export default class ButtonReorder extends React.Component<IButtonReorderProps> {
    render() {
        const props = this.props;
        const saving = props.saving;

        if (props.reordering) {
            return <Row className="pt-2">
                <Col md="6">
                    <Button className="btn-icon btn-3 w-100" size="sm" role="link" disabled={saving} color="success" onClick={props.onToggle}>
                    <span className="btn-inner--icon">
                        <i className="ni ni-check-bold"/>
                    </span>
                        <span className="btn-inner--text">Save</span>
                    </Button>
                </Col>
                <Col md="6">
                    <Button className="btn-icon btn-3 w-100" size="sm" color="danger" disabled={saving} onClick={props.onCancel}>
                    <span className="btn-inner--icon">
                        <i className="ni ni-fat-remove"/>
                    </span>
                        <span className="btn-inner--text">Cancel</span>
                    </Button>
                </Col>
            </Row>;
        }

        return <Button size="sm" onClick={props.onToggle}>Re-order</Button>;
    }
}