import RootApi from "./root-api";
import Axios, {AxiosResponse} from "axios";
import {ICouponDto} from "../models/coupon";

export default class CouponApi extends RootApi {
    private readonly endpoint = "/coupons";

    async getCoupons(): Promise<AxiosResponse> {
        return Axios.get(this.endpoint);
    }

    async getCouponById(id: number): Promise<AxiosResponse> {
        return Axios.get(`${this.endpoint}/${id}`);
    }

    async createCoupon(coupon: ICouponDto): Promise<AxiosResponse> {
        return this.makePostCall(this.endpoint, coupon);
    }

    async updateCoupon(coupon: ICouponDto): Promise<AxiosResponse> {
        return Axios.put(`${this.endpoint}/${coupon.id}`, coupon);
    }

    async deleteCoupon(id: number): Promise<AxiosResponse> {
        return Axios.delete(`${this.endpoint}/${id}`);
    }
}