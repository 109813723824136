import {IProductImgDto} from "../../models/product";
import React from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import BadgeBoolean from "../base/badge/badge-boolean";
import BaseListEdit from "../base/list/base-list-edit";

export default class ProductImgListEdit extends BaseListEdit<IProductImgDto> {
    render() {
        return <DragDropContext onDragEnd={(result) => this.onDragEnd(result)}>
            <Droppable droppableId="droppable">{(provided, snapshot) => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}
                       style={this.getListStyle(snapshot.isDraggingOver)}>{this.props.entities.map((img: IProductImgDto, idx: number) => this.renderImg(img, idx))}{provided.placeholder}</tbody>
            )}
            </Droppable>
        </DragDropContext>;
    }

    private renderImg(img: IProductImgDto, idx: number): JSX.Element {
        const id = img.id;

        return <Draggable key={id} draggableId={id.toString()} index={idx}>{(provided, snapshot) => (
            <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                <td className="w-25">
                    <img className="img-fluid" src={img.path} alt={img.path}/>
                </td>
                <td>
                    <BadgeBoolean isTrue={img.primary}/>
                </td>
                <td>
                    <BadgeBoolean isTrue={img.active}/>
                </td>
                <td/>
            </tr>
        )}
        </Draggable>;
    }
}