import {IBaseProps} from "../base/base";
import ZoneStore from "../../stores/zone-store";
import SimpleForm from "../base/form/simple-form";
import {createRef, RefObject} from "react";

interface IZoneFormProps extends IBaseProps {
    zoneStore: ZoneStore
}

export default class ZoneForm extends SimpleForm<IZoneFormProps> {
    protected readonly NameRef: RefObject<HTMLInputElement>;

    constructor(props: IZoneFormProps) {
        super(props);
        this.NameRef = createRef();
    }
}