import {inject, observer} from "mobx-react";
import React from "react";
import {
    Button,
    Card,
    CardBody, CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input, InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row, Spinner, UncontrolledTooltip
} from "reactstrap";
import {Link} from "react-router-dom";
import ButtonAction from "../base/button/button-action";
import {IProductDetails} from "../../models/product";
import ProductForm from "./product-form";
import TextEditor from "../base/text-editor";
import routes from "../../routes";

@inject('categoryStore', 'productStore')
@observer
export default class ProductCreate extends ProductForm {
    componentDidMount() {
        super.componentDidMount();
        this.props.productStore.unsetDescription();
    }

    constructor(props: any) {
        super(props);
        this.props.productStore.initProduct();
    }

    render() {
        const props = this.props;
        const productStore = props.productStore;
        const categoryStore = props.categoryStore;

        return <Card className="bg-secondary shadow">
            <CardHeader>
                <h6 className="text-uppercase ls-1">Create</h6>
                <h2>Product</h2>
            </CardHeader>
            <CardBody>
                <Form innerRef={this.FormRef}>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label className="form-control-label">Name</Label>
                                <Input innerRef={this.NameRef} type="text" required={true}
                                       className="form-control-alternative" placeholder="Name"/>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label className="form-control-label">Code</Label>
                                <Input innerRef={this.CodeRef} type="text" className="form-control-alternative"
                                       placeholder="Code"/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3">
                            <FormGroup>
                                <Label className="form-control-label">Price</Label>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-money-coins"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input innerRef={this.PriceRef} type="number" step=".01" required={true}
                                           className="form-control-alternative" placeholder="Price"/>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                                <Label className="form-control-label">Discount price</Label>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-money-coins"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input innerRef={this.DiscountPriceRef} type="number" step=".01"
                                           className="form-control-alternative" placeholder="Discount price"/>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="form-control-label">Description</Label>
                                <TextEditor value={productStore.description}
                                            onChange={(description) => productStore.updateDescription(description)}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Category</InputGroupText>
                                    </InputGroupAddon>
                                    <select className="custom-select form-control-alternative"
                                            disabled={categoryStore.loading} onChange={(e) => this.selectCategory(e)}
                                            required={true}>
                                        <option value="">Choose...</option>
                                        {categoryStore.categories.map((category) => this.renderCategory(category))}
                                    </select>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                    <Input innerRef={this.ActiveRef} id="active" type="checkbox" defaultChecked={true}
                                           className="custom-control-input"/>
                                    <Label className="custom-control-label" htmlFor="active">
                                        <span>Active</span>
                                    </Label>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    {!productStore.loadingProps ? this.renderAdditionalPropsResult() : <div className="text-center">
                        <Spinner/>
                    </div>}
                    <hr/>
                    <Row>
                        <Col>
                            <h3>Stock
                                <span className="pl-2">
                                    <i className="fa fa-question-circle" data-placement="top" id="tooltip-code"/>
                                    <UncontrolledTooltip delay={0} placement="top" target="tooltip-code">
                                        <p>If stock is parameter specific, then leave this general stock field empty</p>
                                    </UncontrolledTooltip>
                            </span>
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-box-2"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input innerRef={this.StockRef} type="number" className="form-control-alternative"
                                           placeholder="Stock"/>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col md="6">
                        <Link className="btn btn-danger btn-icon btn-3 w-100" to={routes.products}>
                            <span className="btn-inner--icon">
                                <i className="ni ni-bold-left"/>
                            </span>
                            <span className="btn-inner--text">Back</span>
                        </Link>
                    </Col>
                    <Col md="6">
                        <ButtonAction loading={productStore.saving}>
                            <Button className="btn-icon btn-3 w-100" role="link" color="success"
                                    onClick={async (e) => await this.initProductCreate(e)}>
                                <span className="btn-inner--icon">
                                    <i className="ni ni-check-bold"/>
                                </span>
                                <span className="btn-inner--text">Save</span>
                            </Button>
                        </ButtonAction>
                    </Col>
                </Row>
            </CardFooter>
        </Card>;
    }

    private renderAdditionalPropsResult(): JSX.Element {
        return this.props.productStore.productProps.length > 0 ? this.renderAdditionalProps() : <div/>;
    }

    private async initProductCreate(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const valid = this.FormRef.current?.reportValidity();

        if (valid) {
            await this.createProduct();
        }
    }

    private async createProduct() {
        const product: IProductDetails = {
            id: 0,
            code: this.CodeRef.current?.value,
            name: this.NameRef.current?.value,
            price: Number(this.PriceRef.current?.value),
            active: this.ActiveRef.current?.checked ?? true
        }

        const discountPrice = this.DiscountPriceRef.current?.value;

        if (discountPrice) {
            product.discountPrice = Number(discountPrice);
        }

        const stock = this.StockRef.current?.value;

        if (stock) {
            product.stock = Number(stock);
        }

        const props = this.props;
        const result = await props.productStore.createProduct(product);

        if (result) {
            props.history.push(routes.products);
        }
    }
}