import {IBaseProps} from "../base/base";
import CompanyStore from "../../stores/company-store";
import BaseList from "../base/list/base-list";
import {inject, observer} from "mobx-react";
import {Card, CardHeader, Table} from "reactstrap";
import React from "react";
import LinkCreate from "../base/link/link-create";
import routes from "../../routes";
import LoaderList from "../base/loader/loader-list";
import {ICompanyEmailAccountDto} from "../../models/company";
import BadgeBoolean from "../base/badge/badge-boolean";
import ModalDelete from "../base/modal-delete";
import {EmailTypeEnum} from "../../models/email";

interface ICompanyEmailListProps extends IBaseProps {
    companyStore: CompanyStore
}

@inject('companyStore')
@observer
export default class CompanyEmailList extends BaseList<ICompanyEmailListProps> {
    componentDidMount() {
        this.props.companyStore.getEmailAccounts();
    }

    render() {
        return <Card className="shadow">
            <CardHeader>
                <h2>E-mail accounts</h2>
                <LinkCreate path={routes.companyEmails}/>
            </CardHeader>
            {!this.props.companyStore.loading ? this.renderEmails() : <LoaderList/>}
        </Card>;
    }

    private renderEmails(): JSX.Element {
        return <Table className="align-items-center table-flush" responsive={true}>
            <thead className="thead-light">
            <tr>
                <th scope="col">Type</th>
                <th scope="col">Address</th>
                <th scope="col">Active</th>
                <th scope="col"/>
            </tr>
            </thead>
            <tbody>{this.props.companyStore.emailAccounts.map((email) => this.renderEmail(email))}</tbody>
        </Table>;
    }

    private renderEmail(email: ICompanyEmailAccountDto): JSX.Element {
        const id = email.id;
        const type = CompanyEmailList.renderEmailType(email);

        return <tr key={id}>
            <td>{type}</td>
            <td>{email.address}</td>
            <td>
                <BadgeBoolean isTrue={email.active}/>
            </td>
            <td>{this.renderDefaultBtnRow(id, routes.companyEmails)}</td>
            <ModalDelete id={id} modal={this.state.deletingModal} onToggle={() => this.toggleDeletingModal(id)}
                         onDelete={async () => await this.deleteEmail(id)} deleting={this.props.companyStore.deleting}/>
        </tr>;
    }

    private static renderEmailType(email: ICompanyEmailAccountDto): string {
        const type = email.type;

        if (type === EmailTypeEnum.InvoiceBcc) {
            return "Invoice BCC";
        }

        if (type === EmailTypeEnum.InvoiceFrom) {
            return "Invoice from";
        }

        return "Unknown";
    }

    private async deleteEmail(id: number) {
        const result = await this.props.companyStore.deleteEmailAccount(id);

        if (result) {
            this.toggleDeletingModal(id);
        }
    }
}