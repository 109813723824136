import BaseStore from "./base-store";
import {IOrderDetailsDto, IOrderDto, IOrderUpdateDto} from "../models/order";
import {action, observable} from "mobx";
import {IListDto, IQueryParams} from "../models/base";

export default class OrderStore extends BaseStore {
    @observable
    orderList: IListDto<IOrderDto> = {
        entities: [],
        count: 0,
        limit: 0
    };

    @observable
    order: IOrderDetailsDto | undefined;

    async searchOrders(queryParams?: IQueryParams) {
        try {
            this.startLoading();
            const result = await this.rootStore.orderApi.searchOrders(queryParams);
            this.setOrders(result.data, queryParams);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async getOrderById(id: number) {
        try {
            this.startLoading();
            const result = await this.rootStore.orderApi.getOrderById(id);
            this.setOrder(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async updateOrder(request: IOrderUpdateDto): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.orderApi.updateOrder(request);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess("Order updated");
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async deleteOrder(id: number): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startDeleting();
            const result = await this.rootStore.orderApi.deleteOrder(id);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                this.removeOrder(id);
                notificationStore.notifySuccess("Order deleted");
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishDeleting();
        }
    }

    @action
    private setOrders(orderList: IListDto<IOrderDto>, queryParams?: IQueryParams) {
        this.orderList = orderList;
        const page = queryParams?.page;

        if (page) {
            this.selectPage(page);
        }
    }

    @action
    private setOrder(order: IOrderDetailsDto) {
        this.order = order;
    }

    @action
    private removeOrder(id: number) {
        const orders = this.orderList.entities;
        const order = orders.find(x => x.id === id);

        if (order) {
            const orderIdx = orders.indexOf(order, 0);
            orders.splice(orderIdx, 1);
        }
    }
}