import React from "react";
import {Button, Modal} from "reactstrap";
import {IDeletingModal} from "../../models/base";
import ButtonAction from "./button/button-action";

interface IModalDeleteProps {
    id: number,
    modal: IDeletingModal
    onToggle: () => void,
    onDelete: () => void,
    deleting: boolean
}

export default class ModalDelete extends React.Component<IModalDeleteProps> {
    render() {
        const props = this.props;
        const modal = props.modal;
        const deleting = props.deleting;

        return <Modal className="modal-dialog-centered modal-danger" backdrop="static"
                      contentClassName="bg-gradient-danger" isOpen={props.id === modal.id && modal.open}
                      toggle={props.onToggle}>
            <div className="modal-header">
                <h6 className="modal-title" id="modal-title-notification">Delete</h6>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={props.onToggle}
                        disabled={deleting}>
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="py-3 text-center">
                    <i className="ni ni-bell-55 ni-3x"/>
                    <h4 className="heading mt-4">Are you sure?</h4>
                    <p>Are you sure you want to delete this?</p>
                </div>
            </div>
            <div className="modal-footer">
                <ButtonAction loading={deleting} loadingAlign="left">
                    <Button className="btn-white" color="default" type="button" onClick={props.onDelete}>Yes</Button>
                </ButtonAction>
                <Button className="text-white ml-auto" color="link" data-dismiss="modal" type="button"
                        onClick={props.onToggle} disabled={deleting}>No</Button>
            </div>
        </Modal>;
    }
}