import {IBaseProps} from "../base/base";
import ShippingStore from "../../stores/shipping-store";
import SimpleForm from "../base/form/simple-form";
import React, {createRef, RefObject} from "react";
import {ShippingProviderEnum} from "../../models/shipping";

interface IShippingMethodFormProps extends IBaseProps {
    shippingStore: ShippingStore
}

export default class ShippingMethodForm extends SimpleForm<IShippingMethodFormProps> {
    protected readonly NameRef: RefObject<HTMLInputElement>;
    protected readonly AddressRequiredRef: RefObject<HTMLInputElement>;

    constructor(props: IShippingMethodFormProps) {
        super(props);
        this.NameRef = createRef();
        this.AddressRequiredRef = createRef();
    }

    protected renderProvider(provider: ShippingProviderEnum): JSX.Element {
        return <option key={provider} value={provider}>{provider}</option>;
    }
}