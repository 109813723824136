import RootApi from "./root-api";
import {ILoginDto} from "../models/user";
import Axios, {AxiosResponse} from "axios";

export default class UserApi extends RootApi {
    private readonly endpoint = "/users";

    async getCurrentUser(): Promise<AxiosResponse> {
        return Axios.get(`${this.endpoint}/current`);
    }

    async signIn(login: ILoginDto): Promise<AxiosResponse> {
        return this.makePostCall(`${this.endpoint}/login`, login);
    }
}