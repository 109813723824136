import React, {createRef, RefObject} from "react";
import Base, {IBaseProps} from "../base/base";
import CategoryStore from "../../stores/category-store";
import {inject, observer} from "mobx-react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form, FormGroup, Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Label,
    Row
} from "reactstrap";
import {Link} from "react-router-dom";
import ButtonAction from "../base/button/button-action";
import {ICategoryParameterValue} from "../../models/category";
import ParameterStore from "../../stores/parameter-store";
import {IParameter, IParameterValue} from "../../models/parameter";
import LoaderDetails from "../base/loader/loader-details";
import routes from "../../routes";

interface ICategoryParameterValueListProps extends IBaseProps {
    categoryStore: CategoryStore,
    parameterStore: ParameterStore
}

@inject('categoryStore', 'parameterStore')
@observer
export default class CategoryParameterValueList extends Base<ICategoryParameterValueListProps> {
    private readonly formRef: RefObject<HTMLFormElement>;

    componentDidMount() {
        const props = this.props;
        props.parameterStore.getParameters();
        props.categoryStore.getParameterValues(props.match.params.id);
    }

    constructor(props: ICategoryParameterValueListProps) {
        super(props);
        this.formRef = createRef();
    }

    render() {
        const props = this.props;
        const loading = props.parameterStore.loading || props.categoryStore.loading;
        return !loading ? this.renderParameterValues() : <LoaderDetails/>;
    }

    private renderParameterValues(): JSX.Element {
        const categoryStore = this.props.categoryStore;

        return <Card className="shadow">
            <CardHeader>
                <h2>Category parameter values</h2>
            </CardHeader>
            <CardBody>
                <Form
                    innerRef={this.formRef}>{categoryStore.parameterValues.map((parameterValue) => this.renderParameterValue(parameterValue))}</Form>
                <Row>
                    <Col>
                        <Button className="btn-icon btn-3" role="link" color="primary" size="sm"
                                onClick={() => categoryStore.addParameterValue()}>
                            <span className="btn-inner--icon">
                                <i className="ni ni-fat-add"/>
                            </span>
                            <span className="btn-inner--text">Add</span>
                        </Button>
                    </Col>
                </Row>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col md="6">
                        <Link className="btn btn-danger btn-icon btn-3 w-100" to={routes.categories}>
                            <span className="btn-inner--icon">
                                <i className="ni ni-bold-left"/>
                            </span>
                            <span className="btn-inner--text">Back</span>
                        </Link>
                    </Col>
                    <Col md="6">
                        <ButtonAction loading={categoryStore.saving}>
                            <Button className="btn-icon btn-3 w-100" role="link" color="success"
                                    onClick={async (e) => await this.initParameterValuesUpdate(e)}>
                                <span className="btn-inner--icon">
                                    <i className="ni ni-check-bold"/>
                                </span>
                                <span className="btn-inner--text">Save</span>
                            </Button>
                        </ButtonAction>
                    </Col>
                </Row>
            </CardFooter>
        </Card>;
    }

    private renderParameterValue(parameterValue: ICategoryParameterValue): JSX.Element {
        const idx = parameterValue.idx;
        const props = this.props;
        const categoryStore = props.categoryStore;
        const parameterStore = props.parameterStore;
        const checkboxId = `${idx}-active`;

        return <Row key={idx}>
            <Col md="6">
                <FormGroup>
                    <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>Parameter value</InputGroupText>
                        </InputGroupAddon>
                        <select className="custom-select form-control-alternative" disabled={parameterStore.loading}
                                onChange={(e) => this.selectParameterValue(e, idx)} required={true}
                                defaultValue={parameterValue.parameterValueId}>
                            <option value="">Choose...</option>
                            {parameterStore.parameters.map((parameter) => this.renderValue(parameter))}
                        </select>
                    </InputGroup>
                </FormGroup>
            </Col>
            <Col md="3">
                <FormGroup>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                        <Input id={checkboxId} type="checkbox" defaultChecked={parameterValue.active}
                               className="custom-control-input"
                               onChange={(e) => categoryStore.setParameterValueActive(e.target.checked, idx)}/>
                        <Label className="custom-control-label" htmlFor={checkboxId}>
                            <span>Active</span>
                        </Label>
                    </div>
                </FormGroup>
            </Col>
            <Col md="3">
                <Button className="btn-icon btn-3" role="link" color="warning"
                        onClick={() => categoryStore.removeParameterValue(idx)}>
                    <span className="btn-inner--icon">
                        <i className="ni ni-fat-remove"/>
                    </span>
                    <span className="btn-inner--text">Remove</span>
                </Button>
            </Col>
        </Row>;
    }

    private selectParameterValue(e: React.ChangeEvent<HTMLSelectElement>, idx: string) {
        const parameterValueId = Number(e.target.value);
        this.props.categoryStore.selectParameterValue(parameterValueId, idx);
    }

    private renderValue(parameter: IParameter): JSX.Element[] {
        return parameter.parameterValues?.map((value) => CategoryParameterValueList.renderValueOption(value, parameter.name)) ?? [];
    }

    private static renderValueOption(parameterValue: IParameterValue, parameterName?: string): JSX.Element {
        const id = parameterValue.id;
        return <option key={id} value={id}>{`${parameterName}: ${parameterValue.value}`}</option>;
    }

    private async initParameterValuesUpdate(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const valid = this.formRef.current?.reportValidity();

        if (valid) {
            await this.updateParameterValues();
        }
    }

    private async updateParameterValues() {
        const props = this.props;
        const result = await props.categoryStore.updateParameterValues(props.match.params.id);

        if (result) {
            props.history.push(routes.categories);
        }
    }
}