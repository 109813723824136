import React, {createRef, RefObject} from "react";
import {Cropper} from "react-cropper";
import "cropperjs/dist/cropper.css";
import {Button, Col, Row} from "reactstrap";
import ButtonAction from "../button/button-action";

interface IImgCropProps {
    imgPreviewSrc: string,
    onConfirm: (cropper: Cropper) => void,
    loading: boolean,
    savingCrop: boolean,
    cropping?: boolean
}

export default class ImgCrop extends React.Component<IImgCropProps> {
    private readonly cropperRef: RefObject<HTMLImageElement>;

    constructor(props: IImgCropProps) {
        super(props);
        this.cropperRef = createRef();
    }

    render() {
        const props = this.props;

        return <>
            <Row>
                <Col>
                    <Cropper ref={this.cropperRef} src={props.imgPreviewSrc} disabled={props.loading}
                             style={{height: 200, width: "100%"}} initialAspectRatio={1} aspectRatio={1}
                             preview=".img-preview" guides={false} autoCropArea={1} background={false}/>
                </Col>
            </Row>
            {props.cropping && <Row className="pt-2 text-center">
                <Col>
                    <ButtonAction loading={props.savingCrop}>
                        <Button className="btn-icon btn-3" color="default" type="button" onClick={() => this.cropImg()}>
                            <span className="btn-inner--icon">
                                <i className="ni ni-check-bold"/>
                            </span>
                            <span className="btn-inner--text">Finish cropping</span>
                        </Button>
                    </ButtonAction>
                </Col>
            </Row>}
        </>;
    }

    private cropImg() {
        const cropField = this.cropperRef.current as any;
        const cropper = cropField.cropper as any;
        this.props.onConfirm(cropper);
    }
}