import React from "react";
import {Editor} from "@tinymce/tinymce-react";
import {inject, observer} from "mobx-react";
import Base, {IBaseProps} from "./base";
import ImgStore from "../../stores/img-store";

interface ITextEditorProps extends IBaseProps {
    value: string,
    defaultValue?: string,
    onChange: (value: string) => void,
    imgStore?: ImgStore
}

@inject('imgStore')
@observer
export default class TextEditor extends Base<ITextEditorProps> {
    render() {
        const props = this.props;

        return <Editor apiKey={process.env.REACT_APP_TINY_MCE_KEY} value={props.value} initialValue={props.defaultValue}
                       onEditorChange={(value) => props.onChange(value)}
                       init={{
                           height: 250,
                           toolbar_mode: "sliding",
                           images_upload_handler: async (img, onSuccess, onFailure) => await this.uploadImg(img.blob(), onSuccess, onFailure),
                           image_class_list: [
                               {title: "None", value: ""},
                               {title: "Responsive", value: "img-fluid"}
                           ],
                           contextmenu: "link image imagetools table",
                           menubar: "file edit view insert format tools table help",
                           plugins: "print preview paste importcss searchreplace autolink directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                           toolbar: "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview print | insertfile image media template link anchor codesample | ltr rtl"
                       }}/>;
    }

    private async uploadImg(blob: Blob, onSuccess: (url: string) => void, onFailure: (err: string, options?: any) => void) {
        try {
            const result = await this.props.imgStore?.uploadImg(blob);
            const data = result.data;

            if (data.valid) {
                onSuccess(data.result.imgPath);
            } else {
                onFailure(data.errors);
            }
        } catch (e: any) {
            onFailure(e);
        }
    }
}