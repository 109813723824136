import {IBaseProps} from "../base/base";
import CompanyStore from "../../stores/company-store";
import SimpleForm from "../base/form/simple-form";
import React, {createRef, RefObject} from "react";
import {EmailTypeEnum} from "../../models/email";

interface ICompanyEmailFormProps extends IBaseProps {
    companyStore: CompanyStore
}

export default class CompanyEmailForm extends SimpleForm<ICompanyEmailFormProps> {
    protected readonly AddressRef: RefObject<HTMLInputElement>;
    protected readonly TemplateIdRef: RefObject<HTMLInputElement>;

    constructor(props: ICompanyEmailFormProps) {
        super(props);
        this.AddressRef = createRef();
        this.TemplateIdRef = createRef();
    }

    protected renderType(type: EmailTypeEnum): JSX.Element {
        return <option key={type} value={type}>{CompanyEmailForm.renderTypeName(type)}</option>;
    }

    private static renderTypeName(type: EmailTypeEnum): string {
        if (type === EmailTypeEnum.InvoiceBcc) {
            return "Invoice BCC";
        }

        if (type === EmailTypeEnum.InvoiceFrom) {
            return "Invoice from";
        }

        return type;
    }
}