import React from 'react';
import ReactDOM from 'react-dom';
import 'react-datetime/css/react-datetime.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './assets/css/landing/index.css';
import './assets/plugins/nucleo/css/nucleo.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import './assets/scss/index.scss';
import './assets/css/index-custom.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import RootStore from "./stores/root-store";
import {Provider} from "mobx-react";

const rootStore = new RootStore();

const stores = {
    rootStore: rootStore,
    blogStore: rootStore.blogStore,
    categoryStore: rootStore.categoryStore,
    companyStore: rootStore.companyStore,
    countryStore: rootStore.countryStore,
    couponStore: rootStore.couponStore,
    customerStore: rootStore.customerStore,
    imgStore: rootStore.imgStore,
    orderStore: rootStore.orderStore,
    parameterStore: rootStore.parameterStore,
    productStore: rootStore.productStore,
    shippingStore: rootStore.shippingStore,
    userStore: rootStore.userStore,
    zoneStore: rootStore.zoneStore
};

ReactDOM.render(
    <React.StrictMode>
        <Provider {...stores}>
            <App/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
