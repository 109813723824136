import BaseStore from "./base-store";
import {ILoginDto, IUserDto} from "../models/user";
import {action, observable} from "mobx";
import {LocalStorageEnum} from "../models/local-storage";

export default class UserStore extends BaseStore {
    @observable
    user: IUserDto | undefined;

    @observable
    userLoaded: boolean = false;

    async getCurrentUser() {
        if (!this.user) {
            const notificationStore = this.notificationStore;

            try {
                this.startLoading();
                const result = await this.rootStore.userApi.getCurrentUser();
                const data = result.data;

                if (data.valid) {
                    const user = data.result;

                    if (user) {
                        this.setUser(user);
                    }
                } else {
                    notificationStore.notifyErrors(data.errors);
                }
            } catch (e) {
                notificationStore.notifyError(e);
            } finally {
                this.setUserLoaded();
            }
        }
    }

    async signIn(login: ILoginDto): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startLoading();
            const result = await this.rootStore.userApi.signIn(login);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                this.setUser(data.result);
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishLoading();
        }
    }

    signOut() {
        this.unsetUser()
    }

    @action
    private setUser(user: IUserDto) {
        const sessionToken = user.sessionToken;

        if (sessionToken) {
            localStorage.setItem(LocalStorageEnum.SessionToken, sessionToken);
        }

        this.user = user;
    }

    @action
    private setUserLoaded() {
        this.userLoaded = true;
        this.finishLoading();
    }

    @action
    private unsetUser() {
        localStorage.removeItem(LocalStorageEnum.SessionToken);
        this.user = undefined;
    }
}