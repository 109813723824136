import CompanyEmailForm from "./company-email-form";
import {inject, observer} from "mobx-react";
import LoaderDetails from "../base/loader/loader-details";
import React from "react";
import {
    Button,
    Card,
    CardBody, CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup, Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Label,
    Row, UncontrolledTooltip
} from "reactstrap";
import {Link} from "react-router-dom";
import routes from "../../routes";
import ButtonAction from "../base/button/button-action";

@inject('companyStore')
@observer
export default class CompanyEmailEdit extends CompanyEmailForm {
    componentDidMount() {
        const props = this.props;
        props.companyStore.getEmailAccountById(props.match.params.id);
    }

    render() {
        return !this.props.companyStore.loading ? this.renderEmail() : <LoaderDetails/>;
    }

    private renderEmail(): JSX.Element {
        const companyStore = this.props.companyStore;
        const email = companyStore.emailAccount;
        const type = email?.type;
        const address = email?.address;

        return <Card className="bg-secondary shadow">
            <CardHeader>
                <h6 className="text-uppercase ls-1">E-mail account</h6>
                <h2>{type} - {address}</h2>
            </CardHeader>
            <CardBody>
                <Form innerRef={this.FormRef}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Type</InputGroupText>
                                    </InputGroupAddon>
                                    <select className="custom-select form-control-alternative" required={true}
                                            defaultValue={type} disabled={companyStore.loading}
                                            onChange={(e) => companyStore.selectEmailType(e.target.value)}>
                                        <option value="">Choose...</option>
                                        {companyStore.emailTypes.map((type) => this.renderType(type))}
                                    </select>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label className="form-control-label">Address</Label>
                                <Input innerRef={this.AddressRef} type="email" required={true}
                                       className="form-control-alternative" defaultValue={address}
                                       placeholder="Address"/>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label className="form-control-label">Template ID</Label>
                                <span className="pl-2">
                                    <i className="fa fa-question-circle" data-placement="top" id="tooltip-template"/>
                                    <UncontrolledTooltip delay={0} placement="top" target="tooltip-template">It originates from an external system, which we use for sending your e-mails</UncontrolledTooltip>
                                </span>
                                <Input innerRef={this.TemplateIdRef} type="text" className="form-control-alternative"
                                       defaultValue={email?.templateId} placeholder="Template ID"/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                    <Input innerRef={this.ActiveRef} id="active" type="checkbox"
                                           defaultChecked={email?.active} className="custom-control-input"/>
                                    <Label className="custom-control-label" htmlFor="active">
                                        <span>Active</span>
                                    </Label>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col md="6">
                        <Link className="btn btn-danger btn-icon btn-3 w-100" to={routes.companyEmails}>
                            <span className="btn-inner--icon">
                                <i className="ni ni-bold-left"/>
                            </span>
                            <span className="btn-inner--text">Back</span>
                        </Link>
                    </Col>
                    <Col md="6">
                        <ButtonAction loading={companyStore.saving}>
                            <Button className="btn-icon btn-3 w-100" role="link" color="success"
                                    onClick={async (e) => await this.initEmailUpdate(e)}>
                                <span className="btn-inner--icon">
                                    <i className="ni ni-check-bold"/>
                                </span>
                                <span className="btn-inner--text">Save</span>
                            </Button>
                        </ButtonAction>
                    </Col>
                </Row>
            </CardFooter>
        </Card>;
    }

    private async initEmailUpdate(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const valid = this.FormRef.current?.reportValidity();

        if (valid) {
            await this.updateEmail();
        }
    }

    private async updateEmail() {
        const props = this.props;
        const companyStore = props.companyStore;
        const existingEmail = companyStore.emailAccount;

        if (existingEmail) {
            const updatedEmail = {
                id: existingEmail.id,
                address: this.AddressRef.current?.value,
                templateId: this.TemplateIdRef.current?.value,
                active: this.ActiveRef.current?.checked ?? existingEmail.active
            };

            const result = await companyStore.updateEmailAccount(updatedEmail);

            if (result) {
                props.history.push(routes.companyEmails);
            }
        }
    }
}