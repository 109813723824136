import React from "react";
import {Badge} from "reactstrap";

interface IBadgeBooleanProps {
    isTrue: boolean
}

export default class BadgeBoolean extends React.Component<IBadgeBooleanProps> {
    render() {
        if (this.props.isTrue) {
            return <Badge color="success">Yes</Badge>;
        }

        return <Badge color="danger">No</Badge>;
    }
}