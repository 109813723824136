import React from "react";
import {Route, Switch} from "react-router-dom";
import Error404 from "../error-404";
import PrivateRoute from "../private-route";
import {inject, observer} from "mobx-react";
import UserStore from "../../stores/user-store";
import Base, {IBaseProps} from "../base/base";
import Login from "../login";
import LayoutBackoffice from "./layout-backoffice";
import Overlay from "../overlay";
import routes from "../../routes";

interface ILayoutProps extends IBaseProps {
    userStore: UserStore
}

@inject('userStore')
@observer
export default class Layout extends Base<ILayoutProps> {
    componentDidMount() {
        this.props.userStore.getCurrentUser();
    }

    render() {
        const userStore = this.props.userStore;

        return userStore.userLoaded ? <Switch>
            <Route path={routes.login} component={Login}/>
            <PrivateRoute path={[routes.root, routes.backoffice]} user={userStore.user} component={LayoutBackoffice}/>
            <Route component={Error404}/>
        </Switch> : <Overlay/>;
    }
}