import RootApi from "./root-api";
import Axios, {AxiosResponse} from "axios";
import {IQueryParams} from "../models/base";

export default class CustomerApi extends RootApi {
    private readonly endpoint = "/customers";

    async searchCustomers(queryParams?: IQueryParams): Promise<AxiosResponse> {
        return this.makePostCall(`${this.endpoint}/search`, {}, queryParams);
    }

    async getCustomerById(id: number): Promise<AxiosResponse> {
        return Axios.get(`${this.endpoint}/${id}`);
    }
}