import React from "react";
import {Col, Row} from "reactstrap";

export default class FooterContent extends React.Component {
    render() {
        return <Row className="align-items-center justify-content-xl-between">
            <Col>
                <div className="copyright text-center text-xl-left text-muted">
                    © {new Date().getFullYear()}{" "} <span
                    className="font-weight-bold ml-1">Vanilli Solutions OÜ</span>.
                </div>
            </Col>
        </Row>;
    }
}