import RootApi from "./root-api";
import Axios, {AxiosResponse} from "axios";
import {
    IProductDetailsDto, IProductDto,
    IProductImgCreateDto,
    IProductImgDto,
    IProductParameterValueDto,
    IProductSearchDto
} from "../models/product";
import {IQueryParams} from "../models/base";

export default class ProductApi extends RootApi {
    private readonly endpoint = "/products";

    async searchProducts(request: IProductSearchDto, queryParams?: IQueryParams): Promise<AxiosResponse> {
        return this.makePostCall(`${this.endpoint}/search`, request, queryParams);
    }

    async getProductProps(): Promise<AxiosResponse> {
        return Axios.get(`${this.endpoint}/props`);
    }

    async getProductSources(): Promise<AxiosResponse> {
        return Axios.get(`${this.endpoint}/sources`);
    }

    async getImgsByProduct(productId: number): Promise<AxiosResponse> {
        return Axios.get(`${this.endpoint}/${productId}/images`);
    }

    async getProductByRef(ref: string, refType: string): Promise<AxiosResponse> {
        return Axios.get(`${this.endpoint}/${ref}?refType=${refType}`);
    }

    async getImgById(id: number): Promise<AxiosResponse> {
        return Axios.get(`${this.endpoint}/images/${id}`);
    }

    async getParameterValues(productId: number): Promise<AxiosResponse> {
        return Axios.get(`${this.endpoint}/${productId}/parameter-values`);
    }

    async createProduct(product: IProductDetailsDto): Promise<AxiosResponse> {
        return this.makePostCall(this.endpoint, product);
    }

    async updateProductsOrder(products: IProductDto[]): Promise<AxiosResponse> {
        return this.makePostCall(`${this.endpoint}/order`, products);
    }

    async createImg(file: Blob, img: IProductImgCreateDto): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append("File", file);
        formData.append("Primary", img.primary.toString());
        formData.append("Active", img.active.toString());
        return this.makeFormPostCall(`${this.endpoint}/${img.productId}/images`, formData);
    }

    async updateImgsOrder(productId: number, imgs: IProductImgDto[]): Promise<AxiosResponse> {
        return this.makePostCall(`${this.endpoint}/${productId}/images/order`, imgs);
    }

    async updateProduct(product: IProductDetailsDto): Promise<AxiosResponse> {
        return Axios.put(`${this.endpoint}/${product.id}`, product);
    }

    async updateImg(productId: number, img: IProductImgDto) {
        return Axios.put(`${this.endpoint}/${productId}/images/${img.id}`, img);
    }

    async updateParameterValues(productId: number, parameterValues: IProductParameterValueDto[]): Promise<AxiosResponse> {
        return this.makePostCall(`${this.endpoint}/${productId}/parameter-values`, parameterValues);
    }

    async deleteProduct(id: number): Promise<AxiosResponse> {
        return Axios.delete(`${this.endpoint}/${id}`);
    }

    async deleteImg(id: number): Promise<AxiosResponse> {
        return Axios.delete(`${this.endpoint}/images/${id}`);
    }
}