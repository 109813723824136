import RootApi from "./root-api";
import Axios, {AxiosResponse} from "axios";
import {ICategoryDto, ICategoryParameterValueDto} from "../models/category";

export default class CategoryApi extends RootApi {
    private readonly endpoint = "/categories";

    async getCategories(): Promise<AxiosResponse> {
        return Axios.get(this.endpoint);
    }

    async getCategoryById(id: number): Promise<AxiosResponse> {
        return Axios.get(`${this.endpoint}/${id}`);
    }

    async getParameterValues(categoryId: number): Promise<AxiosResponse> {
        return Axios.get(`${this.endpoint}/${categoryId}/parameter-values`);
    }

    async createCategory(category: ICategoryDto): Promise<AxiosResponse> {
        return this.makePostCall(this.endpoint, category);
    }

    async updateCategory(category: ICategoryDto): Promise<AxiosResponse> {
        return Axios.put(`${this.endpoint}/${category.id}`, category);
    }

    async updateParameterValues(categoryId: number, parameterValues: ICategoryParameterValueDto[]): Promise<AxiosResponse> {
        return this.makePostCall(`${this.endpoint}/${categoryId}/parameter-values`, parameterValues);
    }

    async deleteCategory(id: number): Promise<AxiosResponse> {
        return Axios.delete(`${this.endpoint}/${id}`);
    }
}