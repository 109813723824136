import React from "react";
import NavMenuSide from "../nav-menu/nav-menu-side";
import Header from "../header";
import NavMenuTop from "../nav-menu/nav-menu-top";
import {Col, Container, Row} from "reactstrap";
import {Route, Switch} from "react-router-dom";
import BlogPostList from "../blog/blog-post-list";
import BlogPostCreate from "../blog/blog-post-create";
import BlogPostEdit from "../blog/blog-post-edit";
import BlogPostImg from "../blog/blog-post-img";
import CategoryList from "../category/category-list";
import CategoryCreate from "../category/category-create";
import CategoryEdit from "../category/category-edit";
import CategoryParameterValueList from "../category/category-parameter-value-list";
import CountryList from "../country/country-list";
import CountryCreate from "../country/country-create";
import CountryEdit from "../country/country-edit";
import CountryShippingMethodList from "../country/country-shipping-method-list";
import CountryShippingMethodCreate from "../country/country-shipping-method-create";
import CountryShippingMethodEdit from "../country/country-shipping-method-edit";
import CouponList from "../coupon/coupon-list";
import CouponCreate from "../coupon/coupon-create";
import CouponEdit from "../coupon/coupon-edit";
import OrderList from "../order/order-list";
import OrderDetails from "../order/order-details";
import OrderEdit from "../order/order-edit";
import ParameterList from "../parameter/parameter-list";
import ParameterCreate from "../parameter/parameter-create";
import ParameterEdit from "../parameter/parameter-edit";
import ProductList from "../product/product-list";
import ProductImgList from "../product/product-img-list";
import ProductImgCreate from "../product/product-img-create";
import ProductImgEdit from "../product/product-img-edit";
import ProductCreate from "../product/product-create";
import ProductEdit from "../product/product-edit";
import ShippingMethodList from "../shipping/shipping-method-list";
import ShippingMethodCreate from "../shipping/shipping-method-create";
import ShippingMethodEdit from "../shipping/shipping-method-edit";
import Error404 from "../error-404";
import Footer from "../footer/footer";
import ProductParameterValueList from "../product/product-parameter-value-list";
import routes from "../../routes";
import CustomerList from "../customer/customer-list";
import CustomerDetails from "../customer/customer-details";
import CompanyEdit from "../company/company-edit";
import CompanyEmailList from "../company/company-email-list";
import CompanyEmailCreate from "../company/company-email-create";
import CompanyEmailEdit from "../company/company-email-edit";
import CompanyPaymentProviderList from "../company/company-payment-provider-list";
import CompanyPaymentProviderCreate from "../company/company-payment-provider-create";
import CompanyPaymentProviderEdit from "../company/company-payment-provider-edit";
import ZoneList from "../zone/zone-list";
import ZoneCreate from "../zone/zone-create";
import ZoneEdit from "../zone/zone-edit";

export default class LayoutBackoffice extends React.Component {
    render() {
        return <div>
            <NavMenuSide/>
            <div className="main-content">
                <Header/>
                <NavMenuTop/>
                <Container className="mt--7" fluid={true}>
                    <Row>
                        <Col>
                            <Switch>
                                <Route path={[routes.root, routes.backoffice]} exact={true} component={OrderList}/>
                                <Route path={routes.blogPosts} exact={true} component={BlogPostList}/>
                                <Route path={`${routes.blogPosts}/create`} component={BlogPostCreate}/>
                                <Route path={`${routes.blogPosts}/:id`} exact={true} component={BlogPostEdit}/>
                                <Route path={`${routes.blogPosts}/:id/image`} component={BlogPostImg}/>
                                <Route path={routes.categories} exact={true} component={CategoryList}/>
                                <Route path={`${routes.categories}/create`} component={CategoryCreate}/>
                                <Route path={`${routes.categories}/:id`} exact={true} component={CategoryEdit}/>

                                <Route path={`${routes.categories}/:id/parameter-values`}
                                       component={CategoryParameterValueList}/>

                                <Route path={routes.company} exact={true} component={CompanyEdit}/>
                                <Route path={routes.companyEmails} exact={true} component={CompanyEmailList}/>
                                <Route path={`${routes.companyEmails}/create`} component={CompanyEmailCreate}/>
                                <Route path={`${routes.companyEmails}/:id`} component={CompanyEmailEdit}/>

                                <Route path={routes.companyPaymentProviders} exact={true}
                                       component={CompanyPaymentProviderList}/>

                                <Route path={`${routes.companyPaymentProviders}/create`}
                                       component={CompanyPaymentProviderCreate}/>

                                <Route path={`${routes.companyPaymentProviders}/:id`}
                                       component={CompanyPaymentProviderEdit}/>

                                <Route path={routes.countries} exact={true} component={CountryList}/>
                                <Route path={`${routes.countries}/create`} component={CountryCreate}/>
                                <Route path={`${routes.countries}/:id`} component={CountryEdit}/>

                                <Route path={routes.countryShippings} exact={true}
                                       component={CountryShippingMethodList}/>

                                <Route path={`${routes.countryShippings}/create`}
                                       component={CountryShippingMethodCreate}/>

                                <Route path={`${routes.countryShippings}/:id`} component={CountryShippingMethodEdit}/>
                                <Route path={routes.coupons} exact={true} component={CouponList}/>
                                <Route path={`${routes.coupons}/create`} component={CouponCreate}/>
                                <Route path={`${routes.coupons}/:id`} component={CouponEdit}/>
                                <Route path={routes.customers} exact={true} component={CustomerList}/>
                                <Route path={`${routes.customers}/:id`} component={CustomerDetails}/>
                                <Route path={routes.orders} exact={true} component={OrderList}/>
                                <Route path={`${routes.orders}/:id`} exact={true} component={OrderDetails}/>
                                <Route path={`${routes.orders}/:id/edit`} component={OrderEdit}/>
                                <Route path={routes.parameters} exact={true} component={ParameterList}/>
                                <Route path={`${routes.parameters}/create`} component={ParameterCreate}/>
                                <Route path={`${routes.parameters}/:id`} component={ParameterEdit}/>
                                <Route path={routes.products} exact={true} component={ProductList}/>
                                <Route path={`${routes.products}/:id/images`} exact={true} component={ProductImgList}/>
                                <Route path={`${routes.products}/:id/images/create`} component={ProductImgCreate}/>
                                <Route path={`${routes.products}/:productId/images/:imgId`} component={ProductImgEdit}/>

                                <Route path={`${routes.products}/:id/parameter-values`}
                                       component={ProductParameterValueList}/>

                                <Route path={`${routes.products}/create`} component={ProductCreate}/>
                                <Route path={`${routes.products}/:ref`} exact={true} component={ProductEdit}/>
                                <Route path={routes.shippingMethods} exact={true} component={ShippingMethodList}/>
                                <Route path={`${routes.shippingMethods}/create`} component={ShippingMethodCreate}/>
                                <Route path={`${routes.shippingMethods}/:id`} component={ShippingMethodEdit}/>
                                <Route path={routes.zones} exact={true} component={ZoneList}/>
                                <Route path={`${routes.zones}/create`} component={ZoneCreate}/>
                                <Route path={`${routes.zones}/:id`} component={ZoneEdit}/>
                                <Route component={Error404}/>
                            </Switch>
                        </Col>
                    </Row>
                </Container>
                <Footer/>
            </div>
        </div>;
    }
}