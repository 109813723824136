import {IBaseProps} from "../base/base";
import OrderStore from "../../stores/order-store";
import BaseList from "../base/list/base-list";
import {inject, observer} from "mobx-react";
import {
    Card,
    CardFooter,
    CardHeader,
    Col,
    DropdownItem, Row,
    Table
} from "reactstrap";
import React from "react";
import LoaderList from "../base/loader/loader-list";
import {IOrderDto} from "../../models/order";
import LinkEdit from "../base/link/link-edit";
import ButtonDelete from "../base/button/button-delete";
import ModalDelete from "../base/modal-delete";
import DateUtil from "../../utils/date-util";
import {NavLink, Link} from "react-router-dom";
import BadgeStatus from "../base/badge/badge-status";
import Paging from "../base/paging";
import routes from "../../routes";
import ButtonRow from "../base/button/button-row";
import ButtonDropdown from "../base/button/button-dropdown";

interface IOrderListProps extends IBaseProps {
    orderStore: OrderStore
}

@inject('orderStore')
@observer
export default class OrderList extends BaseList<IOrderListProps> {
    componentDidMount() {
        const orderStore = this.props.orderStore;
        orderStore.searchOrders({page: orderStore.currentPage});
    }

    render() {
        const orderStore = this.props.orderStore;
        const orderList = orderStore.orderList;
        const currentPage = orderStore.currentPage;

        return <Card className="shadow">
            <CardHeader>
                <Row>
                    <Col md="6">
                        <h2>Orders</h2>
                    </Col>
                    <Col md="6">
                        <Paging list={orderList} currentPage={currentPage}
                                onClick={async (page) => await orderStore.searchOrders({page: page})}/>
                    </Col>
                </Row>
            </CardHeader>
            {!orderStore.loading ? this.renderOrders() : <LoaderList/>}
            <CardFooter>
                <Row>
                    <Col md="6">
                        <h4>Total: {orderList.count}</h4>
                    </Col>
                    <Col md="6">
                        <Paging list={orderList} currentPage={currentPage}
                                onClick={async (page) => await orderStore.searchOrders({page: page})}/>
                    </Col>
                </Row>
            </CardFooter>
        </Card>;
    }

    private renderOrders(): JSX.Element {
        return <Table className="align-items-center table-flush" responsive={true}>
            <thead className="thead-light">
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Total</th>
                <th scope="col">Status</th>
                <th scope="col">Created</th>
                <th scope="col"/>
            </tr>
            </thead>
            <tbody>{this.props.orderStore.orderList.entities.map((order) => this.renderOrder(order))}</tbody>
        </Table>;
    }

    private renderOrder(order: IOrderDto): JSX.Element {
        const id = order.id;
        const created = DateUtil.format(order.created);

        return <tr key={id}>
            <td>{order.customerName}</td>
            <td>{order.total.toFixed(2)} €</td>
            <td>
                <BadgeStatus status={order.status}/>
            </td>
            <td>{created}</td>
            <td>{this.renderBtnRow(id)}</td>
            <ModalDelete id={id} modal={this.state.deletingModal} onToggle={() => this.toggleDeletingModal(id)}
                         onDelete={async () => await this.deleteOrder(id)} deleting={this.props.orderStore.deleting}/>
        </tr>;
    }

    private renderBtnRow(orderId: number): JSX.Element {
        return <>
            <ButtonRow expandedClass="d-xl-table-cell">
                <Link className="btn btn-default btn-sm btn-icon btn-3" to={`${routes.orders}/${orderId}`}>
                    <span className="btn-inner--icon">
                        <i className="ni ni-zoom-split-in"/>
                    </span>
                    <span className="btn-inner--text">Details</span>
                </Link>
                <LinkEdit path={`${routes.orders}/${orderId}/edit`}/>
                <ButtonDelete onToggle={() => this.toggleDeletingModal(orderId)}/>
            </ButtonRow>
            <ButtonDropdown hidden="xl">
                <DropdownItem to={`${routes.orders}/${orderId}`} tag={NavLink}>
                    <i className="ni ni-zoom-split-in"/>
                    Details
                </DropdownItem>
                <DropdownItem to={`${routes.orders}/${orderId}/edit`} tag={NavLink}>
                    <i className="ni ni-settings-gear-65"/>
                    Edit
                </DropdownItem>
                <DropdownItem onClick={() => this.toggleDeletingModal(orderId)}>
                    <i className="ni ni-fat-remove"/>
                    Delete
                </DropdownItem>
            </ButtonDropdown>
        </>;
    }

    private async deleteOrder(id: number) {
        const result = await this.props.orderStore.deleteOrder(id);

        if (result) {
            this.toggleDeletingModal(id);
        }
    }
}