import {IBaseProps} from "../base/base";
import CategoryStore from "../../stores/category-store";
import {createRef, RefObject} from "react";
import SimpleForm from "../base/form/simple-form";

interface ICategoryFormProps extends IBaseProps {
    categoryStore: CategoryStore
}

export default class CategoryForm extends SimpleForm<ICategoryFormProps> {
    protected readonly NameRef: RefObject<HTMLInputElement>;

    constructor(props: ICategoryFormProps) {
        super(props);
        this.NameRef = createRef();
    }
}