import React from "react";
import {Card, CardBody, CardHeader, CardText} from "reactstrap";

export default class Error404 extends React.Component {
    render() {
        return <Card className="shadow">
            <CardHeader>
                <h1 className="display-1">Error 404</h1>
            </CardHeader>
            <CardBody>
                <CardText>
                    <h2>Oops! Seems that we did not find what you were looking for!</h2>
                </CardText>
            </CardBody>
        </Card>;
    }
}