import {IBaseProps} from "../base/base";
import CouponStore from "../../stores/coupon-store";
import SimpleForm from "../base/form/simple-form";
import {createRef, RefObject} from "react";

interface ICouponFormProps extends IBaseProps {
    couponStore: CouponStore
}

export default class CouponForm extends SimpleForm<ICouponFormProps> {
    protected readonly CodeRef: RefObject<HTMLInputElement>;
    protected readonly PercentageRef: RefObject<HTMLInputElement>;

    constructor(props: ICouponFormProps) {
        super(props);
        this.CodeRef = createRef();
        this.PercentageRef = createRef();
    }
}