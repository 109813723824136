import React from "react";
import {Link} from "react-router-dom";

interface ILinkEditProps {
    path: string
}

export default class LinkEdit extends React.Component<ILinkEditProps> {
    render() {
        return <Link className="btn btn-primary btn-sm btn-icon btn-3" to={this.props.path}>
            <span className="btn-inner--icon">
                <i className="ni ni-settings-gear-65"/>
            </span>
            <span className="btn-inner--text">Edit</span>
        </Link>;
    }
}