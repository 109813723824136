import BaseList from "../base/list/base-list";
import {IBaseProps} from "../base/base";
import CountryStore from "../../stores/country-store";
import {inject, observer} from "mobx-react";
import {Card, CardHeader, Table} from "reactstrap";
import React from "react";
import LoaderList from "../base/loader/loader-list";
import {ICountryDto} from "../../models/country";
import ModalDelete from "../base/modal-delete";
import LinkCreate from "../base/link/link-create";
import BadgeBoolean from "../base/badge/badge-boolean";
import routes from "../../routes";

interface ICountryListProps extends IBaseProps {
    countryStore: CountryStore
}

@inject('countryStore')
@observer
export default class CountryList extends BaseList<ICountryListProps> {
    componentDidMount() {
        this.props.countryStore.getCountries();
    }

    render() {
        return <Card className="shadow">
            <CardHeader>
                <h2>Countries</h2>
                <LinkCreate path={routes.countries}/>
            </CardHeader>
            {!this.props.countryStore.loadingCountries ? this.renderCountries() : <LoaderList/>}
        </Card>;
    }

    private renderCountries(): JSX.Element {
        return <Table className="align-items-center table-flush" responsive={true}>
            <thead className="thead-light">
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Code</th>
                <th scope="col">Free shipping from</th>
                <th scope="col">Active</th>
                <th scope="col"/>
            </tr>
            </thead>
            <tbody>{this.props.countryStore.countries.map((country) => this.renderCountry(country))}</tbody>
        </Table>;
    }

    private renderCountry(country: ICountryDto): JSX.Element {
        const id = country.id;

        return <tr key={id}>
            <td>{country.name}</td>
            <td>{country.code}</td>
            <td>{country.freeShippingThreshold?.toFixed(2)}</td>
            <td>
                <BadgeBoolean isTrue={country.active}/>
            </td>
            <td>{this.renderDefaultBtnRow(id, routes.countries)}</td>
            <ModalDelete id={id} modal={this.state.deletingModal} onToggle={() => this.toggleDeletingModal(id)}
                         onDelete={async () => await this.deleteCountry(id)}
                         deleting={this.props.countryStore.deleting}/>
        </tr>;
    }

    private async deleteCountry(id: number) {
        const result = await this.props.countryStore.deleteCountry(id);

        if (result) {
            this.toggleDeletingModal(id);
        }
    }
}