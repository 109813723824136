import BaseStore from "./base-store";
import {
    IProductBaseDto, IProductDetails,
    IProductDetailsDto,
    IProductDto,
    IProductImgCreateDto,
    IProductImgDto, IProductParameterValue, IProductParameterValueDto, IProductSearchDto
} from "../models/product";
import {action, observable} from "mobx";
import {IListDto, IPropertyDto, IQueryParams} from "../models/base";
import {v4} from "uuid";

export default class ProductStore extends BaseStore {
    @observable
    loadingProps: boolean = false;

    @observable
    loadingSources: boolean = false;

    @observable
    productList: IListDto<IProductDto> = {
        entities: [],
        count: 0,
        limit: 0
    }

    @observable
    products: IProductBaseDto[] = [];

    @observable
    productProps: IPropertyDto[] = [];

    @observable
    productSources: string[] = [];

    @observable
    product: IProductDetails | undefined;

    @observable
    search: IProductSearchDto = {
        unActive: false,
        isDiscounted: false,
        additionalProps: []
    };

    @observable
    selectedSource: string | undefined;

    @observable
    description: string = "";

    @observable
    nextPosition: number = 1;

    @observable
    imgs: IProductImgDto[] = [];

    @observable
    img: IProductImgDto | undefined;

    @observable
    parameterValues: IProductParameterValue[] = [];

    async searchProducts(queryParams?: IQueryParams) {
        const notificationStore = this.notificationStore;

        try {
            this.startLoading();
            const result = await this.rootStore.productApi.searchProducts(this.search, queryParams);
            const data = result.data;

            if (data.valid) {
                this.setProductList(data.result, queryParams);
            } else {
                notificationStore.notifyErrors(data.errors);
            }
        } catch (e) {
            notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async getProductProps() {
        try {
            this.startLoadingProps();
            const result = await this.rootStore.productApi.getProductProps();
            this.setProps(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoadingProps();
        }
    }

    async getProductSources() {
        try {
            this.startLoadingSources();
            const result = await this.rootStore.productApi.getProductSources();
            this.setSources(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoadingSources();
        }
    }

    async getImgsByProduct(productId: number) {
        try {
            this.startLoading();
            const result = await this.rootStore.productApi.getImgsByProduct(productId);
            this.setImgs(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async getProductByRef(ref: string, refType: string) {
        const notificationStore = this.notificationStore;

        try {
            this.startLoading();
            const result = await this.rootStore.productApi.getProductByRef(ref, refType);
            const data = result.data;

            if (data.valid) {
                this.setProduct(data.result);
            } else {
                notificationStore.notifyErrors(data.errors);
            }
        } catch (e) {
            notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async getImgById(id: number) {
        try {
            this.startLoading();
            const result = await this.rootStore.productApi.getImgById(id);
            this.setImg(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async getParameterValues(productId: number) {
        try {
            this.startLoading();
            const result = await this.rootStore.productApi.getParameterValues(productId);
            this.setParameterValues(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async createProduct(product: IProductDetails): Promise<boolean> {
        this.fillAdditionalFields(product);
        const dto = ProductStore.mapToProductDto(product);
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.productApi.createProduct(dto);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess(`${dto.name} created`);
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async updateProductsOrder(): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.productApi.updateProductsOrder(this.productList.entities);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess("Products order updated");
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async createImg(img: IProductImgCreateDto): Promise<boolean> {
        const rootStore = this.rootStore;
        const imgStore = rootStore.imgStore;
        const file = imgStore.img?.croppedFile;
        const notificationStore = this.notificationStore;

        if (file) {
            try {
                this.startSaving();
                const result = await rootStore.productApi.createImg(file, img);
                const data = result.data;
                const valid = data.valid;

                if (valid) {
                    notificationStore.notifySuccess("Image uploaded");
                    imgStore.resetImg();
                } else {
                    notificationStore.notifyErrors(data.errors);
                }

                return valid;
            } catch (e) {
                notificationStore.notifyError(e);
                return false;
            } finally {
                this.finishSaving();
            }
        } else {
            notificationStore.notifyError("Please finish cropping first");
            return false;
        }
    }

    async updateImgsOrder(productId: number): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.productApi.updateImgsOrder(productId, this.imgs);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess("Images order updated");
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async updateProduct(product: IProductDetails): Promise<boolean> {
        if (product.id === 0) {
            return await this.createProduct(product);
        }

        this.fillAdditionalFields(product);
        const dto = ProductStore.mapToProductDto(product);
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.productApi.updateProduct(dto);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess(`${dto.name} updated`);
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async updateImg(img: IProductImgDto, productId: number): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.productApi.updateImg(productId, img);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess("Image updated");
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async updateParameterValues(productId: number): Promise<boolean> {
        const dtos = this.parameterValues.map((parameterValue) => ProductStore.mapToParameterValueDto(parameterValue));
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.productApi.updateParameterValues(productId, dtos);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess("Product parameter values updated");
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async deleteProduct(id: number): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startDeleting();
            const result = await this.rootStore.productApi.deleteProduct(id);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                this.removeProduct(id);
                notificationStore.notifySuccess("Product deleted");
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishDeleting();
        }
    }

    async deleteImg(id: number): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startDeleting();
            const result = await this.rootStore.productApi.deleteImg(id);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                this.removeImg(id);
                notificationStore.notifySuccess("Image deleted");
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishDeleting();
        }
    }

    @action
    initProduct() {
        this.product = {
            id: 0,
            active: true,
            name: undefined,
            price: 0,
            additionalProps: []
        };
    }

    @action
    setDiscounted(isDiscounted: boolean) {
        this.search.isDiscounted = isDiscounted;
    }

    @action
    setUnActive(isUnActive: boolean) {
        this.search.unActive = isUnActive;
    }

    @action
    selectSource(source: string) {
        this.search.source = source;
    }

    @action
    setSearchProp(value: string, key?: string, type?: string) {
        const matchingProp = this.search.additionalProps.find(x => x.key === key);

        if (matchingProp) {
            if (value) {
                matchingProp.value = value;
            } else {
                const propIdx = this.search.additionalProps.indexOf(matchingProp, 0);

                if (propIdx) {
                    this.search.additionalProps.splice(propIdx, 1);
                }
            }
        } else {
            const prop = {
                id: 0,
                key: key,
                value: value,
                type: type || "string"
            };

            this.search.additionalProps.push(prop);
        }
    }

    @action
    unsetDescription() {
        this.description = "";
    }

    @action
    updateDescription(value: string) {
        this.description = value;
    }

    @action
    setSearchTerm(term: string) {
        this.search.term = term;
    }

    @action
    setImgs(imgs: IProductImgDto[]) {
        this.imgs = imgs;
    }

    @action
    addProductProp() {
        this.product?.additionalProps?.push({
            id: 0,
            idx: v4(),
            key: undefined,
            value: undefined,
            type: undefined
        });
    }

    @action
    selectProductPropKey(key: string, idx: string) {
        const existingProp = this.product?.additionalProps?.find(x => x.idx === idx);

        if (existingProp) {
            existingProp.key = key;
            existingProp.type = this.productProps.find(x => x.key === key)?.type || "string";
        }
    }

    @action
    editProductPropBoolValue(value: boolean, idx: string) {
        const existingProp = this.product?.additionalProps?.find(x => x.idx === idx);

        if (existingProp) {
            existingProp.value = value ? "true" : "false";
        }
    }

    @action
    editProductPropValue(value: string, idx: string) {
        const existingProp = this.product?.additionalProps?.find(x => x.idx === idx);

        if (existingProp) {
            existingProp.value = value;
        }
    }

    @action
    removeProductProp(idx: string) {
        if (this.product) {
            const propIdx = this.product.additionalProps?.findIndex(x => x.idx === idx) as number;

            if (propIdx >= 0) {
                this.product.additionalProps?.splice(propIdx, 1);
            }
        }
    }

    @action
    addParameterValue() {
        this.parameterValues.push({
            id: 0,
            idx: v4(),
            parameterName: undefined,
            parameterValueId: undefined,
            active: true
        });
    }

    @action
    selectParameterValue(valueId: number, idx: string) {
        const matchingValue = this.parameterValues.find(x => x.idx === idx);

        if (matchingValue) {
            matchingValue.parameterValueId = valueId;
        }
    }

    @action
    setParameterValueStock(stock: number, idx: string) {
        const matchingValue = this.parameterValues.find(x => x.idx === idx);

        if (matchingValue) {
            matchingValue.stock = stock;
        }
    }

    @action
    unsetParameterValueStock(idx: string) {
        const matchingValue = this.parameterValues.find(x => x.idx === idx);

        if (matchingValue) {
            matchingValue.stock = undefined;
        }
    }

    @action
    setParameterValuePrice(price: number, idx: string) {
        const matchingValue = this.parameterValues.find(x => x.idx === idx);

        if (matchingValue) {
            if (price > 0) {
                matchingValue.price = price;
            } else {
                matchingValue.price = undefined;
            }
        }
    }

    @action
    setParameterValueActive(active: boolean, idx: string) {
        const matchingValue = this.parameterValues.find(x => x.idx === idx);

        if (matchingValue) {
            matchingValue.active = active;
        }
    }

    @action
    removeParameterValue(idx: string) {
        const parameterValueIdx = this.parameterValues.findIndex(x => x.idx === idx);

        if (parameterValueIdx >= 0) {
            this.parameterValues.splice(parameterValueIdx, 1);
        }
    }

    @action
    setProductListEntities(products: IProductDto[]) {
        this.productList.entities = products;
    }

    @action
    private setProductList(productList: IListDto<IProductDto>, queryParams?: IQueryParams) {
        this.productList = productList;
        const page = queryParams?.page;

        if (page) {
            this.selectPage(page);
        }
    }

    @action
    private setProducts(products: IProductBaseDto[]) {
        this.products = products;
    }

    @action
    private setProduct(product: IProductDetailsDto) {
        const description = product.description || "";
        this.updateDescription(description);
        const categoryId = product.categoryId;

        if (categoryId) {
            this.rootStore.categoryStore.selectCategory(categoryId);
        }

        this.product = ProductStore.mapToProduct(product);
    }

    @action
    private setImg(img: IProductImgDto) {
        this.img = img;
    }

    @action
    private removeProduct(id: number) {
        const products = this.productList.entities;
        const product = products.find(x => x.id === id);

        if (product) {
            const productIdx = products.indexOf(product, 0);
            products.splice(productIdx, 1);
        }
    }

    @action
    private removeImg(id: number) {
        const img = this.imgs.find(x => x.id === id);

        if (img) {
            const imgIdx = this.imgs.indexOf(img, 0);
            this.imgs.splice(imgIdx, 1);
        }
    }

    @action
    private setProps(props: IPropertyDto[]) {
        this.productProps = props;
    }

    @action
    private setSources(sources: string[]) {
        this.productSources = sources;
    }

    @action
    private setParameterValues(parameterValues: IProductParameterValueDto[]) {
        this.parameterValues = parameterValues.map((parameterValue) => {
            return ProductStore.mapToParamterValue(parameterValue);
        });
    }

    @action
    private startLoadingSources() {
        this.loadingSources = true;
    }

    @action
    private finishLoadingSources() {
        this.loadingSources = false;
    }

    @action
    private startLoadingProps() {
        this.loadingProps = true;
    }

    @action
    private finishLoadingProps() {
        this.loadingProps = false;
    }

    private fillAdditionalFields(product: IProductDetails) {
        product.description = this.description;
        product.categoryId = this.rootStore.categoryStore.selectedCategoryId;
        product.additionalProps = this.product?.additionalProps;
    }

    private static mapToParamterValue(parameterValue: IProductParameterValueDto): IProductParameterValue {
        return {
            id: parameterValue.id,
            idx: v4(),
            parameterName: parameterValue.parameterName,
            parameterValueId: parameterValue.parameterValueId,
            active: parameterValue.active,
            price: parameterValue.price,
            stock: parameterValue.stock
        };
    }

    private static mapToParameterValueDto(parameterValue: IProductParameterValue): IProductParameterValueDto {
        return {
            id: parameterValue.id,
            parameterName: parameterValue.parameterName,
            parameterValueId: parameterValue.parameterValueId,
            active: parameterValue.active,
            price: parameterValue.price,
            stock: parameterValue.stock
        }
    }

    private static mapToProduct(product: IProductDetailsDto): IProductDetails {
        return {
            id: product.id,
            active: product.active,
            code: product.code,
            name: product.name,
            description: product.description,
            price: product.price,
            discountPrice: product.discountPrice,
            categoryId: product.categoryId,
            stock: product.stock ?? undefined,
            source: product.source,
            additionalProps: product.additionalProps?.map((prop) => {
                return {
                    id: prop.id,
                    idx: v4(),
                    key: prop.key,
                    value: prop.value,
                    type: prop.type
                }
            })
        };
    }

    private static mapToProductDto(product: IProductDetails): IProductDetailsDto {
        return {
            id: product.id,
            active: product.active,
            code: product.code,
            name: product.name,
            description: product.description,
            price: product.price,
            discountPrice: product.discountPrice,
            categoryId: product.categoryId,
            stock: product.stock ?? null,
            additionalProps: product.additionalProps?.map((prop) => {
                return {
                    id: prop.id,
                    key: prop.key,
                    value: prop.value,
                    type: prop.type
                }
            })
        }
    }
}