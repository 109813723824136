import BaseStore from "./base-store";
import {ICompanyDto, ICompanyEmailAccountDto, ICompanyProvider, ICompanyProviderDto} from "../models/company";
import {action, observable} from "mobx";
import {EmailTypeEnum} from "../models/email";
import {v4} from "uuid";
import {AuthTypeEnum} from "../models/auth";
import {PaymentProviderEnum} from "./provider";

export default class CompanyStore extends BaseStore {
    @observable
    company: ICompanyDto | undefined;

    @observable
    emailAccounts: ICompanyEmailAccountDto[] = [];

    @observable
    paymentProviders: ICompanyProviderDto[] = [];

    @observable
    emailAccount: ICompanyEmailAccountDto | undefined;

    @observable
    paymentProvider: ICompanyProvider | undefined;

    @observable
    selectedEmailType: string | undefined;
    emailTypes: EmailTypeEnum[] = Object.values(EmailTypeEnum);

    @observable
    selectedPaymentProvider: string | undefined;
    paymentProviderNames: PaymentProviderEnum[] = Object.values(PaymentProviderEnum);
    providerAuthTypes: AuthTypeEnum[] = Object.values(AuthTypeEnum);

    async getCompany() {
        try {
            this.startLoading();
            const result = await this.rootStore.companyApi.getCompany();
            this.setCompany(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async getEmailAccounts() {
        try {
            this.startLoading();
            const result = await this.rootStore.companyApi.getEmailAccounts();
            this.setEmailAccounts(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async getPaymentProviders() {
        try {
            this.startLoading();
            const result = await this.rootStore.companyApi.getPaymentProviders();
            this.setPaymentProviders(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async getEmailAccountById(id: number) {
        try {
            this.startLoading();
            const result = await this.rootStore.companyApi.getEmailAccountById(id);
            this.setEmailAccount(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async getPaymentProviderById(id: number) {
        try {
            this.startLoading();
            const result = await this.rootStore.companyApi.getPaymentProviderById(id);
            this.setPaymentProvider(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async createEmailAccount(account: ICompanyEmailAccountDto): Promise<boolean> {
        account.type = this.selectedEmailType;
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.companyApi.createEmailAccount(account);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess(`${account.type} - ${account.address} created`);
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async createPaymentProvider(provider: ICompanyProvider): Promise<boolean> {
        this.fillAdditionalProviderFields(provider);
        const dto = CompanyStore.mapToProviderDto(provider);
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.companyApi.createPaymentProvider(dto);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess(`${dto.name} created`);
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async updateCompany(company: ICompanyDto) {
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.companyApi.updateCompany(company);
            const data = result.data;

            if (data.valid) {
                notificationStore.notifySuccess(`${company.legalName} updated`);
            } else {
                notificationStore.notifyErrors(data.errors);
            }
        } catch (e) {
            notificationStore.notifyError(e);
        } finally {
            this.finishSaving();
        }
    }

    async updateEmailAccount(account: ICompanyEmailAccountDto): Promise<boolean> {
        account.type = this.selectedEmailType;
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.companyApi.updateEmailAccount(account);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess(`${account.type} - ${account.address} updated`);
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async updatePaymentProvider(provider: ICompanyProvider): Promise<boolean> {
        this.fillAdditionalProviderFields(provider);
        const dto = CompanyStore.mapToProviderDto(provider);
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.companyApi.updatePaymentProvider(dto);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess(`${dto.name} updated`);
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    private fillAdditionalProviderFields(provider: ICompanyProvider) {
        provider.name = this.selectedPaymentProvider;
        provider.auths = this.paymentProvider?.auths;
    }

    async deleteEmailAccount(id: number): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startDeleting();
            const result = await this.rootStore.companyApi.deleteEmailAccount(id);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                this.removeEmailAccount(id);
                notificationStore.notifySuccess("E-mail deleted");
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishDeleting();
        }
    }

    async deletePaymentProvider(id: number): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startDeleting();
            const result = await this.rootStore.companyApi.deletePaymentProvider(id);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                this.removePaymentProvider(id);
                notificationStore.notifySuccess("Provider deleted");
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishDeleting();
        }
    }

    initPaymentProvider() {
        this.paymentProvider = {
            id: 0,
            name: undefined,
            active: true,
            auths: []
        };
    }

    @action
    selectEmailType(type: string) {
        this.selectedEmailType = type;
    }

    @action
    selectPaymentProvider(provider: string) {
        this.selectedPaymentProvider = provider;
    }

    @action
    addProviderAuth() {
        this.paymentProvider?.auths?.push({
            id: 0,
            idx: v4(),
            type: undefined,
            value: undefined
        });
    }

    @action
    selectProviderAuthType(type: string, idx: string) {
        const existingAuth = this.paymentProvider?.auths?.find(x => x.idx === idx);

        if (existingAuth) {
            existingAuth.type = type;
        }
    }

    @action
    editProviderAuthValue(value: string, idx: string) {
        const existingAuth = this.paymentProvider?.auths?.find(x => x.idx === idx);

        if (existingAuth) {
            existingAuth.value = value;
        }
    }

    @action
    removeProviderAuth(idx: string) {
        if (this.paymentProvider) {
            const authIdx = this.paymentProvider.auths?.findIndex(x => x.idx === idx) as number;

            if (authIdx >= 0) {
                this.paymentProvider.auths?.splice(authIdx, 1);
            }
        }
    }

    @action
    private setCompany(company: ICompanyDto) {
        this.company = company;
    }

    @action
    private setEmailAccounts(accounts: ICompanyEmailAccountDto[]) {
        this.emailAccounts = accounts;
    }

    @action
    private setPaymentProviders(providers: ICompanyProviderDto[]) {
        this.paymentProviders = providers;
    }

    @action
    private setEmailAccount(account: ICompanyEmailAccountDto) {
        this.selectedEmailType = account.type;
        this.emailAccount = account;
    }

    @action
    private setPaymentProvider(provider: ICompanyProviderDto) {
        this.selectedPaymentProvider = provider.name;
        this.paymentProvider = this.mapToProvider(provider);
    }

    @action
    private removeEmailAccount(id: number) {
        const account = this.emailAccounts.find(x => x.id === id);

        if (account) {
            const accountIdx = this.emailAccounts.indexOf(account, 0);
            this.emailAccounts.splice(accountIdx, 1);
        }
    }

    @action
    private removePaymentProvider(id: number) {
        const provider = this.paymentProviders.find(x => x.id === id);

        if (provider) {
            const providerIdx = this.paymentProviders.indexOf(provider, 0);
            this.paymentProviders.splice(providerIdx, 1);
        }
    }

    private mapToProvider(provider: ICompanyProviderDto): ICompanyProvider {
        return {
            id: provider.id,
            name: provider.name,
            active: provider.active,
            auths: provider.auths?.map((auth) => {
                return {
                    id: auth.id,
                    idx: v4(),
                    type: auth.type,
                    value: auth.value
                }
            })
        };
    }

    private static mapToProviderDto(provider: ICompanyProvider): ICompanyProviderDto {
        return {
            id: provider.id,
            name: provider.name,
            active: provider.active,
            auths: provider.auths?.map((auth) => {
                return {
                    id: auth.id,
                    type: auth.type,
                    value: auth.value
                }
            })
        };
    }
}