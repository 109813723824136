import RootApi from "./root-api";
import Axios, {AxiosResponse} from "axios";
import {IShippingMethodDto} from "../models/shipping";

export default class ShippingApi extends RootApi {
    private readonly endpoint = "/shipping";
    private readonly methodsEndpoint = `${this.endpoint}/methods`;

    async getMethods(): Promise<AxiosResponse> {
        return Axios.get(this.methodsEndpoint);
    }

    async getMethodById(id: number): Promise<AxiosResponse> {
        return Axios.get(`${this.methodsEndpoint}/${id}`);
    }

    async createMethod(method: IShippingMethodDto): Promise<AxiosResponse> {
        return this.makePostCall(this.methodsEndpoint, method);
    }

    async updateMethod(method: IShippingMethodDto): Promise<AxiosResponse> {
        return Axios.put(`${this.methodsEndpoint}/${method.id}`, method);
    }

    async deleteMethod(id: number): Promise<AxiosResponse> {
        return Axios.delete(`${this.methodsEndpoint}/${id}`);
    }
}