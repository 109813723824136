import {IBaseProps} from "../base/base";
import CountryStore from "../../stores/country-store";
import BaseList from "../base/list/base-list";
import {inject, observer} from "mobx-react";
import {Card, CardHeader, Table} from "reactstrap";
import React from "react";
import LinkCreate from "../base/link/link-create";
import LoaderList from "../base/loader/loader-list";
import {ICountryShippingMethodDto} from "../../models/country";
import ModalDelete from "../base/modal-delete";
import routes from "../../routes";

interface ICountryShippingMethodListProps extends IBaseProps {
    countryStore: CountryStore
}

@inject('countryStore')
@observer
export default class CountryShippingMethodList extends BaseList<ICountryShippingMethodListProps> {
    componentDidMount() {
        this.props.countryStore.getShippingMethods();
    }

    render() {
        return <Card className="shadow">
            <CardHeader>
                <h2>Shipping settings</h2>
                <LinkCreate path={routes.countryShippings}/>
            </CardHeader>
            {!this.props.countryStore.loading ? this.renderShippings() : <LoaderList/>}
        </Card>;
    }

    private renderShippings(): JSX.Element {
        return <Table className="align-items-center table-flush" responsive={true}>
            <thead className="thead-light">
            <tr>
                <th scope="col">Zone</th>
                <th scope="col">Country</th>
                <th scope="col">Method</th>
                <th scope="col">Category</th>
                <th scope="col">Fee</th>
                <th scope="col">Day estimation</th>
                <th scope="col"/>
            </tr>
            </thead>
            <tbody>{this.props.countryStore.shippingMethods.map((shipping) => this.renderShipping(shipping))}</tbody>
        </Table>;
    }

    private renderShipping(shipping: ICountryShippingMethodDto): JSX.Element {
        const id = shipping.id;

        return <tr key={id}>
            <td>{shipping.zoneName}</td>
            <td>{shipping.countryName}</td>
            <td>{shipping.methodName}</td>
            <td>{shipping.categoryName}</td>
            <td>{shipping.fee?.toFixed(2)}</td>
            <td>{shipping.estimationInDays}</td>
            <td>{this.renderDefaultBtnRow(id, routes.countryShippings)}</td>
            <ModalDelete id={id} modal={this.state.deletingModal} onToggle={() => this.toggleDeletingModal(id)}
                         onDelete={async () => await this.deleteShipping(id)}
                         deleting={this.props.countryStore.deleting}/>
        </tr>;
    }

    private async deleteShipping(id: number) {
        const result = await this.props.countryStore.deleteShippingMethod(id);

        if (result) {
            this.toggleDeletingModal(id);
        }
    }
}