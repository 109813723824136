import React from "react";
import {Container} from "reactstrap";
import FooterContent from "./footer-content";

export default class Footer extends React.Component {
    render() {
        return <Container fluid={true}>
            <footer className="footer">
                <FooterContent/>
            </footer>
        </Container>;
    }
}