import {ISimpleDto} from "./base";

export interface IShippingMethodDto extends ISimpleDto {
    name: string | undefined,
    provider?: string,
    comment?: string,
    addressRequired: boolean
}

export enum ShippingProviderEnum {
    Dpd = "Dpd",
    Itella = "Itella",
    Omniva = "Omniva"
}