import {IBaseProps} from "../base/base";
import React, {createRef, RefObject} from "react";
import {ICategoryDto} from "../../models/category";
import CategoryStore from "../../stores/category-store";
import ProductStore from "../../stores/product-store";
import SimpleForm from "../base/form/simple-form";
import {IProperty, IPropertyDto} from "../../models/base";
import {Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row} from "reactstrap";

interface IProductFormProps extends IBaseProps {
    categoryStore: CategoryStore,
    productStore: ProductStore
}

export default class ProductForm extends SimpleForm<IProductFormProps> {
    protected readonly CodeRef: RefObject<HTMLInputElement>;
    protected readonly NameRef: RefObject<HTMLInputElement>;
    protected readonly PriceRef: RefObject<HTMLInputElement>;
    protected readonly DiscountPriceRef: RefObject<HTMLInputElement>;
    protected readonly StockRef: RefObject<HTMLInputElement>;

    componentDidMount() {
        const props = this.props;
        props.categoryStore.getCategories();
        props.productStore.getProductProps();
    }

    constructor(props: IProductFormProps) {
        super(props);
        this.CodeRef = createRef();
        this.NameRef = createRef();
        this.PriceRef = createRef();
        this.DiscountPriceRef = createRef();
        this.StockRef = createRef();
    }

    protected renderCategory(category: ICategoryDto): JSX.Element {
        const id = category.id;
        return <option key={id} value={id}>{category.name}</option>;
    }

    protected selectCategory(e: React.ChangeEvent<HTMLSelectElement>) {
        const id = Number(e.target.value);
        this.props.categoryStore.selectCategory(id);
    }

    protected renderAdditionalPropKey(prop: IPropertyDto): JSX.Element {
        const key = prop.key;
        return <option key={key} value={key}>{key}</option>;
    }

    protected renderAdditionalProps(): JSX.Element {
        const productStore = this.props.productStore;

        return <>
            <hr/>
            <Row>
                <Col>
                    <h3>Additional properties</h3>
                </Col>
            </Row>
            {productStore.product?.additionalProps?.map((prop) => this.renderAdditionalProp(prop))}
            <Row>
                <Col>
                    <Button className="btn-icon btn-3" role="link" color="primary" size="sm"
                            onClick={() => productStore.addProductProp()}>
                        <span className="btn-inner--icon">
                            <i className="ni ni-fat-add"/>
                        </span>
                        <span className="btn-inner--text">Add</span>
                    </Button>
                </Col>
            </Row>
        </>;
    }

    protected renderAdditionalProp(prop: IProperty): JSX.Element {
        const idx = prop.idx;
        const productStore = this.props.productStore;

        return <Row key={idx}>
            <Col md="4">
                <FormGroup>
                    <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>Property</InputGroupText>
                        </InputGroupAddon>
                        <select className="custom-select form-control-alternative" defaultValue={prop.key}
                                onChange={(e) => productStore.selectProductPropKey(e.target.value, idx)}
                                required={true}>
                            <option value="">Choose...</option>
                            {productStore.productProps.map((dto) => this.renderAdditionalPropKey(dto))}
                        </select>
                    </InputGroup>
                </FormGroup>
            </Col>
            {prop.type && <Col md="4">
                <FormGroup>{this.renderAdditionalPropValue(prop)}</FormGroup>
            </Col>}
            <Col md="4">
                <Button className="btn-icon btn-3" role="link" color="warning"
                        onClick={() => productStore.removeProductProp(idx)}>
                    <span className="btn-inner--icon">
                        <i className="ni ni-fat-remove"/>
                    </span>
                    <span className="btn-inner--text">Remove</span>
                </Button>
            </Col>
        </Row>;
    }

    protected renderAdditionalPropValue(prop: IProperty): JSX.Element {
        const productStore = this.props.productStore;
        const idx = prop.idx;
        const value = prop.value;

        if (prop.type === "bool") {
            return <div className="custom-control custom-control-alternative custom-checkbox">
                <Input id={`${idx}-checked`} type="checkbox" className="custom-control-input"
                       defaultChecked={value === "true"}
                       onChange={(e) => productStore.editProductPropBoolValue(e.target.checked, idx)}/>
                <Label className="custom-control-label" htmlFor={`${idx}-checked`}>
                    <span>True</span>
                </Label>
            </div>;
        }

        return <Input type="text" required={true} className="form-control-alternative" placeholder="Value"
                      defaultValue={value} onChange={(e) => productStore.editProductPropValue(e.target.value, idx)}/>;
    }
}