import {IBaseProps} from "../base/base";
import CategoryStore from "../../stores/category-store";
import {inject, observer} from "mobx-react";
import {Card, CardHeader, DropdownItem, Table} from "reactstrap";
import React from "react";
import {ICategoryDto} from "../../models/category";
import BaseList from "../base/list/base-list";
import ModalDelete from "../base/modal-delete";
import ButtonDelete from "../base/button/button-delete";
import LinkCreate from "../base/link/link-create";
import LinkEdit from "../base/link/link-edit";
import LoaderList from "../base/loader/loader-list";
import BadgeBoolean from "../base/badge/badge-boolean";
import {Link, NavLink} from "react-router-dom";
import routes from "../../routes";
import ButtonRow from "../base/button/button-row";
import ButtonDropdown from "../base/button/button-dropdown";

interface ICategoryListProps extends IBaseProps {
    categoryStore: CategoryStore
}

@inject('categoryStore')
@observer
export default class CategoryList extends BaseList<ICategoryListProps> {
    componentDidMount() {
        this.props.categoryStore.getCategories();
    }

    render() {
        return <Card className="shadow">
            <CardHeader>
                <h2>Categories</h2>
                <LinkCreate path={routes.categories}/>
            </CardHeader>
            {!this.props.categoryStore.loading ? this.renderCategories() : <LoaderList/>}
        </Card>
    }

    private renderCategories(): JSX.Element {
        return <Table className="align-items-center table-flush" responsive={true}>
            <thead className="thead-light">
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Active</th>
                <th scope="col"/>
            </tr>
            </thead>
            <tbody>{this.props.categoryStore.categories.map((category) => this.renderCategory(category))}</tbody>
        </Table>;
    }

    private renderCategory(category: ICategoryDto): JSX.Element {
        const id = category.id;

        return <tr key={id}>
            <td>{category.name}</td>
            <td>
                <BadgeBoolean isTrue={category.active}/>
            </td>
            <td>{this.renderBtnRow(id)}</td>
            <ModalDelete id={id} modal={this.state.deletingModal} onToggle={() => this.toggleDeletingModal(id)}
                         onDelete={async () => await this.deleteCategory(id)}
                         deleting={this.props.categoryStore.deleting}/>
        </tr>;
    }

    private renderBtnRow(categoryId: number): JSX.Element {
        return <>
            <ButtonRow>
                <Link className="btn btn-default btn-sm btn-icon btn-3"
                      to={`${routes.categories}/${categoryId}/parameter-values`}>
                    <span className="btn-inner--icon">
                        <i className="ni ni-ui-04"/>
                    </span>
                    <span className="btn-inner--text">Parameters</span>
                </Link>
                <LinkEdit path={`${routes.categories}/${categoryId}`}/>
                <ButtonDelete onToggle={() => this.toggleDeletingModal(categoryId)}/>
            </ButtonRow>
            <ButtonDropdown>
                <DropdownItem to={`${routes.categories}/${categoryId}/parameter-values`} tag={NavLink}>
                    <i className="ni ni-ui-04"/>
                    Parameters
                </DropdownItem>
                <DropdownItem to={`${routes.categories}/${categoryId}`} tag={NavLink}>
                    <i className="ni ni-settings-gear-65"/>
                    Edit
                </DropdownItem>
                <DropdownItem onClick={() => this.toggleDeletingModal(categoryId)}>
                    <i className="ni ni-fat-remove"/>
                    Delete
                </DropdownItem>
            </ButtonDropdown>
        </>;
    }

    private async deleteCategory(id: number) {
        const result = await this.props.categoryStore.deleteCategory(id);

        if (result) {
            this.toggleDeletingModal(id);
        }
    }
}