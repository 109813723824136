import BaseForm from "./base-form";
import {createRef, RefObject} from "react";

export default class SimpleForm<T> extends BaseForm<T> {
    protected readonly ActiveRef: RefObject<HTMLInputElement>;

    constructor(props: T) {
        super(props);
        this.ActiveRef = createRef();
    }
}