import {IBaseProps} from "../base/base";
import ProductStore from "../../stores/product-store";
import BaseList from "../base/list/base-list";
import {inject, observer} from "mobx-react";
import {Card, CardHeader, Table} from "reactstrap";
import React from "react";
import LoaderList from "../base/loader/loader-list";
import {IProductImgDto} from "../../models/product";
import BadgeBoolean from "../base/badge/badge-boolean";
import ModalDelete from "../base/modal-delete";
import LinkCreate from "../base/link/link-create";
import {Link} from "react-router-dom";
import ProductImgListEdit from "./product-img-list-edit";
import routes from "../../routes";
import ButtonReorder from "../base/button/button-reorder";

interface IProductImgListProps extends IBaseProps {
    productStore: ProductStore
}

@inject('productStore')
@observer
export default class ProductImgList extends BaseList<IProductImgListProps> {
    componentDidMount() {
        const props = this.props;
        props.productStore.getImgsByProduct(props.match.params.id);
    }

    render() {
        const props = this.props;
        const reordering = this.state.reordering;
        const productStore = props.productStore;

        return <Card className={reordering ? "card-highlighted" : "shadow"}>
            <CardHeader>
                <h2>Images</h2>
                <Link className="btn btn-sm btn-danger btn-icon btn-3" to={routes.products}>
                    <span className="btn-inner--icon">
                        <i className="ni ni-bold-left"/>
                    </span>
                    <span className="btn-inner--text">Back</span>
                </Link>
                {!reordering && <LinkCreate path={`${routes.products}/${props.match.params.id}/images`}/>}
                <ButtonReorder reordering={reordering} saving={productStore.saving}
                               onToggle={async () => await this.toggleOrdering()}
                               onCancel={() => this.setState({reordering: false})}/>
            </CardHeader>
            {!productStore.loading ? this.renderImgs() : <LoaderList/>}
        </Card>;
    }

    private renderImgs(): JSX.Element {
        if (this.state.reordering) {
            return this.renderEditableTable();
        }

        return this.renderFixedTable();
    }

    private renderEditableTable(): JSX.Element {
        const productStore = this.props.productStore;

        return <Table className="align-items-center table-flush" responsive={true}>
            <thead className="thead-light">
            <tr>
                <th scope="col"/>
                <th scope="col">Primary</th>
                <th scope="col">Active</th>
                <th scope="col"/>
            </tr>
            </thead>
            <ProductImgListEdit entities={productStore.imgs} onChange={(imgs) => productStore.setImgs(imgs)}/>
        </Table>;
    }

    private renderFixedTable(): JSX.Element {
        return <Table className="align-items-center table-flush" responsive={true}>
            <thead className="thead-light">
            <tr>
                <th scope="col"/>
                <th scope="col">Primary</th>
                <th scope="col">Active</th>
                <th scope="col"/>
            </tr>
            </thead>
            <tbody>{this.props.productStore.imgs.map((img) => this.renderImg(img))}</tbody>
        </Table>;
    }

    private renderImg(img: IProductImgDto): JSX.Element {
        const id = img.id;
        const path = img.path;
        const props = this.props;

        return <tr key={id}>
            <td className="w-25">
                <img className="img-fluid" src={path} alt={path}/>
            </td>
            <td>
                <BadgeBoolean isTrue={img.primary}/>
            </td>
            <td>
                <BadgeBoolean isTrue={img.active}/>
            </td>
            <td>{this.renderDefaultBtnRow(id, `${routes.products}/${props.match.params.id}/images`)}</td>
            <ModalDelete id={id} modal={this.state.deletingModal} onToggle={() => this.toggleDeletingModal(id)}
                         onDelete={async () => await this.deleteImg(id)} deleting={props.productStore.deleting}/>
        </tr>;
    }

    private async deleteImg(id: number) {
        const result = await this.props.productStore.deleteImg(id);

        if (result) {
            this.toggleDeletingModal(id);
        }
    }

    private async toggleOrdering() {
        if (!this.state.reordering) {
            this.setState({reordering: !this.state.reordering});
        } else {
            const props = this.props;
            const result = await props.productStore.updateImgsOrder(props.match.params.id);

            if (result) {
                this.setState({reordering: !this.state.reordering});
            }
        }
    }
}