import React from "react";
import {
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Media,
    Nav,
    Navbar,
    UncontrolledDropdown
} from "reactstrap";
import Profile from "../../assets/imgs/Profile.png";
import Base, {IBaseProps} from "../base/base";
import UserStore from "../../stores/user-store";
import {inject, observer} from "mobx-react";

interface INavMenuTopProps extends IBaseProps {
    userStore?: UserStore
}

@inject('userStore')
@observer
export default class NavMenuTop extends Base<INavMenuTopProps> {
    render() {
        const userStore = this.props.userStore;

        return <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
            <Container fluid={true} className="justify-content-end">
                <Nav className="align-items-center d-none d-md-flex" navbar={true}>
                    <UncontrolledDropdown nav={true}>
                        <DropdownToggle nav={true}>
                            <Media className="align-items-center">
                                <span className="avatar avatar-sm rounded-circle">
                                    <img alt="Profile" src={Profile}/>
                                </span>
                                <Media className="ml-2 d-none d-lg-block">
                                    <span className="text-sm font-weight-bold">{userStore?.user?.username}</span>
                                </Media>
                            </Media>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right={true}>
                            <DropdownItem className="noti-title" header={true} tag="div">
                                <h6 className="text-overflow">Welcome!</h6>
                            </DropdownItem>
                            <DropdownItem onClick={() => userStore?.signOut()}>
                                <i className="ni ni-user-run"/>
                                <span>Log out</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            </Container>
        </Navbar>;
    }
}