import React from "react";
import {Button} from "reactstrap";

interface IButtonDeleteProps {
    onToggle: () => void;
    disabled?: boolean
}

export default class ButtonDelete extends React.Component<IButtonDeleteProps> {
    render() {
        const props = this.props;

        return <Button className="btn-icon btn-3" type="button" disabled={props.disabled} size="sm" color="danger"
                       onClick={props.onToggle}>
                    <span className="btn-inner--icon">
                        <i className="ni ni-fat-remove"/>
                    </span>
            <span className="btn-inner--text">Delete</span>
        </Button>;
    }
}