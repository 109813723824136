import {OrderStatusEnum} from "../models/order";

export default class ColorUtil {
    static mapFromStatus(status: string | undefined): string {
        if (status === OrderStatusEnum.Finalized) {
            return "success";
        }

        if (status === OrderStatusEnum.Paid) {
            return "warning";
        }

        if (status === OrderStatusEnum.Pending) {
            return "default";
        }

        return "info";
    }
}