import {IBaseProps} from "../base/base";
import CompanyStore from "../../stores/company-store";
import SimpleForm from "../base/form/simple-form";
import React from "react";
import {ICompanyProviderAuth} from "../../models/company";
import {Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row} from "reactstrap";
import {AuthTypeEnum} from "../../models/auth";
import {PaymentProviderEnum} from "../../stores/provider";

interface ICompanyPaymentProviderFormProps extends IBaseProps {
    companyStore: CompanyStore
}

export default class CompanyPaymentProviderForm extends SimpleForm<ICompanyPaymentProviderFormProps> {
    protected renderName(name: PaymentProviderEnum): JSX.Element {
        return <option key={name} value={name}>{name}</option>;
    }

    protected renderAuth(auth: ICompanyProviderAuth): JSX.Element {
        const idx = auth.idx;
        const companyStore = this.props.companyStore;

        return <Row key={idx}>
            <Col md="4">
                <FormGroup>
                    <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>Type</InputGroupText>
                        </InputGroupAddon>
                        <select className="custom-select form-control-alternative" disabled={companyStore.loading}
                                onChange={(e) => companyStore.selectProviderAuthType(e.target.value, idx)}
                                required={true} defaultValue={auth.type}>
                            <option value="">Choose...</option>
                            {companyStore.providerAuthTypes.map((type) => CompanyPaymentProviderForm.renderAuthType(type))}
                        </select>
                    </InputGroup>
                </FormGroup>
            </Col>
            <Col md="4">
                <FormGroup>
                    <Input type="text" required={true} className="form-control-alternative" placeholder="Value"
                           onChange={(e) => companyStore.editProviderAuthValue(e.target.value, idx)}
                           defaultValue={auth.value}/>
                </FormGroup>
            </Col>
            <Col md="4">
                <Button className="btn-icon btn-3" role="link" color="warning"
                        onClick={() => companyStore.removeProviderAuth(idx)}>
                    <span className="btn-inner--icon">
                        <i className="ni ni-fat-remove"/>
                    </span>
                    <span className="btn-inner--text">Remove</span>
                </Button>
            </Col>
        </Row>;
    }

    private static renderAuthType(type: AuthTypeEnum): JSX.Element {
        return <option key={type} value={type}>{CompanyPaymentProviderForm.renderTypeName(type)}</option>;
    }

    private static renderTypeName(type: AuthTypeEnum): string {
        if (type === AuthTypeEnum.ShopId) {
            return "Shop ID";
        }

        return type;
    }
}