import {IBaseProps} from "../base/base";
import ZoneStore from "../../stores/zone-store";
import BaseList from "../base/list/base-list";
import {inject, observer} from "mobx-react";
import {Card, CardHeader, Table} from "reactstrap";
import React from "react";
import LinkCreate from "../base/link/link-create";
import routes from "../../routes";
import LoaderList from "../base/loader/loader-list";
import {IZoneDto} from "../../models/zone";
import BadgeBoolean from "../base/badge/badge-boolean";
import ModalDelete from "../base/modal-delete";

interface IZoneListProps extends IBaseProps {
    zoneStore: ZoneStore
}

@inject('zoneStore')
@observer
export default class ZoneList extends BaseList<IZoneListProps> {
    componentDidMount() {
        this.props.zoneStore.getZones();
    }

    render() {
        return <Card className="shadow">
            <CardHeader>
                <h2>Zones</h2>
                <LinkCreate path={routes.zones}/>
            </CardHeader>
            {!this.props.zoneStore.loading ? this.renderZones() : <LoaderList/>}
        </Card>;
    }

    private renderZones(): JSX.Element {
        return <Table className="align-items-center table-flush" responsive={true}>
            <thead className="thead-light">
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Active</th>
                <th scope="col"/>
            </tr>
            </thead>
            <tbody>{this.props.zoneStore.zones.map((zone) => this.renderZone(zone))}</tbody>
        </Table>;
    }

    private renderZone(zone: IZoneDto): JSX.Element {
        const id = zone.id;

        return <tr key={id}>
            <td>{zone.name}</td>
            <td>
                <BadgeBoolean isTrue={zone.active}/>
            </td>
            <td>{this.renderDefaultBtnRow(id, routes.zones)}</td>
            <ModalDelete id={id} modal={this.state.deletingModal} onToggle={() => this.toggleDeletingModal(id)}
                         onDelete={async () => await this.deleteZone(id)} deleting={this.props.zoneStore.deleting}/>
        </tr>;
    }

    private async deleteZone(id: number) {
        const result = await this.props.zoneStore.deleteZone(id);

        if (result) {
            this.toggleDeletingModal(id);
        }
    }
}