import {IBaseProps} from "../base/base";
import CountryStore from "../../stores/country-store";
import SimpleForm from "../base/form/simple-form";
import React, {createRef, RefObject} from "react";
import ZoneStore from "../../stores/zone-store";
import {IZoneDto} from "../../models/zone";

interface ICountryFormProps extends IBaseProps {
    countryStore: CountryStore,
    zoneStore: ZoneStore
}

export default class CountryForm extends SimpleForm<ICountryFormProps> {
    protected readonly CodeRef: RefObject<HTMLInputElement>;
    protected readonly NameRef: RefObject<HTMLInputElement>;
    protected readonly FreeShippingThresholdRef: RefObject<HTMLInputElement>;

    componentDidMount() {
        this.props.zoneStore.getZones();
    }

    constructor(props: ICountryFormProps) {
        super(props);
        this.CodeRef = createRef();
        this.NameRef = createRef();
        this.FreeShippingThresholdRef = createRef();
    }

    protected renderZone(zone: IZoneDto): JSX.Element {
        const id = zone.id;
        return <option key={id} value={id}>{zone.name}</option>;
    }

    protected selectZone(e: React.ChangeEvent<HTMLSelectElement>) {
        const id = Number(e.target.value);
        this.props.zoneStore.selectZone(id);
    }
}