import BaseStore from "./base-store";
import {ICouponDto} from "../models/coupon";
import {action, observable} from "mobx";

export default class CouponStore extends BaseStore {
    @observable
    coupons: ICouponDto[] = [];

    @observable
    coupon: ICouponDto | undefined;

    async getCoupons() {
        try {
            this.startLoading();
            const result = await this.rootStore.couponApi.getCoupons();
            this.setCoupons(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async getCouponById(id: number) {
        try {
            this.startLoading();
            const result = await this.rootStore.couponApi.getCouponById(id);
            this.setCoupon(result.data);
        } catch (e) {
            this.notificationStore.notifyError(e);
        } finally {
            this.finishLoading();
        }
    }

    async createCoupon(coupon: ICouponDto): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.couponApi.createCoupon(coupon);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess(`${coupon.code} created`);
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async updateCoupon(coupon: ICouponDto): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startSaving();
            const result = await this.rootStore.couponApi.updateCoupon(coupon);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                notificationStore.notifySuccess(`${coupon.code} updated`);
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid;
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishSaving();
        }
    }

    async deleteCoupon(id: number): Promise<boolean> {
        const notificationStore = this.notificationStore;

        try {
            this.startDeleting();
            const result = await this.rootStore.couponApi.deleteCoupon(id);
            const data = result.data;
            const valid = data.valid;

            if (valid) {
                this.removeCoupon(id);
                notificationStore.notifySuccess("Coupon deleted");
            } else {
                notificationStore.notifyErrors(data.errors);
            }

            return valid
        } catch (e) {
            notificationStore.notifyError(e);
            return false;
        } finally {
            this.finishDeleting();
        }
    }

    @action
    private setCoupons(coupons: ICouponDto[]) {
        this.coupons = coupons;
    }

    @action
    private setCoupon(coupon: ICouponDto) {
        this.coupon = coupon;
    }

    @action
    private removeCoupon(id: number) {
        const coupon = this.coupons.find(x => x.id === id);

        if (coupon) {
            const couponIdx = this.coupons.indexOf(coupon, 0);
            this.coupons.splice(couponIdx, 1);
        }
    }
}