import ProductImgForm from "./product-img-form";
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import React from "react";
import LoaderDetails from "../base/loader/loader-details";
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";
import ButtonAction from "../base/button/button-action";
import routes from "../../routes";

@inject('productStore')
@observer
export default class ProductImgEdit extends ProductImgForm {
    componentDidMount() {
        const props = this.props;
        props.productStore.getImgById(props.match.params.imgId);
    }

    render() {
        return !this.props.productStore.loading ? this.renderImg() : <LoaderDetails/>;
    }

    private renderImg(): JSX.Element {
        const props = this.props;
        const productStore = props.productStore;
        const img = productStore.img;

        return <Card className="bg-secondary shadow">
            <CardHeader>
                <h2>Image</h2>
            </CardHeader>
            <CardBody>
                <Form innerRef={this.FormRef}>
                    <Row>
                        <Col md="6">
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <div className="custom-control custom-control-alternative custom-checkbox">
                                            <Input innerRef={this.PrimaryRef} id="primary" type="checkbox"
                                                   defaultChecked={img?.primary} className="custom-control-input"/>
                                            <Label className="custom-control-label" htmlFor="primary">
                                                <span>Primary</span>
                                            </Label>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <div className="custom-control custom-control-alternative custom-checkbox">
                                            <Input innerRef={this.ActiveRef} id="active" type="checkbox"
                                                   defaultChecked={img?.active} className="custom-control-input"/>
                                            <Label className="custom-control-label" htmlFor="active">
                                                <span>Active</span>
                                            </Label>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="6">
                            <img className="img-fluid" src={img?.path} alt={img?.extRef}/>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col md="6">
                        <Link className="btn btn-danger btn-icon btn-3 w-100"
                              to={`${routes.products}/${props.match.params.productId}/images`}>
                            <span className="btn-inner--icon">
                                <i className="ni ni-bold-left"/>
                            </span>
                            <span className="btn-inner--text">Back</span>
                        </Link>
                    </Col>
                    <Col md="6">
                        <ButtonAction loading={productStore.saving}>
                            <Button className="btn-icon btn-3 w-100" role="link" color="success"
                                    onClick={async (e) => await this.initImgUpdate(e)}>
                                <span className="btn-inner--icon">
                                    <i className="ni ni-check-bold"/>
                                </span>
                                <span className="btn-inner--text">Save</span>
                            </Button>
                        </ButtonAction>
                    </Col>
                </Row>
            </CardFooter>
        </Card>;
    }

    private async initImgUpdate(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const valid = this.FormRef.current?.reportValidity();

        if (valid) {
            await this.updateImg();
        }
    }

    private async updateImg() {
        const props = this.props;
        const productStore = props.productStore;
        const existingImg = productStore.img;

        if (existingImg) {
            const updatedImg = {
                id: existingImg.id,
                extRef: existingImg.extRef,
                path: existingImg.path,
                primary: this.PrimaryRef.current?.checked ?? existingImg.primary,
                active: this.ActiveRef.current?.checked ?? existingImg.active
            };

            const productId = props.match.params.productId;
            const result = await productStore.updateImg(updatedImg, productId);

            if (result) {
                props.history.push(`${routes.products}/${productId}/images`);
            }
        }
    }
}