import {IBaseProps} from "../base/base";
import OrderStore from "../../stores/order-store";
import BaseForm from "../base/form/base-form";
import {inject, observer} from "mobx-react";
import LoaderDetails from "../base/loader/loader-details";
import React, {createRef, RefObject} from "react";
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Link} from "react-router-dom";
import ButtonAction from "../base/button/button-action";
import routes from "../../routes";

interface IOrderEditProps extends IBaseProps {
    orderStore: OrderStore
}

@inject('orderStore')
@observer
export default class OrderEdit extends BaseForm<IOrderEditProps> {
    private readonly processedRef: RefObject<HTMLInputElement>;
    private readonly trackingCodeRef: RefObject<HTMLInputElement>;

    componentDidMount() {
        const props = this.props;
        props.orderStore.getOrderById(props.match.params.id);
    }

    constructor(props: IOrderEditProps) {
        super(props);
        this.processedRef = createRef();
        this.trackingCodeRef = createRef();
    }

    render() {
        return !this.props.orderStore.loading ? this.renderOrder() : <LoaderDetails/>;
    }

    private renderOrder(): JSX.Element {
        const orderStore = this.props.orderStore;
        const order = orderStore.order;

        return <Card className="bg-secondary shadow">
            <CardHeader>
                <h6 className="text-uppercase ls-1">Order</h6>
                <h2>{order?.code}</h2>
            </CardHeader>
            <CardBody>
                <Form innerRef={this.FormRef}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                    <Input innerRef={this.processedRef} id="processed" type="checkbox"
                                           defaultChecked={order?.processed} className="custom-control-input"/>
                                    <Label className="custom-control-label" htmlFor="processed">
                                        <span>Processed</span>
                                    </Label>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col md="6">
                        <Link className="btn btn-danger btn-icon btn-3 w-100" to={routes.orders}>
                            <span className="btn-inner--icon">
                                <i className="ni ni-bold-left"/>
                            </span>
                            <span className="btn-inner--text">Back</span>
                        </Link>
                    </Col>
                    <Col md="6">
                        <ButtonAction loading={orderStore.saving}>
                            <Button className="btn-icon btn-3 w-100" role="link" color="success"
                                    onClick={async (e) => await this.initOrderUpdate(e)}>
                                <span className="btn-inner--icon">
                                    <i className="ni ni-check-bold"/>
                                </span>
                                <span className="btn-inner--text">Save</span>
                            </Button>
                        </ButtonAction>
                    </Col>
                </Row>
            </CardFooter>
        </Card>;
    }

    private async initOrderUpdate(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const valid = this.FormRef.current?.reportValidity();

        if (valid) {
            await this.updateOrder();
        }
    }

    private async updateOrder() {
        const props = this.props;
        const orderStore = props.orderStore;
        const existingOrder = orderStore.order;

        if (existingOrder) {
            const updatedOrder = {
                orderId: existingOrder.id,
                processed: this.processedRef.current?.checked ?? false,
                trackingCode: this.trackingCodeRef.current?.value
            }

            const result = await orderStore.updateOrder(updatedOrder);

            if (result) {
                props.history.push(routes.orders);
            }
        }
    }
}