const routes = {
    root: "/",
    backoffice: "/back-office",
    blogPosts: "/back-office/blog/posts",
    categories: "/back-office/categories",
    company: "/back-office/company",
    companyEmails: "/back-office/company-emails",
    companyPaymentProviders: "/back-office/company-payment-providers",
    contact: "/#contact",
    countries: "/back-office/countries",
    countryShippings: "/back-office/country/shippings",
    coupons: "/back-office/coupons",
    customers: "/back-office/customers",
    designs: "/designs",
    features: "/#features",
    login: "/login",
    orders: "/back-office/orders",
    parameters: "/back-office/parameters",
    pricing: "/#pricing",
    products: "/back-office/products",
    shippingMethods: "/back-office/shipping/methods",
    team: "/#team",
    zones: "/back-office/zones"
}

export default routes;