import RootApi from "./root-api";
import Axios, {AxiosResponse} from "axios";
import {IParameterDto} from "../models/parameter";

export default class ParameterApi extends RootApi {
    private readonly endpoint = "/parameters";

    async getParameters(): Promise<AxiosResponse> {
        return Axios.get(this.endpoint);
    }

    async getParameterById(id: number): Promise<AxiosResponse> {
        return Axios.get(`${this.endpoint}/${id}`);
    }

    async createParameter(parameter: IParameterDto): Promise<AxiosResponse> {
        return this.makePostCall(this.endpoint, parameter);
    }

    async updateParameter(parameter: IParameterDto): Promise<AxiosResponse> {
        return Axios.put(`${this.endpoint}/${parameter.id}`, parameter);
    }

    async deleteParameter(id: number): Promise<AxiosResponse> {
        return Axios.delete(`${this.endpoint}/${id}`);
    }
}